export default {
  computed: {
    userList() {
      return this.$store.getters["member/getRealUserList"]
    },
    topShareInfo() {
      return this.$store.state.member.userList.find(i => i.isTopShare)
    },
    isExitUserShare() { // 存在用户共享
      return this.userList.find(i => i.isShare)
    },
    isExitUserLock() { // 存在锁定用户
      return this.userList.find(i => i.isDbClick)
    }
  },

  methods: {
    dobuleClick(type, user, $meet) { // 双击事件
      if (!this.isExitUserLock) { // 如果不存在锁定用户，进行提示
        $meet.showToast(this.$t('meeting.unLocking'))
      }

      // 重置isDbClick
      this.userList.forEach(item => {
        this.$store.commit('member/updateUser', {
          userId: item.userId,
          isDbClick: false
        })
      })

      if (type === 'tab2') { // tab2点击，特殊处理
        if (this.isExitUserShare && this.isExitUserShare.userId === user.userId) {
          console.error('命中了相同tab-----')
          window._isClickSameTab = true
        }

        if (this.topShareInfo && (this.topShareInfo.userId === user.userId)) {
          console.error('双击了tab2共享画面，这时候要执行解锁操作')

          $meet.showToast(this.$t('meeting.screenUnlock'))
          window._isClickTopShare = true
          this.$store.commit('member/removeShareView')

          return
        }
      }
      if (user.isTopShare) { // 双击了顶部锁定画面，这时候要执行解锁操作
        console.error('双击了顶部画面，这时候要执行解锁操作')

        $meet.showToast(this.$t('meeting.screenUnlock'))
        window._isClickTopShare = true
        this.$store.commit('member/removeShareView')
      } else { // 双击了正常的list用户
        window._isLoadMainVideo = true

        this.$store.commit('member/updateUser', {
          userId: user.userId,
          isDbClick: true
        })

        if (this.isExitUserShare) { // 当前存在共享用户

          console.error('双击时存在共享的用户');

          if (!this.topShareInfo) {
            window._loadTopShare = true

            this.$store.commit('member/unshiftUser', {
              userId: this.isExitUserShare.userId,
              isTopShare: true,
              sharePaused: this.isExitUserShare.sharePaused
            })
          }

        } else { // 当前不存在共享用户
          console.error('双击时不存在共享的用户');
        }
      }
    }
  }
}
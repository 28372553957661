<!--
* 医百会议-主界面-底部-截图至云端功能
-->
<template>
  <div class="screenBtn">
    <img src="@/assets/images/screen.png" alt="">
    <div class="screenInfo" @click="screenHot">截图至云端</div>
  </div>
</template>

<script>
  import {
    getOS,
    checkApp
  } from "@/utils/tool";
  import Html2canvas from "html2canvas";

  import {
    CONTROL_COMMAND
  } from "@/constant/index";
  import {
    imgUpload,
    fetchMeetControl
  } from '@/api/meet'
  import throttle from 'lodash.throttle'


  export default {
    data() {
      return {
        dataURL: '',
        meetInfo: {},
        userId: '',
        name: '',
        time1: '',
        isClick: false,
      };
    },
    computed: {
      userList() {
        // 成员列表
        return this.$store.getters["member/getRealUserList"]
      },
    },
    created() {
      this.meetInfo = this.$route.query
      this.userID = this.$route.params.userID
    },
    methods: {
      screenHot: throttle(async function () {
        // if(this.isClick){
        //   return this.$parent.showToast('待截图完成，请稍后')
        // }else{
        // }
        console.error('点击截图按钮')
        

        const selfId = this.$configs.peerId;
        // 判断是否有权限点击截图、主持人，联席主持人才有权限，其他人点击提示暂无权限
        const meetControlData = await fetchMeetControl({
          command: CONTROL_COMMAND.SCREEN_SHOT,
          toPeerId: selfId,
        }, this.$configs)

        // 截图操作
        let date = new Date();
        this.time1 = date.getTime();
        this.name = `img${this.time1}.png`
        
        document.getElementById('screenBottom').style.opacity = 0

        const screenshot = document.getElementById("screenshot");
        const opts = {
          logging: false, //在console.log()中输出信息
          allowTaint: true, // 是否允许跨域
          useCors: true, //涉及到画布转图片的问题(截图功能)
          scale: 1, //设置放大倍数
          backgroundColor: null, //画出来的图片有白色的边框，不要可设置背景为透明色(null)
          timeout: 0, //图片加载延迟，默认延迟为0，单位毫秒
          taintTest: true, //是否在渲染前测试图片
          letterRendering: false, //在设置了字间距的时候有用
          height: null,
          width: null,
          x: 0,
          y: 0
        };

        Html2canvas(screenshot, opts).then(res => {
          const {
            height,
            width
          } = res;
          // document.body.appendChild(res) //创建节点，截图后展示出来查看
          this.dataURL = res.toDataURL("image/png"); //toDataURL图片格式转为base64
          // this.isClick = true
          // document.getElementById('screenBottom').style.display = 'block'
        document.getElementById('screenBottom').style.opacity = 1


          this.uploadImg()
        }, err => alert("截图失败，请重新尝试"));
      }, 4000),

      async uploadImg() {
        const copyUserList = JSON.parse(JSON.stringify(this.userList))
        copyUserList.forEach(e => {
          e.userId = e.userId.substr(e.userId.indexOf('_') + 1, e.userId.length)
        })
        const data = {
          conferenceId: this.meetInfo.conferenceId,
          roomId: this.meetInfo.roomID,
          userId: this.userID,
          metaData: JSON.stringify(copyUserList),
          name: this.name,
          image: this.dataURL,
        }
        try {
          let resData = await imgUpload(data)
          this.isClick = false
          this.$parent.showToast('截图成功')
        } catch (error) {
          console.log(error)
        }
      },
    }
  };
</script>

<style lang="scss" scoped>
  @mixin public_box {
    display: flex;
    justify-content: space-between;
    height: 48px;
  }

  .screenBtn {
    @include public_box;
    float: left;
    padding-left:8px;


    img {
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-top: 13px;
    }

    .screenInfo {
      @include public_box;
      padding: 0 14px 0 6px;
      color: #333;
      font-size: 14px;
      cursor: pointer;
      align-items: center;
      transition-property: background-color;
      transition-duration: 0.5s;
      transition-timing-function: ease;
    }
    &:hover {
        background: rgba(0, 0, 0, 0.05) !important;
      }

      &:focus {
        background: rgba(0, 0, 0, 0.1) !important;
      }
  }
</style>
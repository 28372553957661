<template>
  <!-- 中间一路画面 -->
  <div class="tab2-box">
    <videoItem 
      :user="tab2User"
      :tab2CurrentShare="tab2CurrentShare"
      idPix="tab2-video"
      @dobClick="dobuleTab2Click"
    />
  </div>
</template>

<script>
import { ROLE_CODE } from '@/constant/index'
import VideoItem from './video.item'
import dobuleClickMixins from "@/mixins/dobuleClick.mixins"

export default {
  mixins: [dobuleClickMixins],

  components: {
    videoItem: VideoItem
  },

  computed: {
    userList() {
      return this.$store.getters["member/getRealUserList"]
    },

    speakUser() { // 发言的用户
      return this.$store.getters["member/getSpeakUser"]
    },

    lastSpeaker() { // 最晚的发言用户
      return this.$store.state.member.lastSpeaker
    },

    isExitUserShare() { // 存在用户共享
      return this.userList.find(i => i.isShare)
    },

    isExitUserLock() { // 存在锁定用户
      return this.userList.find(i => i.isDbClick)
    },

    selfInfo() {
      return this.$store.getters["member/getUser"](this.$configs.peerId)
    },

    tab2User() { // tab2区域的显示画面
      if (!this.isExitUserShare) { // 没有人在共享画面，则显示自己
        console.log('tab2没有共享人，显示自己')
        return this.selfInfo || {}
      } else { // 有人正在共享

        const findDobuleClick = this.userList.find(item =>{
          return item.isDbClick
        })
        
        if (findDobuleClick) { // 存在锁定的人像，则显示共享画面
          const findShare = this.userList.find(item =>{
            return item.isShare
          })
          //tab画面告诉子组件是共享流
          this.tab2CurrentShare = true

          console.log('tab2存在锁定的人像，准备开启共享画面');

          // 如果共享人是自己，则显示死图片，如果不是自己，则显示共享人画面
          return findShare
        } else { // 不存在锁定的人像
          console.log('tab2不存在锁定的人像');
          //tab画面告诉子组件非共享流
          this.tab2CurrentShare = false

          // 显示规则 发言者 > 主持人 > 最早入会
          if (this.speakUser) {
            console.log('命中了tab2中正在发言的人----------')
            return this.speakUser
          } else { // 发言人数组为空，这时候来查看是否有最晚的发言人
            if (this.lastSpeaker) {
              console.log('命中了tab2中最晚发言人-----------')
              return this.lastSpeaker
            }
          }

          const findHost = this.userList.find((item) => {
            return item.roleCode === ROLE_CODE.HOST
          })
          if (findHost) {
            console.log('tab2将要显示主持人---');
            return findHost
          }

          //最早入会人得Index
          let minIndex = -1
          let minMs = ''
          this.userList.forEach((item, index) => {
            const { timeMs } = item

            if (!minMs) { // 不存在，第一次
              minMs = timeMs
              minIndex = index
            } else {
              if (minMs >= timeMs) {
                minMs = timeMs
                minIndex = index
              }
            }
          })
          if (minIndex > -1) {
            console.log('tab2命中了最早入会----------')
            return this.userList[minIndex]
          }

          console.error('tab2什么也不显示---')
          return {}
        }

      }
    }
  },

  watch: {
    tab2User: {
      handler(newVal, oldVal) {
        const { userId, isShare, isUseShiPin } = newVal

        console.error('----------tab2User watch执行-----------')
        console.log(newVal)
        console.log(oldVal)

        if (!userId) {
          return
        }

        // 清理视频源操作
        if (!oldVal) {
        } else if (newVal.userId !== oldVal.userId) {
          console.error('tab2 newVal和oldval不是同一个人，执行清理操作--')
          this.clearVideoSource(oldVal.userId)
        } else { // 这个时候还是本人
          if (!newVal.isUseShiPin && !newVal.isShare) {
            console.error('tab2 newVal视频和分享都没开，执行清理操作--')
            this.clearVideoSource(newVal.userId)
          }
        }

        let isRun = false

        if (!oldVal) {
          isRun = true
        } else if (newVal.userId !== oldVal.userId) { // 换人，这时候下面得逻辑要执行 
          // 要执行
          isRun = true
        } else if (window._isClickSameTab) {
          window._isClickSameTab = false
          isRun = true
        } else { // 这个时候还是本人，不过其他属性改变了，这个时候要对isShare和isUseShiPin进行判断
          const _old = newVal._old || {}
          
          if (newVal.isShare && !_old.isShare) { // 旧的没开启分享，新的开启了分享
            console.log('tab2User 之前没有分享，现在分享了----')
            // 要执行
            isRun = true
          } else if (!newVal.isShare && _old.isShare) { // 旧的开启了分享，但是新的关闭了
            console.log('tab2User 之前分享，现在不分享了----')
            isRun = true
          } else if (newVal.isUseShiPin && !_old.isUseShiPin) {
            // 也要执行
            isRun = true
          }
        }


        if (isRun) {

          console.log('------执行 tab2 isRun------')

          this.$nextTick(async () => {
            const videoEl = document.getElementById(`tab2-video-${userId}`)
            console.log(videoEl)

            if (!this.isExitUserShare) { // 非共享，上边已经选中了本人，这里只对本人的视频做处理即可
              
              if (isUseShiPin) { // 命中的人是开启视频的
                this.$meetingControl.startLocalPreview(videoEl)
              }
            } else { // 存在共享

              if (this.isExitUserLock) { // 存在锁定人像，显示共享画面

                if (userId !== this.$configs.peerId) {
                  console.log('tab2 分享的人不是自己，这时候要开启其他人的分享---')
                  console.log(videoEl)

                  this.$meetingControl.startRemoteShare(userId, videoEl)
                }
              } else { // 不存在锁定人像

                if (isUseShiPin) { // 命中的人是开启视频的
                  if (userId === this.$configs.peerId) { // 命中的是自己，这时候要重新调用startlocalvideo，将大画面的el传入进去
                    console.log('命中了tab2中的自己')
                  
                    this.$meetingControl.startLocalPreview(videoEl)
                  } else { // 命中了其他用户，同理，再次订阅即可

                    console.log('远端其他人命中了tab2，要开启视频')
                    console.log(videoEl)
                    this.$meetingControl.startRemoteVideo(userId, videoEl)
                  }
                }
              }
            }
          })
        } else {
          console.log('------不执行 tab2 isRun------')
        }
      },
      deep: true,
      immediate: true
    }
  },

  data() {
    return {
      ROLE_CODE      
    };
  },


  methods: {
    dobuleTab2Click(user) {
      this.dobuleClick('tab2', user, this.$parent.$parent.$parent)
    },

    clearVideoSource(userId) {
      const videoEl = document.getElementById(`tab2-video-${userId}`)

      console.error('执行tab2 video清理工作---')
      console.error(videoEl)

      videoEl && (videoEl.srcObject = null)
    }
  },
};
</script>

<style lang="scss" scoped>
.tab2-box {
  position: relative;
  .user-item {
    height: 137px;
    // height: 160px;
    // ::v-deep video{
    //   width:auto;
    // }
  }

  .selfShare {
    color: #fff;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #222222;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    font-size: 16px;
  }
}
</style>
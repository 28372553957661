<template>
  <div>
    <!-- <my-icon class='iconwangluo' :iconName="getIcon" @click.stop="togglePanel"  id="meeting_network_btn_headless"></my-icon> -->
    <img class="iconwangluo" :src="getIcon" @click.stop="togglePanel" alt="">
    <div class="iconwangluoPanel" v-show="panel">
      <div class="wangluoTitle">{{ getTitle }}</div>
      <div class="wangluoInfo" @click="openNetworkSet">{{ $t('meeting.network_look') }}</div>
      <table>
        <!-- 延迟 -->
        <tr>
          <td>
            <div class="wangluoDelayName">{{ $t('meeting.latency') }}:</div>
          </td>
          <!-- TODO: 延迟 以后潘哥提供可以统一换成潘哥的 -->
          <td>
            <div class="wangluoDelayValue">{{networkDelay}}</div>
          </td>
        </tr>

        <!-- 丢包率 -->
        <tr>
          <td>
            <div class="wangluoDiuBaoLvName">{{ $t('meeting.packet') }}:</div>
          </td>
          <td>
            <my-icon class="iconwangluo_shanghang" :fontSize="16" :iconName="'iconwangluo_shanghang_24_hei'"></my-icon>
            <div class="wangluoShangXing">{{sentLossRate}}</div>
            <my-icon class="iconwangluo_xiahang" :fontSize="16" :iconName="'iconwangluo_xiahang_24_hei'"></my-icon>
            <div class="wangluoXiaXing">{{recvLossRate}}</div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      networkInfo: {
        type: Object,
        default: () => {}
      }
    },
    watch: {
      networkInfo(newVal) {
        // console.log(newVal)
      },
      panel(value) {
        if (value && !this.networkInterval) {
          this.getNetWorkStats()
          this.networkInterval = setInterval(this.getNetWorkStats, 2000)
        } else {
          this.networkInterval && clearInterval(this.networkInterval)
          this.networkInterval = null
        }
      }
    },

    mounted() {
      this.$parent.$el.addEventListener("click", () => {
        this.panel = false
      })
    },
    beforeDestroy() {
      this.networkInterval && clearInterval(this.networkInterval)
      this.networkInterval = null
    },
    data() {
      return {
        panel: false,
        networkValue: 0, // 网络值,
        networkInterval: null, //计算延迟 定时器
        networkDelay: '--'
      };
    },
    computed: {
      isNetworkDisconnect() {
        return this.$store.state.meet.isNetworkDisconnect
      },
      sentLossRate() {
        const {
          upAveragePacketLost
        } = this.networkInfo

        if (typeof upAveragePacketLost === 'number' && !isNaN(upAveragePacketLost)) {
           return upAveragePacketLost > 0 ? parseFloat(upAveragePacketLost / 100).toFixed(1) + '%' : '0.0%' 
        }
        return '--'
      },
      recvLossRate() {
        const {
          downAveragePacketLost
        } = this.networkInfo

        if (typeof downAveragePacketLost === 'number' && !isNaN(downAveragePacketLost)) {
          return downAveragePacketLost > 0 ? parseFloat(downAveragePacketLost / 100).toFixed(1) + '%' : '0.0%'
        }
        return '--'
      },
      getIcon() { // TODO:
        if (this.isNetworkDisconnect) { // 显示断网图标
          return  require('@/assets/images/iconwangluo_wuwangluo_24.png')
        }

        const {
          value
        } = this.networkInfo

        // 好
        if (value >= 0 && value <= 2) {
          return require('@/assets/images/iconwangluo_24.png')
        }
        // 一般
        else if (value > 2 && value < 5) {
          return require('@/assets/images/iconwangluo_wangluoruo_24.png')

        }
        // 差
        else if (value >= 5) {
          return require('@/assets/images/iconwangluo_wangluocha_24.png')

        }
        // 断网
        else {
          return require('@/assets/images/iconwangluo_wangluoruo_24.png')
        }
      },
      getTitle() {
        if (this.isNetworkDisconnect) { // 显示断网图标
          return this.$t('meeting.net_wrong')
        }

        const {
          value
        } = this.networkInfo

        // 好
        if (value >= 0 && value <= 2) {
          return this.$t('meeting.network_good')
        }
        // 一般
        else if (value > 2 && value < 5) {
          return this.$t('meeting.new_poor')
        }
        // 差
        else if (value >= 5) {
          return this.$t('meeting.net_ver')
        }
        // 断网
        else {
          return this.$t('meeting.new_poor')
        }
      }
    },
    methods: {
      // 查看网络面板
      openNetworkSet() {
        this.$emit('openNetwork')
      },
      togglePanel() {
        // 防止弹窗堆叠
        !this.panel && this.$parent.$el.click()
        this.panel = !this.panel
      },
      async getNetWorkStats() {
        //local stats
        await this.$deviceControl.computeLocalStats()
        this.networkDelay = this.$store.state.device.stats.rtt ? this.$store.state.device.stats.rtt + 'ms' : '--'
      }
    },
  };
</script>

<style lang="scss" scoped>
  .iconwangluo {
    color: #1ab370;
    cursor: pointer;
    width:24px;
    height:24px;
    margin-top:4px;
    margin-right: 8px;

    &:hover {
      background: rgba(102, 102, 102, 0.3);
    }

    &:focus {
      background: rgba(153, 153, 153, 0.3);
    }
  }

  /* ------------------------------------------------- 会议网络 开始 ------------------------------------------------- */
  @mixin public_style{
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    line-height: 20px;
    font-weight: 400;
  }
  .iconwangluoPanel {
    // height: 183px;
    background: #ffffff;
    border-radius: 8px;
    position: absolute;
    left: 208px;
    top: 58px;
    padding: 24px;
    box-sizing: border-box;

    table {
      width: 100%;
    }

    .wangluoTitle {
      @include public_style;
      color: #333333;
      font-weight: 500;
      font-family: PingFangSC-Medium, PingFang SC;
    }

    .wangluoInfo {
      @include public_style;
      font-weight: 400;
      color: #1ab370;
      margin: 8px 0 0 0;
      cursor: pointer;

      &:hover {
        color: #4DDC9D;
      }

      &:focus {
        color: #0B9659;
      }
    }

    .wangluoDelayName {
      @include public_style;
      //width: 42px;
      color: #999999;
      padding: 6px 0;
    }

    .wangluoDelayValue {
      @include public_style;
    }

    .wangluoDiuBaoLvName {
      @include public_style;
      color: #999999;
    }

    .iconwangluo_shanghang, .iconwangluo_xiahang {
      margin: 5px 0 0 4px;
      float: left;
    }

    .wangluoShangXing,.wangluoXiaXing {
      @include public_style;
      color: #333333;
      margin: 3px 0 0 4px;
      float: left;
    }
    .wangluoCpuName {
      @include public_style;
      color: #999999;
      padding: 6px 0;
    }

    .wangluoCpuValue {
      @include public_style;
      color: #333333;
    }
  }
</style>
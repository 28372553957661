<template>
  <div class="meeting minSize" id='screenshot'>
    <!-- 顶部操作栏 -->
    <div class="top" @mouseover="overMenuBarHandle" v-show="isShowMenuBar">
      <div class="left">
        <!-- 信息 -->
        <meetingTopLeftInfo :mopanInfo="mopanInfo"></meetingTopLeftInfo>
        <!-- 安全 -->
        <meetingTopLeftSafety></meetingTopLeftSafety>
        <!-- 网络 -->
        <meetingTopLeftNetwork :networkInfo="networkInfo" @openNetwork="isShowNetwork = true"></meetingTopLeftNetwork>
        <!-- 录制浮层 -->
        <meetingTopLeftRecord v-if="isUserRecording"></meetingTopLeftRecord>
      </div>
      <div class="right">
        <meetingTopRightUploadLog pos="center"></meetingTopRightUploadLog>
        <!-- 帮助 -->
        <meetingTopRightHelp></meetingTopRightHelp>
        <!-- 客服 -->
        <meetingTopRightKeFu></meetingTopRightKeFu>
        <!-- 免费时长倒计时 -->
        <div v-if="maturityTimeStr" class="freeCoutTime">{{$t('meeting.haveTime')}}{{ maturityTimeStr }}</div>
        <!-- 时间 -->
        <meetingTopRightTime v-show='isShowTime'></meetingTopRightTime>
        <!-- 切换布局 -->
        <meetingTopRightChangeLayout></meetingTopRightChangeLayout>
        <!-- 全屏 -->
        <meetingTopRightScreenfull></meetingTopRightScreenfull>
      </div>
    </div>

    <!-- 视频主区域 -->
    <div @mousemove="menuBarMoveHandler" id="meet-main" class="main">
      <!-- 会场布局 -->
      <layoutGallery v-if="layoutType === LAYOUT_CONFIG.GALLERY9" />
      <layoutGalleryMCU v-if="layoutType === LAYOUT_CONFIG.GALLERY25" />
      <layoutLecturerColumn v-if="layoutType === LAYOUT_CONFIG.COLUMN" :is-show-menu-bar="isShowMenuBar"/>
      <layoutLecturerRow v-if="layoutType === LAYOUT_CONFIG.ROW" :is-show-menu-bar="isShowMenuBar"/>

      <!-- 音频 -->
      <!-- TODO: 这里确认下是否需要为自己创建audio标签，目前来看是不需要的 -->
      <audio
        autoplay
        v-for="(user) in audioList"
        :key="user.userId"
        :id="`audio-${user.userId}`"
        style="display: none;"
        class="audioPlayoutVolumeItem media-reset-flag"
      ></audio>
    </div>

    <!-- 底部操作区 -->
    <div @mouseover="overMenuBarHandle" @mouseleave="menuBarMoveHandler"  id='screenBottom' class="optionWrap" v-show="isShowMenuBar">
      <div class="left-option">
        <!-- 静音 -->
        <meetingAudioBtn @openSetting="openSetting(1)"></meetingAudioBtn>
        <!-- 扬声器 -->
        <meetingSpeakerBtn @openSetting="openSetting(1)"></meetingSpeakerBtn>
        <!-- 视频 -->
        <meetingVideoBtn @openSetting="openSetting(2)"></meetingVideoBtn>
      </div>

      <div class="middle-section">
        <!-- 共享 -->
        <meetingShareBtn></meetingShareBtn>

        <!-- 其他功能区 -->
        <div class="otherOption">
          <!-- 录制 -->
          <meetingRecordBtn ></meetingRecordBtn>

          <!-- 成员管理 -->
          <meetingUserManagerBtn></meetingUserManagerBtn>

          <!-- 聊天 -->
          <meetingChatBtn></meetingChatBtn>

          <!-- 议程 -->
          <div
            class="agenda-box"
            @click="iframeOpen"
          >
            <!-- <my-icon
              :target="'agendaIcon'"
              :iconName="'iconyicheng_24_hei'"
            ></my-icon> -->
            <img src="@/assets/images/iconyicheng_24_hei.png" width="24px" height="24px"/>

            <div class="agendaInfo">{{$t('meeting.agenda')}}</div>
          </div>

          <!-- 倒计时 -->
          <meetingCountdownBtn ref="meetingCountdownBtn" @handleCount="handleCount"></meetingCountdownBtn>
          <!-- 截图至云端 -->
          <MeetinScreenHotBtn></MeetinScreenHotBtn>


          <!-- 问卷 -->
          <div
            class="agenda-box wenjuanBtn"
            @click="wenjuanIframeOpen"
          >
            <!-- <my-icon
              :target="'agendaIcon'"
              :iconName="'iconyicheng_24_hei'"
            ></my-icon> -->
            <img src="@/assets/images/iconyicheng_24_hei.png" width="24px" height="24px"/>
            <div class='circle' v-if="isShowWenjuanTips"></div>
            <div class="agendaInfo">问卷</div>
          </div>
        </div>
      </div>

      <!-- 结束会议 -->
      <meetingLogoutBtn ref="meetingLogoutBtn" @logoutMeeting="logoutMeeting" @hostEndMeeting="hostEndMeeting"></meetingLogoutBtn>
    </div>

    <!-- 倒计时 -->
    <meetingCountdownTip
      :minutes="countDownTipMinutes"
      :seconds="countDownTipSeconds"
      :type="countDownTipType"
      @close="closeTip"
      @reset="resetTip"
    ></meetingCountdownTip>

    <!-- 断网提示 -->
    <div class="disconnect-box" v-if="isNetworkDisconnect">
      <div class="icon"></div>

      <div class="text">{{$t('meeting.instability')}}</div>
    </div>

    <!-- 会议设置弹窗 -->
    <meetingSettings :isMeeting="true" @openNetwork="isShowNetwork = true"></meetingSettings>

    <!-- 网络检测 -->
    <meetingNetworkBox @close="isShowNetwork=false" :isShowPanel="isShowNetwork" />

    <!-- 会议议程 -->
    <el-dialog
      v-dialogDrag
      custom-class="el-dialog-drag el-dialog-iframe"
      :title="$t('login.agenda')"
      :visible.sync="isOK"
      :modal="false"
      width="600px"
      @closed="iframeBeforeClosed"
      :append-to-body="true"
      :close-on-click-modal="true"
    >
      <span class="iframe-box">
        <iframe
          :src="webviewUrl"
          frameborder="0"
          name="showHere"
          scrolling="auto"
          width="100%"
          height="430px"
          class="iframe-item"
          @load="iframeLoad"
          v-if="webviewUrl"
        >
        </iframe>
        <span
          class="iframe-loading-box"
          v-if="iframeLoading"
        >
          <img
            src="~@/assets/images/agendas_loading_48.gif"
            alt=""
            srcset=""
            class="iframe-loading-img"
          >
        </span>
      </span>
    </el-dialog>

    <!-- toast提示 -->
    <div v-if="isShowToast" class="user-totast">{{ toastText }}</div>

    <!-- 水印 -->
    <div v-if="watermark" class="watermarkBox">{{watermark}}</div>

    <!-- 提示音 -->
    <audio class="media-reset-flag" id="userEnterTipAudio" style="display: none;" src="~@/assets/some_one_join_room.wav"></audio>

    <!-- 通用提示弹窗 -->
    <el-dialog
      :title="diyTip?diyTip:$t('login.tips')"
      class="commonElDialog"
      :modal="false"
      :visible.sync="commonTipDialog"
      width="380px"
    >
      <span>{{ commonTipText }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="commonTipDialog = false;diyTip=''">{{$t('login.know')}}</el-button>
      </span>
    </el-dialog>

    <!-- 通用返回首页提示弹窗 -->
    <el-dialog
      custom-class="commonElDialog"
      width="380px"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="commonBackDialog"
    >
      <div class="dialog-title">
        <div class="icon error"></div>
        <div class="text">{{$t('login.tips')}}</div>
      </div>

      <div class="dialog-content">
        {{$t('meeting.enterError')}}
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="goMeetIndex">{{$t('login.know')}}</el-button>
      </span>
    </el-dialog>

    <!-- 倒计时弹窗 -->
    <el-dialog
      :title="intervalTip"
      class="commonElDialog"
      :modal="false"
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="intervalDialog"
      width="380px"
    >
      <span>{{ intervalText }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="intervalDialog = false">{{$t('login.know')}}({{ removeTime }}s)</el-button>
      </span>
    </el-dialog>


    <!-- 被强制踢出会议 -->
    <el-dialog
      :title="$t('login.tips')"
      class="commonElDialog"
      :modal="false"
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="exitMeetDialog"
      width="380px"
    >
      <span>{{ exitMeetText }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="shortlyExitMeet">{{$t('login.know')}}({{ removeTime }}s)</el-button>
      </span>
    </el-dialog>

    <!-- 是否开启音频 -->
    <el-dialog
      :title="$t('login.tips')"
      class="commonElDialog"
      :modal="false"
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="unMuteAudioDialog"
      width="380px"
    >
      <span>{{$t('meeting.pleacenoVoice')}}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resumeAudioDialogHandle">{{$t('meeting.audioUnMute')}}</el-button>
        <el-button type="primary" @click="unMuteAudioDialog = false">{{$t('meeting.keepQuiet')}}</el-button>
      </span>
    </el-dialog>

    <!-- 连接超时 -->
    <el-dialog
      custom-class="commonElDialog"
      width="380px"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="networkTimeoutDialog"
    >
      <div class="dialog-title">
        <div class="icon error"></div>
        <div class="text">{{$t('meeting.abnormality')}}</div>
      </div>

      <div class="dialog-content">
        {{$t('meeting.exception')}}
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="reJoinMeetHandle">{{$t('seeting.joinAgain')}}</el-button>
        <el-button type="primary" @click="logoutMeetHandle">{{$t('login.leaveMeet')}}</el-button>
      </span>
    </el-dialog>

    <!-- 共享中断弹窗 -->
    <el-dialog
      custom-class="commonElDialog"
      width="380px"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="shareStopDialog"
    >
      <div class="dialog-title">
        <div class="icon error"></div>
        <div class="text">{{$t('login.tips')}}</div>
      </div>

      <div class="dialog-content">
        {{$t('meeting.againShare')}}
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="shareStopDialog = false">{{$t('login.know')}}</el-button>
      </span>
    </el-dialog>


    <!-- 问卷 -->
    <el-dialog
      v-dialogDrag
      custom-class="el-dialog-drag el-dialog-iframe wenjuann-dialog-drag"
      :visible.sync="isOpenWenjuan"
      :modal="false"
      title="问卷"
      width="600px"
      @closed="iframeBeforeClosed"
      :append-to-body="true"
      :close-on-click-modal="true"

    >
      <span class="iframe-box">
        <iframe
          :src="questionnaireUrl"
          frameborder="0"
          name="showHere"
          scrolling="auto"
          width="100%"
          height="430px"
          class="iframe-item"
          @load="iframeLoad"
          v-if="questionnaireUrl"
        >
        </iframe>
        <span
          class="iframe-loading-box"
          v-if="iframeLoading"
        >
          <img
            src="~@/assets/images/agendas_loading_48.gif"
            alt=""
            srcset=""
            class="iframe-loading-img"
          >
        </span>
      </span>
    </el-dialog>

    <!-- 问卷询问 -->
    <el-dialog
       title="收到问卷"
       class="commonElDialog"
       :modal="false"
       :show-close="false"
       :close-on-click-modal="false"
       :visible.sync="isOpenWenjuanAsk"
       width="380px"
     >
       <span>收到主持人向您发送的问卷，是否现在前往填写</span>
       <span slot="footer" class="dialog-footer">
         <el-button @click="isOpenWenjuanAsk=false,isShowWenjuanTips = true">稍后填写</el-button>
         <el-button type="primary" @click="questionnaireUrl = `${questionnaireUrlStr}&roleCode=${selfInfo.roleCode}`,isOpenWenjuan=true,isOpenWenjuanAsk=false,isShowWenjuanTips=false">前往</el-button>
       </span>
     </el-dialog>
  </div>

</template>

<script>
import Vue from 'vue'
import { loganLog } from "@/utils/log"

import screenfull from "screenfull"
import throttle from 'lodash.throttle'

import MeetingManager from '@/lib/core/meetingManager'
import ExceptionManager from '@/lib/core/exceptionManager'

import { QRTCQuality } from "@/meet-sdk/module/rtc/lib/RoomParams"

import { getUuid, getAvatar, getAcceptLanguage, setMeetInfo,getMopanLinks,getReJoinUid,getMeetToken } from "@/utils/auth";
import { getLocalDeviceInfo } from "@/utils/device"
import { countDownTimer } from "@/utils/countDown";
import { strMiddleEllipsis } from "@/utils/str"
import { isSafari } from "@/utils/mobile"

import { ROLE_CODE, CONTROL_COMMAND, LAYOUT_CONFIG } from "@/constant/index";
import { meetAgendasUrl } from "@/config/index";

import { fetchReconnectionInfo, joinConference, fetchMeetControl, fetchConference, fetchConferenceSnowflake, landingConfirm } from '@/api/meet'

import LayoutGallery from "./Meeting.Layout.Gallery.vue";
import LayoutGalleryMCU from "./Meeting.Layout.GalleryMCU.vue";
import LayoutLecturerRow from "./Meeting.Layout.Lecturer.Row.vue";
import LayoutLecturerColumn from "./Meeting.Layout.Lecturer.Column.vue";

import MeetingTopLeftInfo from "./top/Meeting.Left.Info.vue";
import MeetingTopLeftSafety from "./top/Meeting.Left.Safety.vue";
import MeetingTopLeftNetwork from "./top/Meeting.Left.Network.vue";
import MeetingTopLeftRecord from "./top/Meeting.Left.Record.vue";

import MeetingTopRightUploadLog from "./top/Meeting.Right.UploadLog.vue";
import MeetingTopRightHelp from "./top/Meeting.Right.Help.vue";
import MeetingTopRightKeFu from "./top/Meeting.Right.KeFu.vue";
import MeetingTopRightTime from "./top/Meeting.Right.Time.vue";
import MeetingTopRightChangeLayout from "./top/Meeting.Right.ChangeLayout.vue";
import MeetingTopRightScreenfull from "./top/Meeting.Right.Screenfull.vue";

import MeetingAudioBtn from "./buttom/Meeting.AudioBtn.vue";
import MeetingSpeakerBtn from "./buttom/Meeting.SpeakerBtn.vue";
import MeetingVideoBtn from "./buttom/Meeting.VideoBtn.vue";
import MeetingShareBtn from "./buttom/Meeting.ShareBtn.vue";
import MeetingLogoutBtn from "./buttom/Meeting.LogoutBtn.vue";
import MeetingRecordBtn from "./buttom/Meeting.RecordBtn.vue";
import MeetingUserManagerBtn from "./buttom/Meeting.UserManagerBtn.vue";
import MeetingChatBtn from "./buttom/Meeting.ChatBtn.vue";
import MeetingCountdownBtn from "./buttom/Meeting.CountdownBtn.vue";
import MeetingCountdownTip from "./buttom/Meeting.CountDownTip.vue";
import MeetinScreenHotBtn from "./buttom/Meeting.screenHot.vue";

import MeetingSettings from "./Meeting.Settings.vue";
import MeetingNetworkWindow from "./Meeting.Network.Window.vue";
import { DIALOG_BACK_ERR_CODE } from "@/constant/index"

export default {
  components: {
    layoutGallery: LayoutGallery,
    layoutGalleryMCU: LayoutGalleryMCU,
    layoutLecturerRow: LayoutLecturerRow,
    layoutLecturerColumn: LayoutLecturerColumn,

    meetingTopLeftInfo: MeetingTopLeftInfo,
    meetingTopLeftSafety: MeetingTopLeftSafety,
    meetingTopLeftNetwork: MeetingTopLeftNetwork,
    meetingTopLeftRecord: MeetingTopLeftRecord,

    meetingTopRightUploadLog: MeetingTopRightUploadLog,
    meetingTopRightHelp: MeetingTopRightHelp,
    meetingTopRightKeFu: MeetingTopRightKeFu,
    meetingTopRightTime: MeetingTopRightTime,
    meetingTopRightChangeLayout: MeetingTopRightChangeLayout,
    meetingTopRightScreenfull: MeetingTopRightScreenfull,

    meetingAudioBtn: MeetingAudioBtn,
    meetingSpeakerBtn: MeetingSpeakerBtn,
    meetingVideoBtn: MeetingVideoBtn,
    meetingShareBtn: MeetingShareBtn,
    meetingLogoutBtn: MeetingLogoutBtn,
    meetingRecordBtn: MeetingRecordBtn,
    meetingUserManagerBtn: MeetingUserManagerBtn,
    meetingChatBtn: MeetingChatBtn,
    meetingCountdownBtn: MeetingCountdownBtn,
    meetingCountdownTip: MeetingCountdownTip,
    MeetinScreenHotBtn,

    meetingSettings: MeetingSettings,
    meetingNetworkBox: MeetingNetworkWindow
  },

  data() {
    return {
      waitLoading: null,

      LAYOUT_CONFIG,
      // isNeedDisconnect: true, // 需要清理
      isNeedDisconnect: true, // 需要清理

      maturityTimer: null, // 免费时长
      maturityTimeStr: '', // 免费时长字符串

      isShowToast: false,
      toastText: '',
      toastTimer: null,

      // 离开会议
      removeTimer: null,
      removeTime: 3,
      exitMeetDialog: false,
      exitMeetText: "",

      // 通用弹窗提示
      commonTipDialog: false,
      commonTipText: "",

      diyTip:"",
      // 通用返回首页弹窗提示
      commonBackDialog: false,

      // 连接超时弹窗
      networkTimeoutDialog: false,

      // 请求解除静音
      unMuteAudioDialog: false,

      // 提供共享停止弹窗
      shareStopDialog: false,

      agendaId: "", // 议程ID
      agendaPermission: 0, // 会议权限

      countDownTipMinutes: 0, // 倒计时提示框分钟
      countDownTipSeconds: 0, // 倒计时提示框秒钟
      countDownTipType: "-", // 倒计时类型

      // 顶部和底部操作栏
      menuBarTimer: null,
      isShowMenuBar: true,

      webviewUrl: "",
      isOK: false,
      iframeLoading: true,

      // 网路检测
      isShowNetwork: false,
      networkInfo: {},

      // 退房锁定
      isLockExitRoom: false,

      //
      intervalTip : '',
      intervalText : '',
      intervalDialog : false,

      isShowWenjuanTips: false, //是否显示提示
      questionnaireUrl:'',//问卷地址
      questionnaireUrlStr:'',
      isOpenWenjuan:false,//是否打开问卷
      isOpenWenjuanAsk:false,//问卷询问
      mopanInfo:{},
      watermark:'',//水印
    };
  },

  computed: {
    isFullModel() {
      return this.$store.state.meet.isFullModel
    },

    isNetworkDisconnect() {
      return this.$store.state.meet.isNetworkDisconnect
    },
    isShowTime(){
      return this.$store.state.meet.joinDuration
    },
    layoutType() { // 布局模式
      return this.$store.state.meet.layoutType;
    },

    userList() { // 成员列表
      return this.$store.getters["member/getRealUserList"]
    },

    audioList() { // 音频队列
      //todo audioList17路没限制住
      return this.$store.state.member.audioList
    },

    selfInfo() {
      const userId = this.$route.params.userID;
      const roomId = this.$route.query.roomID;

      return this.$store.getters["member/getUser"](`${roomId}_${userId}`)
    },

    isHost() {
      return this.selfInfo.roleCode === ROLE_CODE.HOST;
    },

    isCoHost() {
      return this.selfInfo.roleCode === ROLE_CODE.CO_HOST;
    },

    isOrdinaryUser() {
      return this.selfInfo.roleCode === ROLE_CODE.USER;
    },

    isUserRecording() {
      return this.userList.find(item => {
        return item.isRecord && !item.recordPaused
      })
    },
    isExitUserShare() { // 存在用户共享
      return this.userList.find(i => i.isShare)
    }
  },

  async created() {
    const routeQuery = this.$route.query

    let userId = this.$route.params.userID
    let roomId = routeQuery.roomID
    let conferenceNo = routeQuery.conferenceNo

    // 设置configs值
    this.$configs.userId = userId;
    this.$configs.roomId = roomId;
    this.$configs.userName = decodeURIComponent(routeQuery.userName)
    this.$configs.avatarUrl = getAvatar()
    this.$configs.peerId = roomId + '_' + userId;
    this.$configs.conferenceNo = conferenceNo;

    !isSafari() && window.addEventListener('storage', this.handleStorageEvent)

    this.initScreenfullEvent()

    this.getQuestionnaireUrl()

    this.waitLoading = this.$loading({
      lock: true,
      text: this.$t('meeting.goMeeting'),
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)',
      customClass: 'waitMeetLoading'
    })
    try {
      const resData = await fetchReconnectionInfo({
        userId: userId,
        deviceId: getUuid(),
        conferenceNo: conferenceNo,
        roomId: roomId
      })

      !isSafari() && window.localStorage.setItem('exit-msg', new Date().getTime())

      /**
       * attendList中的数据来源：
       * 用户本人和房间内其他用户的信息都有可能返回，只要满足以下任意一个条件：
       * 1. 不是普通用户
       * 2. 正在举手
       * 3. 正在录制
       */
      const {
        attendList,
        conference,
        roleCode
      } = resData;

      const conferenceToken = resData['X-Conference-Token']
      const channelToken = resData['X-Channel-Token']

      setMeetInfo(
        conferenceToken,
        channelToken
      )

      this.$router.replace({
        query: {
          ...this.$route.query,
          conferenceNo: conference.conferenceNo
        }
      })

      //从缓存配置中获取 是否显示参会时长
      const localDeviceData = getLocalDeviceInfo()
      const joinDuration = localDeviceData.joinDuration
      
      // 存储全局会议状态
      this.$store.commit("meet/updateGlobalMeetState", {
        allowEarlyEntry: conference.allowEarlyEntry,
        muteJoinMeeting: conference.muteJoinMeeting,
        playTips: conference.playTips,
        allowSelfUnmute: conference.allowSelfUnmute,
        ownerPasswordEnable: conference.ownerPasswordEnable,
        passwordEnable: conference.passwordEnable,
        agendaPermission: conference.agendaPermission,
        allMuteState: conference.allMuteState,
        recordPermission: conference.recordPermission,
        sharePermission: conference.sharePermission,
        lockedState: conference.lockedState,

        ownerName: conference.ownerName,
        ownerId: conference.ownerId,
        links: conference.links,
        userRoleCode: Number(roleCode),
        meetName: conference.title,
        password:conference.password,
        joinDuration,
        cloudRecorState:conference.cloudRecordState
      });

      if (Array.isArray(attendList) && attendList.length > 0) {
        attendList.forEach((user) => {
          // raiseHandStatus 举手状态 1:举手 0：手放下
          // recordStatus 录制状态 1：已录制  0：停止录制
          const { peerId, raiseHandStatus, recordStatus, roleCode } = user;

          const stateInfo = {
            isRaiseHand: !!raiseHandStatus,
            isRecord: !!recordStatus,
          };

          this.$configs.attendMap[peerId] = {
            userId: peerId,
            roleCode: Number(roleCode),
            ...stateInfo,
          };
        });
      }

      // 链接im
      this.$i100MeetSDK.i100IM.enterRoom({
        roomid: roomId,
        channelToken
      })

      // 初始化会议sdk
      this.initMeetControl({
        conferenceToken
      })

      //上报数据
      this.runShenceTimer();
    } catch (error) {
      this.isNeedDisconnect = false
      // console.error(444442,error)
      this.waitLoading.close()

      let describe  = error.message? `错误信息:${error.message}` :  error.msg? `错误信息:${error.msg}` : ''
      if (error.message && error.message === 'Network Error') {
        describe = this.$t('meeting.netWorkBad')
      }
      const commonText  = error.code?`错误码:${error.code}`: this.$t('meeting.enterError')
      this.$store.commit("meet/updateGlobalMeetState", {
        meetDialogInfo: {
          tips: error.code? "加入会议失败" : "",
          isGoIndex: true,
          showClose: false,
          describe:describe,
          commonText: commonText
        }
      })
    }
  },
  methods: {
    // 获取问卷地址
    async getQuestionnaireUrl(){
      try {
        const resData = await landingConfirm({
          token: getMeetToken()
        })
        this.questionnaireUrl = resData.info.questionnaireUrl
        this.questionnaireUrlStr = resData.info.questionnaireUrl
        if(resData.project && resData.project == 20){
          this.mopanInfo = resData.info
        }
        if(resData.info.onlineWatermark){
          let time = new Date()
          let y = time.getFullYear();
          let m = time.getMonth() + 1;
          let d = time.getDate();
          this.watermark =resData.info.mobile + resData.info.username + (y-2000)+'年'+m+'月'+d+'日'
        }
      } catch (error) {
        loganLog(`获取Confirm信息失败-----error:${JSON.stringify(error)}`)

        setTimeout(() => {
          this.getQuestionnaireUrl()
        }, 1000);
      }
    },
    //数据上报
    runShenceTimer(){
      this.shenceDataTimer = setInterval(() => {
        // const _localMediaStats = this.$i100MeetSDK.i100MeetingControl.getLocalMediaStats()
        // const _remoteMediaStats = this.$i100MeetSDK.i100MeetingControl.getRemoteMediaStats()
        // const _isUseHuaTong = this.selfInfo.isUseHuaTong
        // const _isUseShiPin = this.selfInfo.isUseShiPin
        // const _isShare = this.selfInfo.isShare

        // // console.error('isUseHuaTong:',_isUseHuaTong)
        // // console.error('isUseShiPin:',_isUseShiPin)
        // // console.error('isShare:',_isShare)
        // // console.error('getLocalMediaStats:',_localMediaStats)
        // // console.error('getRemoteMediaStats:',_remoteMediaStats)

        // let trackData = {
        //   uar : _localMediaStats[1] ? _localMediaStats[1].packetBitrate : '',  // 上行音频速率
        //   uaplr : _localMediaStats[1] ? _localMediaStats[1].packetsLostRatio : '', // 上行音频丢包率
        //   uad : _localMediaStats[1] ? _localMediaStats[1].jitterBufferDelay : '',// 上行音频延迟
        //   uanj : _localMediaStats[1] ? _localMediaStats[1].jitter : '', // 上行音频网络抖动
        //   uvr : _localMediaStats[0] ? _localMediaStats[0].packetBitrate : '', // 上行视频速率
        //   uvplr : _localMediaStats[0] ? _localMediaStats[0].packetsLostRatio : '', // 上行视频丢包率
        //   uvd : _localMediaStats[0] ? _localMediaStats[0].jitterBufferDelay : '', // 上行视频延迟
        //   uvnj : _localMediaStats[0] ? _localMediaStats[0].jitter : '', // 上行视频网络抖动
        //   uvfr : _localMediaStats[0] ? _localMediaStats[0].framesPerSecond : '', // 上行视频帧率
        //   usr : _localMediaStats[2] ? _localMediaStats[2].packetBitrate : '', // 上行共享速率
        //   usplr : _localMediaStats[2] ? _localMediaStats[2].packetsLostRatio : '', // 上行共享丢包率
        //   usd : _localMediaStats[2] ? _localMediaStats[2].jitterBufferDelay : '', // 上行共享延迟
        //   usnj : _localMediaStats[2] ? _localMediaStats[2].jitter : '', // 上行共享网络抖动
        //   usfr : _localMediaStats[2] ? _localMediaStats[2].framesPerSecond : '', // 上行共享帧率

        //   dar : _remoteMediaStats[1] ? _remoteMediaStats[1].packetBitrate : '', // 下行音频速率
        //   daplr : _remoteMediaStats[1] ? _remoteMediaStats[1].packetsLostRatio : '', // 下行音频丢包率
        //   dad : _remoteMediaStats[1] ? _remoteMediaStats[1].jitterBufferDelay : '', // 下行音频延迟
        //   danj : _remoteMediaStats[1] ? _remoteMediaStats[1].jitter : '', // 下行音频网络抖动
          
        //   dvr : _remoteMediaStats[0] ? _remoteMediaStats[0].packetBitrate : '', // 下行视频速率
        //   dvplr : _remoteMediaStats[0] ? _remoteMediaStats[0].packetsLostRatio : '', // 下行视频丢包率
        //   dvd : _remoteMediaStats[0] ? _remoteMediaStats[0].jitterBufferDelay : '', // 下行视频延迟
        //   dvnj : _remoteMediaStats[0] ? _remoteMediaStats[0].jitter : '', // 下行视频网络抖动
        //   dvfr : _remoteMediaStats[0] ? _remoteMediaStats[0].framesPerSecond : '', // 下行视频帧率
          
        //   dsr : _remoteMediaStats[2] ? _remoteMediaStats[2].packetBitrate : '', // 下行共享速率
        //   dsplr : _remoteMediaStats[2] ? _remoteMediaStats[2].packetsLostRatio : '', // 下行共享丢包率
        //   dsd : _remoteMediaStats[2] ? _remoteMediaStats[2].jitterBufferDelay : '', // 下行共享延迟
        //   dsnj : _remoteMediaStats[2] ? _remoteMediaStats[2].jitter : '', // 下行共享网络抖动
        //   dsfr : _remoteMediaStats[2] ? _remoteMediaStats[2].framesPerSecond : '', // 下行共享帧率

        //   as : _isUseHuaTong ? 1 : 0, // 音频开启状态
        //   vs : _isUseShiPin ? 1 : 0, // 视频开启状态
        //   ss : _isShare ? 1 : 0, // 共享开启状态
        // }

        // // console.error('isUseHuaTong:',_isUseHuaTong)
        // // console.error('isUseShiPin:',_isUseShiPin)
        // // console.error('isShare:',_isShare)

        // this.$sensors.track('MeetingApp', {
        //   ...trackData
        // });
      }, 15000);
    },

    /**
     * storage events
     */
    handleStorageEvent(e) {
      console.error('收到storage events')

      if (e.key === 'exit-msg') {
        this.exitMeetByTimer("您已在其他页签加入，当前页签被移出会议")

      }
    },
    
    goMeetIndex() {
      this.commonBackDialog = false
      // this.$router.push('/')
      this.$router.push({
          name: 'join'
        })
      // window.location.href = getMopanLinks();
    },


    /**
     * 初始化会议sdk
     */
    async initMeetControl(options = {}) {
      // 初始化sdk
      Vue.prototype.$meetingControl = new MeetingManager(this)

      // 初始化订阅异常处理单元
      Vue.prototype.$exceptionManager = new ExceptionManager(this)

      // 初始化sdk事件
      this.initMeetControlEvent()

      // 进房
      try {
        await this.$meetingControl.enterRoom(options)
        console.error('进房成功----')
        this.waitLoading.close()
      } catch (error) {
        this.isNeedDisconnect = false
        this.waitLoading.close()
        console.error(44441,error.errorCode)
        loganLog(`进房失败-----error:${JSON.stringify(error)}`)
        //1.6新增SDK异常处理，避免多次处理进房失败
        if(JSON.stringify(error) == '{}' || !error || error.errorCode!== undefined && !(DIALOG_BACK_ERR_CODE.includes(error.errorCode))){
          this.commonBackDialog = true
        }
        const { peerId, userName } = this.$configs
        this.$sentry.captureException({
          msg: '进房失败',
          userId: peerId,
          userName,
          error
        })
        return
      }


      // updata设备列表
      try {
        await this.$deviceControl.undataDeviceList()
      } catch (error) {
        loganLog(`updata设备列表-----error:${JSON.stringify(error)}`)
        const { peerId, userName } = this.$configs
        this.$sentry.captureException({
          msg: 'updata设备列表失败',
          userId: peerId,
          userName,
          error
        })
      }
      
      // 初始化摄像头
      try {
        await this.$deviceControl.initCurrentCameraDevice()
      } catch (error) {
        loganLog(`初始化摄像头失败-----error:${JSON.stringify(error)}`)
        const { peerId, userName } = this.$configs
        this.$sentry.captureException({
          msg: '初始化摄像头失败',
          userId: peerId,
          userName,
          error
        })
      }
    },

    loganLogFn(msg){
      loganLog(msg)
    },
    /**
     * 初始化sdk事件
     */
    initMeetControlEvent() {
      // 注册web-sdk改名监听事件
      this.initChangeNameEvent();

        // 注册web-sdk踢人监听事件
      this.initUserExitRoomEvent()

      // 注册web-sdk会控（customCommand）监听事件
      this.initMeetingControlEvent();

      // 注册web-sdk 错误事件
      this.initMeetingErrorEvent()

      // 注册web-sdk 网络检测
      this.initCheckNetwork()

      // 注册web-sdk连接断开通知
      this.initMeetingDisconnected()

      // 注册断网重连超时事件
      this.initMeetingTimeout()

      // 注册log
      this.initSDKLog()
    },


    /**
     * 注册全屏事件
     */
    initScreenfullEvent() {
      // 全屏改变事件
      screenfull.onchange(() => {
        this.$store.commit("meet/updateGlobalMeetState", {
          isFullModel: screenfull.isFullscreen
        })
      })
    },
    
    /**
     * 注册web-sdk 连接断开通知
     */
    initMeetingDisconnected() { 
      let _disconnectedEventCount = this.$i100MeetSDK.listenerCount('disconnected')
      if(_disconnectedEventCount == 0){
        this.$i100MeetSDK.on('disconnected', () => {
          loganLog(`disconnected 连接断开了---出现图标`)

          this.$store.commit("meet/updateGlobalMeetState", {
            isNetworkDisconnect: true
          })
          this.showToast(this.$t('meeting.netWorkBad'))
        })
      }

      let _websocketMayDisconnectedEventCount = this.$i100MeetSDK.listenerCount('websocketMayDisconnected')
      if(_websocketMayDisconnectedEventCount == 0){
        this.$i100MeetSDK.on('websocketMayDisconnected', () => {
          loganLog(`websocketMayDisconnected ping pong监测断网---出现图标`)
          this.$store.commit("meet/updateGlobalMeetState", {
            isNetworkDisconnect: true
          })

          // this.showToast(this.$t('meeting.netWorkBad'))
        })
      }
      let _websocketStillAliveDisconnectedEventCount = this.$i100MeetSDK.listenerCount('websocketStillAlive')
      if(_websocketStillAliveDisconnectedEventCount == 0){
        this.$i100MeetSDK.on('websocketStillAlive', () => {
          loganLog(`websocketStillAlive ping pong 断网恢复---出现图标`)
          this.$store.commit("meet/updateGlobalMeetState", {
            isNetworkDisconnect: false
          })
        })
      }
    },


    /**
     * 重新入会
     */
    async reJoinMeetHandle() {
      // 重新调用join
      const { conferenceNo, userName } = this.$configs
      const _userid  = getReJoinUid()

      try {
        const resData = await joinConference({
          conferenceNo,
          closeConference: true,
          userId:_userid,
          userName
        })

        const { conference, roomid, userid } = resData

        setMeetInfo(
          resData["X-Conference-Token"],
          resData["X-Channel-Token"]
        )

        this.$router.replace({
          params: { userID: userid },
          query: {
            ...this.$route.query,
            roomID: roomid,
            conferenceNo: conference.conferenceNo
          }
        })

        window.location.reload()
      } catch (error) {
        this.logoutMeetHandle()
      }
    },

    /**
     * 离开会议
     */
    logoutMeetHandle() {
      this.networkTimeoutDialog = false
      console.error("离开会议，logoutMeetHandle")
      this.logoutMeeting()
    },


    /**
     * 注册web-sdk错误处理回调
     */
    initMeetingErrorEvent() {
      let _eventCount = this.$i100MeetSDK.listenerCount('error')
      if(_eventCount>0){ //如果注册过了就返回 避免多次注册   重复的问题以后再查一下
        return
      }
      this.$i100MeetSDK.on('error', error => {
        console.error('sdk抛出错误-----')
        console.log(error)

        this.$sentry.captureException({
          msg: 'sdk抛出错误-----',
          userId: this.$configs.peerId,
          error
        })
      })
    },

    /**
     * 注册web-sdk会控通知回调
     */
    initMeetingControlEvent() {
      let _eventCount = this.$i100MeetSDK.listenerCount('customCommand')
      if(_eventCount>0){ //如果注册过了就返回 避免多次注册   重复的问题以后再查一下
        return
      }
      this.$i100MeetSDK.on("customCommand", (data) => {
        console.log("----收到会控通知--------");
          
        try {
          data.metadata = JSON.parse(data.metadata);
        } catch (error) {
          data.metadata = {};
        }

        console.log(data);

        switch (data.command) {
          case CONTROL_COMMAND.HOST_JOIN_ROOM:
            this.handleHostJoinRoom(data);
            break;

          case CONTROL_COMMAND.AUTOMATICGRANT:
            this.handleAutomaticGrant(data);
            break;

          case CONTROL_COMMAND.CHANGE_NAME:
            this.handleChangeName(data);
            break;

          case CONTROL_COMMAND.GRANT:
            this.handleGrant(data);
            break;

          case CONTROL_COMMAND.TRANSFER_HOST:
            this.handleTransferHost(data);
            break;

          case CONTROL_COMMAND.RECOVER_HOST_PERMISSION:
            this.handleRecoverHostPermission(data);
            break;

          case CONTROL_COMMAND.SINGLE_MUTE:
            this.handleSingleMute(data);
            break;

          case CONTROL_COMMAND.SINGLE_UNMUTE:
            this.handleSingleUnMute(data);
            break;

          case CONTROL_COMMAND.STOP_SHARE:
            this.handleStopShare(data);
            break;

          case CONTROL_COMMAND.SHIELD_VIDEOS:
            this.handleShieldVideos(data);
            break;


          case CONTROL_COMMAND.START_RECORD:
            this.handleRecord(data, 'isRecord', true);
            break;

          case CONTROL_COMMAND.STOP_RECORD:
            this.handleRecord(data, 'isRecord', false);
            break;

          case CONTROL_COMMAND.PAUSE_RECORD:
            this.handleRecord(data, 'recordPaused', true);
            break;

          case CONTROL_COMMAND.RESUME_RECORD:
            this.handleRecord(data, 'recordPaused', false);
            break;


          case CONTROL_COMMAND.REMOVE_SELF:
            this.handleRemoveSelf(data);
            break;

          // 不会发起了，改成了rtc的通知
          // case CONTROL_COMMAND.REMOVE_USER:
          //   this.handleRemoveUser(data);
          //   break;

          case CONTROL_COMMAND.RAISE_HAND:
            this.handleRaiseHand(data);
            break;

          case CONTROL_COMMAND.SINGLE_HAND_DOWN:
            this.handleDownHand(data);
            break;

          case CONTROL_COMMAND.ALL_HAND_DOWN:
            this.handleAllHandDown(data);
            break;

          /* ------------全局会议状态处理---------------- */
          // 不会发起了，改成了rtc的通知
          // case CONTROL_COMMAND.END_MEET:
          //   this.handleEndMeet();
          //   break;

          // 不会发起了，改成了rtc的通知
          // case CONTROL_COMMAND.FORCE_END_MEET:
          //   this.handleForceEndMeet();
          //   break;

          case CONTROL_COMMAND.LOCK_CONFERENCE:
            this.handleLockConference(data);
            break;

          case CONTROL_COMMAND.UNLOCK_CONFERENCE:
            this.handleUnLockConference(data);
            break;

          case CONTROL_COMMAND.ALLOW_SELF_UNMUTE:
            this.handleAllowSelfUnmute(data);
            break;

          case CONTROL_COMMAND.FORBID_SELF_UNMUTE:
            this.handleForbidSelfUnmute(data);
            break;

          case CONTROL_COMMAND.OPEN_PLAY_TIPS:
            this.handleOpenPlayTipse(data);
            break;

          case CONTROL_COMMAND.CLOSE_PLAY_TIPS:
            this.handleClosePlayTipse(data);
            break;

          case CONTROL_COMMAND.OPEN_MUTE_JOIN_MEETING:
            this.handleOpenMuteJoinMeeting(data);
            break;

          case CONTROL_COMMAND.CLOSE_MUTE_JOIN_MEETING:
            this.handleCloseMuteJoinMeeting(data);
            break;

          case CONTROL_COMMAND.ALL_FORCE_MUTE:
            this.handleAllForceMute(data);
            break;

          case CONTROL_COMMAND.ALL_UNFORCE_MUTE:
            this.handleAllUnForceMute(data);
            break;

          case CONTROL_COMMAND.ALL_UNMUTE:
            this.handleAllUnMute(data);
            break;

          case CONTROL_COMMAND.SHARE_PERMISSIONS_ALL:
            this.handleSharePermissionsForAll(data);
            break;

          case CONTROL_COMMAND.SHARE_PERMISSIONS_HOST:
            this.handleSharePermissionsForHost(data);
            break;

          case CONTROL_COMMAND.RECORD_PERMISSIONS_ALL:
            this.handleRecordPermissionForAll(data);
            break;

          case CONTROL_COMMAND.RECORD_PERMISSIONS_HOST:
            this.handleRecordPermissionForHost(data);
            break;

          case CONTROL_COMMAND.SCHEDULE_PERMISSIONS_ALL:
            this.handleSchedulePermissionForALL(data);
            break;

          case CONTROL_COMMAND.SCHEDULE_PERMISSIONS_HOST:
            this.handleSchedulePermissionForHost(data);
            break;

          case CONTROL_COMMAND.MATURITY_NOTICE:
            this.handleMaturityNotice(data);
            break;

          case CONTROL_COMMAND.APPLY_HOST:
            this.handleApplyHost(data);
            break;

          case CONTROL_COMMAND.START_CLOUDRECORD: //开始云录制
            this.handleRecordFn(data,10);
            break;

          case CONTROL_COMMAND.START_BACK_CLOUDRECORD: //开始云录制准备好了
            this.handleRecordFn(data,11);
            break;
      
          case CONTROL_COMMAND.PAUSE_CLOUDRECORD: //暂停云录制
            this.handleRecordFn(data,20);
            break;
          case CONTROL_COMMAND.RESUME_CLOUDRECORD: //恢复云录制
            this.handleRecordFn(data,30);
            break;
          case CONTROL_COMMAND.RESUME_BACK_CLOUDRECORD: //恢复云录制准备好了
            this.handleRecordFn(data,31);
            break;
          case CONTROL_COMMAND.STOP_CLOUDRECORD: //结束云录制
            this.handleRecordFn(data,40);
            break;

          case CONTROL_COMMAND.INITIATE_QUESTIONNAIRE: //收到问卷
            this.handleInitiateQuestionnaireFn(data);
            break;
            
          default:
            console.log("unknow command");
        }
      })
    },

    /**
     * 注册web-sdk改名通知回调
     */
    initChangeNameEvent() {
      let _eventCount = this.$i100MeetSDK.listenerCount('onDisplayNameChanged')
      if(_eventCount>0){ //如果注册过了就返回 避免多次注册   重复的问题以后再查一下
        return
      }
      this.$i100MeetSDK.on("onDisplayNameChanged", (data) => {
        console.log("-----rtc changeName------");
        console.log(data);

        const { peerId, displayName } = data;

        this.$store.commit("member/updateUser", {
          userId: peerId,
          userName: displayName,
        })
      })
    },

    /**
     * 注册web-sdk exitRoom 回调
     */
    initUserExitRoomEvent() {
      let _eventCount = this.$i100MeetSDK.listenerCount('onExitMeeting')
      if(_eventCount>0){ //如果注册过了就返回 避免多次注册   重复的问题以后再查一下
        return
      }
      this.$i100MeetSDK.on("onExitMeeting", (data) => {
        console.log("-----rtc exitRoom------");
        console.log(data);

        // 1：被服务器踢出当前房间
        // 2: 主持人主动结束当前会议EndConference
        // 3: 服务器强制结束当前会议forceEndConference
        const { reason } = data

        const textConfig = {
          1: this.$t('meeting.getOut'),//您已被主持人移出会议
          2: this.$t('meeting.overTimeMeet'),//主持人已结束会议
          3: this.$t('meeting.closeMeet'),//会议已结束
        }


        if (reason === 2 && this.isHost) { // 主持人已结束会议，并且自己是主持人
          // 不会出现弹窗
          this.isNeedDisconnect = false // 不需要触发destory中的断开逻辑
          // 执行sdk退房操作
          this.$meetingControl.logoutRoom(false); // 不需要走leave
           this.$router.push({
            path: "join",
          })
          // window.location.href = getMopanLinks();
        } else {
          this.exitMeetByTimer(textConfig[reason])
        }
      })
    },


    /**
     * 注册web-sdk 连接超时 回调
     */
    initMeetingTimeout() { 
      this.$i100MeetSDK.on('connectionTimeOut', () => {
        console.log('超时了---------------------222')
        this.networkTimeoutDialog = true
      })
    },


    /**
     * 注册web-sdk log 回调
     */
    initSDKLog() {
      this.$i100MeetSDK.on('sdkLogReport', (message) => {
        // loganLog(`sdkLogReport底层抛出日志-----${message}`)
      })
    },

    /**
     * 注册web-sdk 网络检测
     */
    initCheckNetwork() { 
      let _eventCount = this.$i100MeetSDK.listenerCount('networkQuality')
      if(_eventCount>0){ //如果注册过了就返回 避免多次注册   重复的问题以后再查一下
        return
      }
      this.$i100MeetSDK.on("networkQuality", (qualityInfo) => {
        switch (qualityInfo.uplink) {
          case QRTCQuality.QRTCQuality_Excellent:

            // console.error('最好')

            this.networkInfo = {
              ...qualityInfo,
              value: QRTCQuality.QRTCQuality_Excellent,
              text: this.$t('meeting.best')
            }

            break;
          case QRTCQuality.QRTCQuality_Good:

            // console.error('好')

            this.networkInfo = {
              ...qualityInfo,
              value: QRTCQuality.QRTCQuality_Good,
              text: this.$t('meeting.good')
            }

            break;
          case QRTCQuality.QRTCQuality_Poor:

            console.error('一般')

            this.networkInfo = {
              ...qualityInfo,
              value: QRTCQuality.QRTCQuality_Poor,
              text: this.$t('meeting.commonly')
            }

            break;
          case QRTCQuality.QRTCQuality_Bad:

            console.error('差')

            this.networkInfo = {
              ...qualityInfo,
              value: QRTCQuality.QRTCQuality_Bad,
              text: this.$t('meeting.noGood')
            }

            break;
          case QRTCQuality.QRTCQuality_Vbad:

            console.error('很差')

            this.networkInfo = {
              ...qualityInfo,
              value: QRTCQuality.QRTCQuality_Vbad,
              text: this.$t('meeting.noBest')
            }

            break;
          case QRTCQuality.QRTCQuality_Down:

            console.error('不可用')

            this.networkInfo = {
              ...qualityInfo,
              value: QRTCQuality.QRTCQuality_Down,
              text: this.$t('meeting.notAvailable')
            }

            break;
        }
      })
    },

    /**
     * 收到改名
     */
    async handleChangeName(data) {
      const selfId = this.$configs.peerId;
      const { userName } = data.metadata;

      if (selfId === data.to.peerid) {
        // 要更改名字的为当前用户
        // 调用RTC改名
        this.$i100MeetSDK.i100MeetingControl.changeLocalDisplayName(userName);

        // im改名
        this.$i100MeetSDK.i100IM.updateNickAndAvatar({
          nickname: userName,
        });
      }
    },

    /**
     * 在userList中获取主持人信息
     */
    setOldHostToUser() {
      // 将之前的老主持人重置为普通用户
      const oldHost = this.userList.find((i) => i.roleCode === ROLE_CODE.HOST) || {};

      if (oldHost.userId) {
        this.$store.commit("member/updateUser", {
          userId: oldHost.userId,
          roleCode: ROLE_CODE.USER,
        });
      }
    },

    /**
     * 主持人进入房间
     */
    handleHostJoinRoom(data) {
      const { peerid } = data.to;

      // let isRun = true

      // if (peerid === this.$configs.peerId) { // 本人
      //   if (this.selfInfo.userId) { // 存在，那说明时序没有问题
      //     console.error('时序没有问题---------')
      //   } else {
      //     window._localRoleCode = ROLE_CODE.HOST
      //     isRun = false
      //     console.error('时序错误---------')
      //   }
      // }

      // if (isRun) {
      //   this.$store.commit("member/updateUser", {
      //     userId: peerid,
      //     roleCode: ROLE_CODE.HOST,
      //   })
      // }

      console.error('---------更新成主持人---------');

      // this._handleAddUser(peerid, () => {
        this.$store.commit("member/updateUser", {
          userId: peerid,
          roleCode: ROLE_CODE.HOST,
        });
      // })
    },

    /**
     * 自动选取主持人
     */
    async handleAutomaticGrant(data) {
      console.log("------automaticGrant-------");
      console.log(data);

      const { peerid } = data.to;

      // this._handleAddUser(peerid, () => {
        this.$store.commit("member/updateUser", {
          userId: peerid,
          roleCode: ROLE_CODE.HOST,
        })
      // })
    },

    /**
     * 授权角色变更
     */
    handleGrant(data) {
      console.log("--handleGrant---");
      console.log(data);

      const selfId = this.$configs.peerId;
      const { peerid } = data.to;
      const { roleCode } = data.metadata;

      if (peerid === selfId) { // 角色变更的是自己
        if (Number(roleCode) === ROLE_CODE.CO_HOST) {
          this.showToast(this.$t('meeting.becomeGoHost'))
        } else if (Number(roleCode) === ROLE_CODE.USER) {
          if (this.isCoHost) { // 当前为联席主持人，角色变更为普通用户
            this.showToast(this.$t('meeting.backModerator'))
          }
        }
      }

      // this._handleAddUser(peerid, () => {
        this.$store.commit("member/updateUser", {
          userId: peerid,
          roleCode: Number(roleCode)
        })
      // })
    },

    /**
     * 转移主持人
     */
    handleTransferHost(data) {
      console.log("--handleTransferHost---");
      console.log(data);

      // 将之前的老主持人重置为普通用户
      this.setOldHostToUser();

      const selfId = this.$configs.peerId;
      if (selfId === data.to.peerid) {
        const fromUserInfo = this.userList.find((i) => i.userId === data.from.peerid)

        this.commonTipText = `${strMiddleEllipsis(fromUserInfo.userName, 10, 3, 2)}` + this.$t('meeting.giveYou');
        this.commonTipDialog = true;
      }

      // this._handleAddUser(data.to.peerid, () => {
        this.$store.commit("member/updateUser", {
          userId: data.to.peerid,
          roleCode: ROLE_CODE.HOST,
        });
      // })
    },

    /**
     * 申请成为主持人
     */
    handleApplyHost(data) {
      console.log("--handleApplyHost---");
      console.log(data);

      // this._handleAddUser(data.from.peerid, () => {
        this.$store.commit("member/updateUser", {
          userId: data.from.peerid,
          roleCode: ROLE_CODE.HOST
        })
      // })

      if (data.from.peerid === this.$configs.peerId) {
        this.showToast(this.$t('meeting.becomeMain'))
      }
    }, 
    
    /**
     * 云录制
     */
    handleRecordFn(data,type) {
      loganLog(`--收到云录制信令---type:${type}`);
      if(type === 10){//开始录制
        if (this.isHost || this.isCoHost) { 
          // if(this.$store.state.meet.ownerId === this.$configs.peerId){
          //   this.commonTipText = '录制结束后，可在相关业务详情页查看云端录制视频文件'
          // } else {
          //   this.commonTipText = '会议结束后，从会议负责人和协办人获取录制文件链接'
          // }
          this.intervalTip = '云录制中'
          this.intervalText = '录制结束后，可在相关业务详情页查看云端录制视频文件'
          this.intervalDialog = true;
          this.removeTime = 5
          clearInterval(this.intervalTimer)
          // 开启定时器
          this.intervalTimer = setInterval(() => {
            if (this.removeTime === 0) {
              this.intervalDialog = false;
              clearInterval(this.intervalTimer)
              console.error('倒计时结束----')
            } else {
              this.removeTime = this.removeTime - 1;
            }
          }, 1000);
        }
      } else if(type === 40){ //结束录制
        if (this.isHost || this.isCoHost) { 
          this.intervalTip = '云录制已结束'
          this.intervalText = '录制结束后，可在相关业务详情页查看云端录制视频文件'
          this.intervalDialog = true;
          this.removeTime = 5
          clearInterval(this.intervalTimer)
          // 开启定时器
          this.intervalTimer = setInterval(() => {
            if (this.removeTime === 0) {
              this.intervalDialog = false;
              clearInterval(this.intervalTimer)
              console.error('倒计时结束----')
            } else {
              this.removeTime = this.removeTime - 1;
            }
          }, 1000);
        }
      }
      
    this.$store.commit("meet/updateGlobalMeetState", {
      cloudRecorState: type
    })
  },



    /**
     * 问卷
     */
    handleInitiateQuestionnaireFn(data){
      const selfId = this.$configs.peerId;
      let targets = data.targets
      console.log("--handleInitiateQuestionnaireFn---",selfId,data.from.peerid,data);
      targets && targets.forEach(item=>{
        if(item == selfId){
          // console.error(1111,this.isExitUserShare, this.isOpenWenjuan, data.from.peerid == selfId)
          if(this.isExitUserShare && this.isExitUserShare.userId == selfId || this.isOpenWenjuan || data.from.peerid == selfId){//在共享中、问卷打开中、发送人是自己
            this.isShowWenjuanTips = true
          } else {
            this.isOpenWenjuanAsk = true
          }
        }
      })
    },
    /**
     * 收回主持人权限
     */
    handleRecoverHostPermission(data) {
      console.log("--handleRecoverHostPermission---");
      console.log(data);

      // 将之前的老主持人重置为普通用户
      this.setOldHostToUser();

      const { peerid } = data.from; // 发起收回主持人的peerId

      console.log(peerid);

      // this._handleAddUser(peerid, () => {
        this.$store.commit("member/updateUser", {
          userId: peerid,
          roleCode: ROLE_CODE.HOST
        })
      // })
    },

    /**
     * 收到静音操作
     */
    async handleSingleMute(data) {
      console.log("--handleSingleMute---");
      console.log(data);

      const selfId = this.$configs.peerId;

      if (selfId === data.to.peerid) {

        try {
          // 自己收到主持人的静音操作
          await this.$meetingControl.muteLocalAudio(true); // 主动调用信令，执行静音操作

          this.$store.commit("member/updateUser", {
            userId: selfId,
            isUseHuaTong: false,
          })

          this.showToast(this.$t('meeting.mainNoVoice'))
        } catch (error) {
          this.$sentry.captureException({
            msg: '收到会控的静音操作',
            userId: selfId,
            userName: this.selfInfo.userName,
            error
          }) 
        }
      }
    },

    /**
     * 取消静音操作，需要弹出对话框让用户去选择
     */
    handleSingleUnMute(data) {
      console.log("--handleSingleUnMute---");
      console.log(data);

      const selfId = this.$configs.peerId;

      if (selfId === data.to.peerid) {
        // 自己收到主持人的取消静音操作
        this.unMuteAudioDialog = true;
      }
    },

    /**
     * 用户通过弹窗主动解除静音
     */
    async resumeAudioDialogHandle() {
      const selfId = this.$configs.peerId;

      try {
        await this.$meetingControl.muteLocalAudio(false);

        this.$store.commit("member/updateUser", {
          userId: selfId,
          isUseHuaTong: true,
        });

        this.unMuteAudioDialog = false;
      } catch (error) {
        this.$sentry.captureException({
          msg: '用户通过弹窗主动解除静音',
          userId: selfId,
          userName: this.selfInfo.userName,
          error
        })
      }
    },

    /**
     * 停止共享
     */
    handleStopShare(data) {
      console.log("--handleStopShare---");
      console.log(data);

      const selfId = this.$configs.peerId;

      if (selfId === data.to.peerid) { // 本人收到主持人的关闭分享操作
        // 执行后会触发分享结束的回调
        this.$meetingControl.stopShare()

        if (selfId === data.from.peerid) { // 自己操作自己
          console.error('自己操作自己')
          return
        } else {
          // const fromUser = this.userList.find(i => i.userId === data.from.peerid)

          // let targetName = '主持人'
          // if (fromUser && (fromUser.roleCode === ROLE_CODE.CO_HOST)) {
          //   targetName = '联席主持人'
          // }

          this.commonTipText = this.$t('meeting.mainStopShare')
          this.commonTipDialog = true
        }
      }
    },

    /**
     * 收到关闭视频的会控指令
     */
    handleShieldVideos(data) {
      console.log("--handleShieldVideos---");
      console.log(data);

      const selfId = this.$configs.peerId;

      if (selfId === data.to.peerid) {
        // 自己收到主持人的关闭视频操作

        this.$meetingControl.stopLocalPreview(); // 主动调用信令，执行关闭视频操作

        this.$store.commit("member/updateUser", {
          userId: selfId,
          isUseShiPin: false,
        });

        this.commonTipText = this.$t('meeting.closeVideo');
        this.commonTipDialog = true;
      }
    },


    /**
     * 录制相关
     */
    async handleRecord(data, key, value) {
      console.log("--record---");
      console.log(data);

      const { peerid } = data.from

      // this._handleAddUser(peerid, () => {
        this.$store.commit("member/updateUser", {
          userId: peerid,
          [key]: value
        })
      // })
    },


    /**
     * 收到踢出自己命令
     */
    async handleRemoveSelf(data) {
      console.log("--handleRemoveSelf---");
      console.log(data);

      const selfId = this.$configs.peerId;

      if (selfId === data.to.peerid) {
        // 已经在其他设备上登录
        this.exitMeetByTimer(this.$t('meeting.noMembership'));
      }
    },


    /**
     * 举手
     */
    async handleRaiseHand(data) {
      console.log("--handleRaiseHand---");
      console.log(data)

      const { peerid } = data.from;

      // this._handleAddUser(peerid, () => {
        this.$store.commit("member/updateUser", {
          userId: peerid,
          isRaiseHand: true,
        })
      // })
    },

    /**
     * 手放下
     */
    async handleDownHand(data) {
      console.log("--handleDownHand---");
      console.log(data);

      const { peerid } = data.to;
      
      this.$store.commit("member/updateUser", {
        userId: peerid,
        isRaiseHand: false,
      });
    },

    /**
     * 所有手放下
     */
    async handleAllHandDown(data) {
      console.log("--handleAllHandDown---");
      console.log(data);
      this.userList.forEach((item) => {
        const { userId } = item;

        this.$store.commit("member/updateUser", {
          userId,
          isRaiseHand: false,
        });
      });
    },

    /* -------------全局会议状态变更------------------ */

    /**
     * 免费时长倒计时提示
     */
    async handleMaturityNotice(data) {
      // console.log("--handleMaturityNotice---");
      // console.log(data)

      const { remainingTime } = data.metadata

      if (!this.maturityTimeStr) { // 是主持人，并且maturityTimeStr不存在，代表还没有提示过，此时发起提示

        if (this.isHost) { // 如果是主持人，才发起弹窗提示
          this.commonTipText = this.$t('meeting.lijiEnd') + `${remainingTime}` + this.$t('meeting.inMinutes');
          this.commonTipDialog = true
        }

        // 开启定时器
        this.maturityTimer = countDownTimer(
          remainingTime,
          (min, sec) => {
            this.maturityTimeStr = `${min}:${sec}`
          },
          () => {
            this.maturityTimeStr = ''
          }
        )
      }
    },


    /**
     * 锁定会议
     */
    handleLockConference(data) {
      console.error("锁定--")
      console.log(data)

      this.$store.commit("meet/updateGlobalMeetState", {
        lockedState: 1
      })

      const { peerid } = data.from
      if (peerid === this.$configs.peerId) {
        this.showToast(this.$t('meeting.lockMeeting'))
      }
    },

    /**
     * 解锁会议
     */
    handleUnLockConference(data) {
      console.log("解锁--");
      this.$store.commit("meet/updateGlobalMeetState", {
        lockedState: 0,
      });

      const { peerid } = data.from
      if (peerid === this.$configs.peerId) {
        this.showToast(this.$t('meeting.okMeet'))
      }
    },

    /**
     * 允许成员自我解除静音
     */
    handleAllowSelfUnmute(data) {
      console.log("---handleAllowSelfUnmute--");
      console.log(data);

      this.$store.commit("meet/updateGlobalMeetState", {
        allowSelfUnmute: 1,
      });
    },

    /**
     * 禁止成员自我解除静音
     */
    handleForbidSelfUnmute(data) {
      console.log("--handleForbidSelfUnmute---");
      console.log(data);

      this.$store.commit("meet/updateGlobalMeetState", {
        allowSelfUnmute: 0,
      });
    },

    /**
     * 成员加入会议时开启播放提示音
     */
    handleOpenPlayTipse(data) {
      console.log("--handleOpenPlayTipse---");
      console.log(data);

      this.$store.commit("meet/updateGlobalMeetState", {
        playTips: 1,
      });
    },

    /**
     * 成员加入会议时关闭播放提示音
     */
    handleClosePlayTipse(data) {
      console.log("---handleClosePlayTipse--");
      console.log(data);

      this.$store.commit("meet/updateGlobalMeetState", {
        playTips: 0,
      });
    },

    /**
     * 成员加入会议开启静音
     */
    handleOpenMuteJoinMeeting(data) {
      console.log("--handleOpenMuteJoinMeeting---");
      console.log(data);

      this.$store.commit("meet/updateGlobalMeetState", {
        muteJoinMeeting: 1,
      });
    },

    /**
     * 成员加入会议关闭静音
     */
    handleCloseMuteJoinMeeting(data) {
      console.log("--handleCloseMuteJoinMeeting---");
      console.log(data);

      this.$store.commit("meet/updateGlobalMeetState", {
        muteJoinMeeting: 0,
      });
    },

    /**
     * 全体静音操作
     */
    async _allMuteHandle(data, allowSelfUnmute) {
      // 全体静音：
      // 1、主持人操作，生效范围：在会场/新入会的——所有联席主持人（包括owner）+所有普通成员（包括owner）
      // 2、联席主持人操作，生效范围：在会场/新入会的——其他联席主持人（包括owner）+所有普通成员（包括owner）

      this.$store.commit("meet/updateGlobalMeetState", {
        allMuteState: 1,
        allowSelfUnmute
      });

      const selfId = this.$configs.peerId;
      let isRun = false

      if (this.isHost) { // 自己是主持人
        console.log("主持人不会被静音");
      } else if (this.isCoHost) { // 自己是联席
        if (selfId !== data.from.peerid) {
          // 操作人不是自己
          console.log("是联席，并且操作人不是是自己");
          isRun = true
        } else {
          console.log("是联席，并且操作人是自己");
        }
      } else { // 普通用户
        console.log("普通用户");
        isRun = true
      }


      if (isRun && this.selfInfo.isUseHuaTong) { // isRun为true并且非静音状态
        try {
          await this.$meetingControl.muteLocalAudio(true); // 主动调用信令，执行静音操作

          this.$store.commit("member/updateUser", {
            userId: selfId,
            isUseHuaTong: false,
          });

          this.showToast(this.$t('meeting.allNoVoice'))
        } catch (error) {
          this.$sentry.captureException({
            msg: '全体静音操作',
            userId: selfId,
            userName: this.selfInfo.userName,
            error
          })
        }
      }
    },

    /**
     * 全体强制静音
     *
     */
    handleAllForceMute(data) {
      console.log("--handleAllForceMute---");
      console.log(data);

      this._allMuteHandle(data, 0);
    },

    /**
     * 全体非强制静音
     */
    handleAllUnForceMute(data) {
      console.log("--handleAllUnForceMute---");
      console.log(data);

      this._allMuteHandle(data, 1);
    },

    /**
     * 全体解除静音
     * // 全局的静音状态  0：全体解除静音  1：全体静音
     */
    handleAllUnMute(data) {
      console.log("--handleAllUnMute---");
      console.log(data);

      this.$store.commit("meet/updateGlobalMeetState", {
        allMuteState: 0,
      });

      // 主持人操作，对所有的联席和普通成员生效
      // 联席主持人操作，对其他联席和普通成员生效
      if (data.from.peerid === this.selfInfo.userId) { // 操作人为自己，此时不做任何操作
        return
      } else { // 操作其他人
        if (this.isHost) { // 如果自己是主持人，则不做任何操作
          return
        } else {
          if (!this.selfInfo.isUseHuaTong) {
            this.unMuteAudioDialog = true;
          }
        }
      }

      // if (!this.isHost) {
      //   // 如果自己为主持人，则不处理
      //   if (!this.selfInfo.isUseHuaTong) {
      //     this.unMuteAudioDialog = true;
      //   }
      // }
    },

    /**
     *
     */
    handleSharePermissionsForAll(data) {
      console.log("--handleSharePermissionsForAll---");
      console.log(data);

      this.$store.commit("meet/updateGlobalMeetState", {
        sharePermission: 0,
      });
    },

    /**
     *
     */
    handleSharePermissionsForHost(data) {
      console.log("--handleSharePermissionsForHost---");
      console.log(data);

      this.$store.commit("meet/updateGlobalMeetState", {
        sharePermission: 1,
      });
    },

    /**
     *
     */
    handleRecordPermissionForAll(data) {
      console.log("--handleRecordPermissionForAll---");
      console.log(data);

      this.$store.commit("meet/updateGlobalMeetState", {
        recordPermission: 0,
      });
    },

    /**
     *
     */
    handleRecordPermissionForHost(data) {
      console.log("--handleRecordPermissionForHost---");
      console.log(data);

      this.$store.commit("meet/updateGlobalMeetState", {
        recordPermission: 1,
      });
    },

    /**
     *
     */
    handleSchedulePermissionForALL(data) {
      console.log("--handleSchedulePermissionForALL---");
      console.log(data);

      this.$store.commit("meet/updateGlobalMeetState", {
        agendaPermission: 0,
      });
    },

    /**
     *
     */
    handleSchedulePermissionForHost(data) {
      console.log("--handleSchedulePermissionForHost---");
      console.log(data);

      this.$store.commit("meet/updateGlobalMeetState", {
        agendaPermission: 1,
      });
    },

    /**
     * 点击知道了，立刻退出会议
     */
    shortlyExitMeet() {
      clearInterval(this.removeTimer);
      this.isLockExitRoom = false
       // 返回会议面板
      // this.$router.push({
      //   path: "join",
      // });
      window.location.href = getMopanLinks();
    },

    exitMeetByTimer(exitMeetText) {
      if (this.isLockExitRoom) {
        console.error('exitMeetByTimer锁定中, 直接返回')
        return
      }
      console.error('正常执行----')
      this.isLockExitRoom = true

      this.isNeedDisconnect = false // 不需要触发destory中的断开逻辑

      this.exitMeetText = exitMeetText;
      this.exitMeetDialog = true;

      // 执行sdk退房操作
      this.$meetingControl.logoutRoom(false); // 不需要走leave
        this.$router.push({
          path: "join"
        })
      clearInterval(this.removeTimer)

      this.removeTime = 3
      // 开启定时器
      this.removeTimer = setInterval(() => {
        if (this.removeTime === 0) {
          console.error('倒计时结束----')
          this.shortlyExitMeet();
        } else {
          this.removeTime = this.removeTime - 1;
        }
      }, 1000);
    },

    // 主持人结束会议
    async hostEndMeeting() {
      try {
        // 调用会控结束会议
        await fetchMeetControl({
          command: CONTROL_COMMAND.END_MEET,
        }, this.$configs)
        this.$router.push({
          name: 'join'
        });
      } catch (error) {
        this.$refs.meetingLogoutBtn.resetSenEndMeeting();//清除防抖锁
        console.error(error)
      }
    },

    // 所有角色退出会议
    logoutMeeting() {
       this.$router.push({
        name: 'join'
      });
      // window.location.href = getMopanLinks();
    },

    restMediaSrc() {
      console.error('restMediaSrc-111--')

      const resetList = document.querySelectorAll('.media-reset-flag')

      for (const el of resetList) {
        el.srcObject = null
        el.src = ''
      }
    },

    //倒计时基数
    handleCount(minutes, seconds, type) {
      this.countDownTipMinutes = minutes;
      this.countDownTipSeconds = seconds;
      this.countDownTipType = type;
    },
    // 关闭倒计时tip框
    closeTip() {
      let that = this;
      that.$refs.meetingCountdownBtn.close();
    },
    // 重置倒计时框
    resetTip() {
      let that = this;
      that.$refs.meetingCountdownBtn.reset();
    },

    showToast(text) {
      clearTimeout(this.toastTimer)

      this.toastText = text
      this.isShowToast = true

      this.toastTimer = setTimeout(() => {
        this.isShowToast = false
      }, 3000)
    },

    // 显示设置面板
    openSetting(index) {
       this.$store.commit("isShowSettingPanel", true);
       this.$store.commit("settingPanelDefaultIndex", index);
    },

    clearMenuBar() {
      clearTimeout(this.menuBarTimer)
    },

    menuBarMoveHandler: throttle(function() {
      this.isShowMenuBar = true

      // 清理掉之前的定时器
      clearTimeout(this.menuBarTimer)

      this.menuBarTimer = setTimeout(() => {
        this.isShowMenuBar = false

        clearTimeout(this.menuBarTimer)
      }, 3000)
    }, 400, {
      trailing: false
    }),

    overMenuBarHandle: throttle(function() {
      this.isShowMenuBar = true

        // 清理掉之前的定时器
      clearTimeout(this.menuBarTimer)
    }, 50, {
      trailing: false
    }),

    iframeBeforeClosed(e) {
      this.isOK = false
      this.webviewUrl = ""
      this.questionnaireUrl = ""
      
      this.iframeLoading = true

      const el = document.querySelector('.el-dialog-iframe')

      if (el) {
        el.style.top = 'initial'
        el.style.left = 'initial'
      }
    },
    async iframeOpen() {
      if(this.$store.state.meet.isNetworkDisconnect){
        this.showToast(this.$t('meeting.netWorkBad'))
        return
      }
      const routeQuery = this.$route.query
      let meetingId = routeQuery.conferenceId

      let status = this.selfInfo.roleCode
      let userId = this.$configs.userId || ""
      if (this.isOK) {
        this.iframeBeforeClosed()
        return
      }

      try {        
        const data = await fetchConference({
          id: meetingId
        })

        this.agendaId = data.agendaId || "";
        this.agendaPermission = data.agendaPermission;
        let language = getAcceptLanguage() === 'zh-CN' ? 'zh' : 'en'
        if (this.agendaId) {
          this.webviewUrl = meetAgendasUrl + "/h5/agendas?meetingId=" + meetingId + "&agendaId=" + this.agendaId + "&status=" + status + "&userId=" + userId +  "&langue=" + language + "&jurisdiction=" + this.agendaPermission;
          this.isOK = true;
        } else {

          try {
            const agendaId = await fetchConferenceSnowflake()

            this.webviewUrl = meetAgendasUrl + "/h5/agendas?meetingId=" + meetingId + "&agendaId=" + agendaId + "&status=" + status + "&userId=" + userId +  "&langue=" + language +"&jurisdiction=" + this.agendaPermission;
            this.isOK = true;

          } catch (error) {
            console.log(error) // TODO:
          }
        }
      } catch (error) {
        console.log(error) // TODO:
      }
    },
    wenjuanIframeOpen(){
      if(this.questionnaireUrlStr){
        this.isOpenWenjuan = true
        this.questionnaireUrl = `${this.questionnaireUrlStr}&roleCode=${this.selfInfo.roleCode}`
        this.isShowWenjuanTips = false
      }else{
        this.showToast('请稍后再试')
      }
    },
    iframeLoad() {
      this.iframeLoading = false
    }
  },

  mounted(){
    
  },
  beforeRouteLeave(to, from, next) {
    console.error('即将beforeRouteLeave')

    console.log(to)

    this.$store.commit("reset")

    this.restMediaSrc()

    next()
  },

  destroyed() {
    this.$notify.closeAll()
    this.waitLoading && this.waitLoading.close()
    // 清空storage event
    !isSafari() && window.removeEventListener('storage', this.handleStorageEvent)

    // 清空倒计时
    clearInterval(this.removeTimer)
    clearTimeout(this.toastTimer)
    clearInterval(this.maturityTimer)
    clearInterval(this.shenceDataTimer)
    


    // 清理鼠标移动相关
    this.clearMenuBar()

    setTimeout(() => {
      // console.error("55552 isNeedDisconnect",this.isNeedDisconnect)
      if (this.isNeedDisconnect) { // 需要断开
        // 调用rtc退房命令
        this.$meetingControl && this.$meetingControl.logoutRoom(true) // 需要走leave
      }
    }, 100);

    if (this.isFullModel) {
      screenfull.exit()
    }

    // 清空vuex中member, reset状态
    this.$store.commit("member/reset");
    this.$store.commit("meet/reset");
    this.$store.commit('resetMessageList')

    // reset $configs
    this.$configs.userId = ''
    this.$configs.roomId = ''
    this.$configs.userName = ''
    this.$configs.avatarUrl = ''
    this.$configs.peerId = ''
    this.$configs.conferenceNo = ''
    this.$configs.attendMap = {}
  }
}
</script>

<style lang="scss" scoped>
  @mixin pub_style($posi,$left,$right,$index){
    position: $posi;
    left: $left;
    right: $right;
    z-index: $index;
  }
  @mixin base_style($width){
    width: $width;
    height: $width;
  }
  @mixin dis_style($dis,$jus,$align){
    display: $dis;
    justify-content: $jus;
    align-items: $align;
  }
  @mixin ali_style($dis,$ali){
    align-items: $ali;
    display: $dis;
  }
  @mixin top_style($posi,$left,$top,$radius,$padding,$bag){
  position: $posi;
  left: $left;
  top: $top;
  border-radius: $radius;
  padding: $padding;
  background: $bag;
}
.meeting {
  position: relative;

  .main {
    background: #000000;
    overflow: hidden;
    height: 100%;
  }

  .top {
    @include pub_style(absolute,0,0,11);
    top: 0;
    display: block;
    justify-content: space-between;
    padding: 0 16px;
    height: 0;
    .left {
      @include  ali_style(flex,center);
      // pointer-events: all;
      float: left;
      padding-top: 18px;
    }

    .right {
      float: right;
      padding-top: 18px;
      @include  ali_style(flex,center);
      // height: 60px;
      // pointer-events: all;
      // padding-right: 16px;
    }

    .btnItem {
      margin-left: 10px;
      margin-top: 5px;
    }
  }

  .optionWrap {
    @include pub_style(absolute,0,0,999);
    bottom: 0;
    height: 72px;
    background: #f2f2f2;
    border-radius: 4px 4px 0px 0px;
    @include dis_style(flex,space-between,center);
    padding: 0 16px;
    z-index: 999;
    .left-option {
      @include  ali_style(flex,center);
    }

    .middle-section{
      @include ali_style(flex,center);
      // justify-content: center;
      .otherOption {
        display:flex;
        background: #fff;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
        border-radius: 24px;
        margin-left: 14px;

        .agenda-box {
          @include dis_style(flex,space-between,center);
          height: 48px;
          overflow: hidden;
          cursor: pointer;
          padding:0 10px;
          position: relative;
          &:hover {
            background: rgba(0, 0, 0, 0.05);
          }
          .agendaInfo {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            margin-left:6px;
          }
          .circle{
            position: absolute;
            width: 7px;
            height: 7px;
            padding: 0;
            border-radius: 50%;
            background-color: #f56c6c;
            top: 10px;
            left: 28px;
          }
        }
        .wenjuanBtn{
          padding-right: 20px;
          border-radius: 0px 100px 100px 0px;
        }
      }
    }
  }
}
.disconnect-box {
  z-index: 9999999;
  @include top_style(fixed,50%,20px,24px,0 12px,#FFFFFF);
  transform: translateX(-50%);
  height: 48px;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  @include ali_style(flex,center);
  .icon {
    @include base_style(24px);
    background: url(~@/assets/images/agendas_loading_48.gif) no-repeat;
    background-size: 100% 100%;
    margin-right: 10px;
  }

  .text {
    color: #000000;
    font-size: 14px;
  }
}

// 免费倒计时
.freeCoutTime {
  line-height: 24px;
  height: 24px;
  padding: 0 4px;
  box-shadow: 0px 20px 50px 0px rgb(0 0 0 / 30%);
  border-radius: 2px;
  background: rgba(51, 51, 51, 0.4);
  // margin: 16px 0 0 30px;
  margin-right: 10px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.85);
}

.user-totast {
  @include top_style(fixed,50%,50%,10px,20px 40px,#333333);
  z-index: 99999;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: #fff;
}

.watermarkBox{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  pointer-events: none; /* 关键点：水印不响应鼠标事件 */
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none; /* 防止文字被选中 */
  color: rgba(102,102,102,0.6);
  font-size: 20px;
  font-weight: 500;
  transform: rotate(-45deg);
}

.iframe-box {
  @include dis_style(flex,center,center);
  position: relative;
  // height: 530px;
  .iframe-loading-box {
    @include dis_style(flex,center,center);
    @include base_style(100%);
    position: absolute;
    background: #fff;
    .iframe-loading-img {
    @include base_style(48px);
      margin-top: -60px;
    }
  }
}
</style>

<style lang="scss">
.el-dialog-iframe {
  .el-dialog__body {
    padding: 0 !important;
  }
  .el-dialog__headerbtn .el-dialog__close {
    font-size: 16px !important;
  }
}
</style>
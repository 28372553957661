<template>
  <div class="changeLayout-box" v-show="isShowChange()">
    <div class="changeLayout" @click.stop="click" id="meeting_changelayout_btn_headless">
      <!-- <my-icon class="myIcon" :target="'modeIcon'" :iconName="modeIcon"></my-icon> -->
    <img class="myIcon" :src="modeIcon">
      <div class="desc">{{ modeName }}</div>

      <my-icon :class="['arrowIcon', isShowPanel ? 'arrowRotate' : '']" :target="'layoutArrowIcon'" :fontSize="16"
        :iconName="'iconjiantou_xiajiantou_bai'"></my-icon>
    </div>

    <div class="layoutPanel" :class="{
      isFull: isFullModel
    }" v-show="isShowPanel" v-on-clickaway="clickawayHandle">
      <div class="gonggeDesc">{{ $t('meeting.grid') }}</div>
      <div class="yanjiangzheDesc">{{ $t('meeting.speaker') }}</div>

      <div class="layoutWrap">
        <!-- 9宫格 -->
        <div :title="setNotClickText()" @mouseover="hover9 = true" @mouseleave="hover9 = false"
          :class="['gongge9', 'spring-item',layoutType===LAYOUT_CONFIG.GALLERY9 ? 'is-active':'']"
          @click="clickItem(LAYOUT_CONFIG.GALLERY9)" id="meeting_changelayout_nine_headless">
          <my-icon class="gongge9Icon base_style" :fontSize="48"
            :iconName="layoutType===LAYOUT_CONFIG.GALLERY9 || hover9 ? 'icongongge_9gongge_48_lv' : 'icongongge_9gongge_48_qianlv'">
          </my-icon>
          <div class="word">{{ $t('meeting.grid9') }}</div>
        </div>

        <!-- 25宫格 -->
        <div :title="setNotClickText()" @mouseover="hover25 = true" @mouseleave="hover25 = false"
          :class="['gongge25', 'spring-item',layoutType===LAYOUT_CONFIG.GALLERY25?  'is-active':'']"
          @click="clickItem(LAYOUT_CONFIG.GALLERY25)" id="meeting_changelayout_twentyfive_headless">
          <my-icon class="gongge25Icon base_style" :fontSize="48"
            :iconName="layoutType===LAYOUT_CONFIG.GALLERY25 || hover25 ? 'icongongge_25gongge_48_lv' : 'icongongge_25gongge_48_qianlv'">
          </my-icon>
          <div class="word">{{ $t('meeting.grid25') }}</div>
        </div>

        <!-- 演讲者左右 -->
        <div :class="['yanjiangzheZuoYou', 'spring-item',layoutType===LAYOUT_CONFIG.COLUMN ? 'is-active':'']"
          @mouseover="hoverColumn = true" @mouseleave="hoverColumn = false" @click="clickItem(LAYOUT_CONFIG.COLUMN)"
          v-if="!isFullModel" id="meeting_changelayout_transverse_headless">
          <my-icon class="yanjiangzheZuoYouIcon base_style" :fontSize="48"
            :iconName="layoutType===LAYOUT_CONFIG.COLUMN || hoverColumn ? 'iconyanjiang_youbuju_48_lv' : 'iconyanjiang_youbuju_48_qianlv'">
          </my-icon>
          <div class="word">{{ $t('meeting.speakerRight') }}</div>
        </div>

        <!-- 演讲者上下 -->
        <div :class="['yanjiangzheShangXia', 'spring-item',layoutType===LAYOUT_CONFIG.ROW ? 'is-active':'']"
          @mouseover="hoverRow = true" @mouseleave="hoverRow = false" @click="clickItem(LAYOUT_CONFIG.ROW)"
          v-if="!isFullModel" id="meeting_changelayout_vertical_headless">
          <my-icon class="yanjiangzheShangXiaIcon base_style" :fontSize="48"
            :iconName="layoutType===LAYOUT_CONFIG.ROW || hoverRow ? 'iconyanjiang_xiabuju_48_lv' : 'iconyanjiang_xiabuju_48_qianlv'">
          </my-icon>
          <div class="word">{{ $t('meeting.speakerTop') }}</div>
        </div>

        <!-- 演讲者全屏 -->
        <div
          :class="['yanjiangzheQuanPing', 'spring-item',(layoutType===LAYOUT_CONFIG.ROW||layoutType===LAYOUT_CONFIG.COLUMN)? 'is-active':'']"
          @mouseover="hoverCR = true" @mouseleave="hoverCR = false" @click="clickItem(LAYOUT_CONFIG.FULL)"
          v-if="isFullModel" id="meeting_changelayout_full_headless">
          <my-icon class="yanjiangzheQuanPingIcon base_style" :fontSize="48"
            :iconName="(layoutType===LAYOUT_CONFIG.ROW||layoutType===LAYOUT_CONFIG.COLUMN)|| hoverCR ? 'iconyanjiang_quanping_48_lv' : 'iconyanjiang_quanping_48_qianlv'">
          </my-icon>
          <div class="word">{{ $t('meeting.speakerFull') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    LAYOUT_CONFIG
  } from "@/constant/index";

  export default {
    computed: {
      userList() {
        return this.$store.getters["member/getRealUserList"]
      },

      isFullModel() {
        return this.$store.state.meet.isFullModel
      },

      isExitUserShare() { // 存在用户共享
        return this.userList.find(i => i.isShare)
      },

      layoutType() {
        // 布局模式
        return this.$store.state.meet.layoutType
      },

      modeName() {
        return this.layoutType <= 2 ? this.layoutNameConfig.grid : this.layoutNameConfig.speaker
      },

      modeIcon() {
        return this.layoutType <= 2 ? require('@/assets/images/iconbuju_hualangbuju_24_bai.png') : require('@/assets/images/iconbuju_yanjiangmoshi_24_bai.png')
      }
    },

    watch: {
      async isFullModel(newVal) {
        console.log('变化了-----')
        console.log(newVal)

        if (this.userList.length <= 1 && !this.isExitUserShare) { // 会场中小于1人

          try {
            // 暂停订阅所有的远端流
            await this.$meetingControl.muteRemoteVideoAll()

            if (newVal) { // 切换成全屏，自动切换到演讲者模式
              this.$store.commit("meet/updateGlobalMeetState", {
                layoutType: LAYOUT_CONFIG.ROW
              })
            } else { // 取消全屏，自动切换到宫格模式
              this.$store.commit("meet/updateGlobalMeetState", {
                layoutType: LAYOUT_CONFIG.GALLERY9
              })
            }
          } catch (error) {
            console.log(error)
          }
        }
      }
    },

    data() {
      return {
        LAYOUT_CONFIG,
        hover9: false,
        hover25: false,
        hoverColumn: false,
        hoverRow: false,
        hoverCR: false,
        layoutNameConfig: {
          grid: this.$t('meeting.grid'),
          speaker: this.$t('meeting.speaker')
        },

        isShowPanel: false, // 是否显示面板
      };
    },
    methods: {

      setNotClickText() {
        if (this.userList.length <= 1 && this.isFullModel) {
          return this.$t('meeting.littlePeople')
        } else {
          return ''
        }
      },

      isShowChange() {
        if (this.userList.length <= 1) { // 会场中小于1人
          return this.isFullModel
        } else { // 会场中大于1人
          return true
        }
      },

      clickawayHandle() {
        this.isShowPanel = false
      },

      // 点击按钮
      click() {
        this.isShowPanel = !this.isShowPanel;
      },

      // 点击布局项
      async clickItem(type) {
        let targetType = type

        if (this.userList.length <= 1) { // 会场中小于1人
          if (targetType === LAYOUT_CONFIG.GALLERY9 || targetType === LAYOUT_CONFIG.GALLERY25) {
            return
          }
        }
        //观看共享期间不能切换宫格
        if (this.isExitUserShare && (targetType === LAYOUT_CONFIG.GALLERY9 || targetType === LAYOUT_CONFIG
            .GALLERY25)) {
          this.isShowPanel = false
          this.$parent.showToast(this.$t('meeting.noChangeLayout'))
          return
        }

        if (this.layoutType === LAYOUT_CONFIG.COLUMN || this.layoutType === LAYOUT_CONFIG.ROW) {
          window._loadTopShare = true
        }

        if (targetType === LAYOUT_CONFIG.FULL) { // 能点到，说明在全屏模式下
          if (this.layoutType === LAYOUT_CONFIG.COLUMN || this.layoutType === LAYOUT_CONFIG
            .ROW) { // 如果当前的布局模式已经是演讲者了，那直接return
            this.isShowPanel = false
            return
          } else { // 否则的话默认重置到顶部列表的演讲者模式
            targetType = LAYOUT_CONFIG.ROW
          }
        }

        try {
          // 停止订阅远端所有的视频流
          await this.$meetingControl.muteRemoteVideoAll()

          // 存储全局会议状态
          this.$store.commit("meet/updateGlobalMeetState", {
            layoutType: targetType
          })

          this.isShowPanel = false;
        } catch (error) {
          console.log(error)
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  @mixin hover_style($margin) {
    width: 100px;
    height: 88px;
    margin: $margin;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    float: left;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;

    &:hover,
    &:focus {
      background: rgba(26, 179, 112, 0.1);
    }
  }

  @mixin public_style($height, $font, $margin) {
    height: $height;
    font-size: $font;
    line-height: $height;
    margin: $margin;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    float: left;
  }

  .changeLayout-box {
    margin-right: 10px;
  }

  .changeLayout {
    // display: inline-block;
    height: 24px;
    background: rgba(51, 51, 51, 0.4);
    box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    // margin: 16px 0 0 8px;
    cursor: pointer;

    .myIcon {
      margin: 0 0 0 4px;
      float: left;
      width:24px;
      height:24px;
    }

    .desc {
      display: inline-block;
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.85);
      line-height: 17px;
      text-shadow: 0px 20px 50px rgba(0, 0, 0, 0.3);
      float: left;
      margin: 3px 0 4px 4px;
    }

    .arrowIcon {
      margin: 5px 0 4px 8px;
      float: left;
      transition: all ease-out 0.3s;
      transform: rotate(0);
    }

    .arrowRotate {
      transform: rotate(-180deg);
    }
  }

  .layoutPanel {
    width: 456px;
    height: 166px;
    background: #ffffff;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.2);
    border: 1px solid #d7d7d7;
    position: absolute;
    right: 48px;
    top: 56px;
    border-radius: 8px;
    overflow: hidden;

    &.isFull {
      width: 347px;
    }

    .layoutWrap {
      height: 88px;
      float: left;
    }

    .gonggeDesc{
      display: inline-block;
      @include public_style(20px, 14px, 24px 144px 0 16px)
    }
    .yanjiangzheDesc {
      display: inline-block;
      @include public_style(20px, 14px, 24px 0 0 16px)
    }

    .gongge9 {
      clear: both;
      @include hover_style(10px 0 0 16px);
    }

    .gongge25,
    .yanjiangzheZuoYou,
    .yanjiangzheShangXia,
    .yanjiangzheQuanPing {
      @include hover_style(10px 0 0 8px);
    }

    .base_style {
      margin: 16px 26px 0 26px;
      float: left;
    }

    .word {
      width: 78px;
      opacity: 0.7;
      text-align: center;
      @include public_style(16px, 12px, 8px 14px 10px 13px)
    }

    .is-active {
      background: rgba(26, 179, 112, 0.1);
      border-color: #1ab370;

      &::after {
        content: "";
        border: 10px solid transparent;
        border-top-color: #1ab370;
        border-right-color: #1ab370;
        border-radius: 0;
        font-size: 0;
        width: 0;
        height: 0;
        padding: 0;
        position: absolute;
        right: 0;
      }

      .word {
        color: #1ab370;
      }
    }
  }
</style>
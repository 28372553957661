<template>
  <div class="list drag-list" :class="{
    isFullModel: isFullModel,
    col: type === 'col',
    row: type === 'row'
  }">
    <!-- 全屏模式下的控制区域 -->
    <div v-show="isFullModel" class="top-control" :class="this.controlType !== 1 ? 'top-control-hide' : ''">
      <div :title="$t('meeting.shouVideo')" @click="controlClickHandle(1)" class="item item1">
         <my-icon
            :fontSize="16"
            :iconName="'iconbuju_yincangshipin_16_lv'"
            v-if="controlType==1"
          ></my-icon>
          <my-icon
            :fontSize="16"
            :iconName="'iconbuju_yincangshipin_16_hei'"
            v-else
          ></my-icon>
      </div>
      <div :title="getTab2Title()" @click="controlClickHandle(2)" class="item item2">
        <my-icon
            :fontSize="16"
            :iconName="'iconbuju_dangeshipin_16_lv'"
            v-if="controlType==2"
          ></my-icon>
          <my-icon
            :fontSize="16"
            :iconName="'iconbuju_dangeshipin_16_hei'"
            v-else
          ></my-icon>
      </div>
      <div :title="$t('meeting.lookAllVideo')" @click="controlClickHandle(3)" class="item item3">
         <my-icon
            :fontSize="16"
            :iconName="'iconbuju_duogeshipin_16_lv'"
            v-if="controlType==3"
          ></my-icon>
          <my-icon
            :fontSize="16"
            :iconName="'iconbuju_duogeshipin_16_hei'"
            v-else
          ></my-icon>
      </div>
    </div>
    
    <!-- 非全屏模式下的列表，全屏模式下的第三种按钮显示的样式 -->
    <div class="list-box" v-show="isShowListBox()">
      <div class="list-box-center">
        <!-- 上一页 -->
        <div
          v-if="isPrev"
          class="page-icon-box prev-box"
          @click="prevHandle"
        >
          <my-icon class="gongge9PrevIcon" :iconName="getPrevIconName()"></my-icon>
        </div>

        <videoItem
          :key="getItemKey(user)"
          v-for="(user) in pageList"
          :user="user"
          @dobClick="dobuleListItemClick"
        />

        <!-- 下一页 -->
        <div
          v-if="isNext"
          class="page-icon-box next-box"
          @click="nextHandle"
        >
          <my-icon class="gongge9NextIcon" :iconName="getNextIconName()"></my-icon>
        </div>
      </div>
    </div>

    <!-- 中间一路画面 -->
    <tab2 
      v-if="isShowOneView()"
    />
  </div>
</template>

<script>
import { ROLE_CODE } from '@/constant/index'
import layoutMixins from "./layout.mixins"
import dobuleClickMixins from "@/mixins/dobuleClick.mixins"

import VideoItem from './video.item'
import Tab2 from './Meeting.Layout.tab2'

export default {
  mixins: [layoutMixins, dobuleClickMixins],

  components: {
    videoItem: VideoItem,
    tab2: Tab2
  },

  props: {
    isFullModel: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    }
  },

  computed: {
    userList() {
      return this.$store.getters["member/getRealUserList"]
    },
    pageList() {
      return this.$store.getters['member/pageList']
    },
    
    isPrev() {
      return this.$store.getters['member/isPrev']
    },
    isNext() {
      return this.$store.getters['member/isNext']
    },

    isExitUserShare() { // 存在用户共享
      return this.userList.find(i => i.isShare)
    },

    isExitUserLock() { // 存在锁定用户
      return this.userList.find(i => i.isDbClick)
    },
  },

  watch: {
    isFullModel: {
      handler(newVal) {
        if (newVal) {
          console.error('触发---isFullModel---true---')
          this.controlType = 2

          this.$nextTick(() => {
            // 初始化拖动
            this.initDrag()
          }) 
        } else {
          this.$nextTick(() => {
            this.removeDrag()
          })
        }
      },
      immediate: true
    }
  },

  data() {
    return {
      ROLE_CODE,

      controlType: 2, // 1 最小化 2 单个画面 3 list模式
    };
  },

  methods: {
    getItemKey(user) {
      if (user.isTopShare) {
        return `top-share-${user.userId}` 
      }

      return user.userId
    },

    dobuleListItemClick(user) {
      this.dobuleClick('list', user, this.$parent.$parent)
    },

    getTab2Title() {
      if (!this.isExitUserShare) { // 不是共享状态
        return this.$t('meeting.selfVideo')
      } else { // 是共享状态
        if (!this.isExitUserLock) { // 不存在锁定用户
          return this.$t('meeting.lookSpeakVideo')
        } else { // 存在锁定
          return this.$t('meeting.lookShare')
        }
      }
    },

    initDrag() { // 初始化拖动
      const dragDom = document.querySelector('.drag-list')

      if (dragDom) {
        dragDom.onmousedown = e => {
          // 鼠标按下，计算当前元素距离可视区的距离
          const disX = e.clientX - dragDom.offsetLeft;
          const disY = e.clientY - dragDom.offsetTop;

          const screenWidth = document.body.clientWidth; // body当前宽度
          const screenHeight = document.documentElement.clientHeight; // 可见区域高度(应为body高度，可某些环境下无法获取)

          const dragDomWidth = dragDom.offsetWidth; // 对话框宽度
          const dragDomheight = dragDom.offsetHeight; // 对话框高度

          const minDragDomLeft = 0
          const maxDragDomLeft = screenWidth - dragDomWidth
          const minDragDomTop = 45
          const maxDragDomTop = screenHeight - dragDomheight

          document.onmousemove = function(e) {
            // 通过事件委托，计算移动的距离
            let left = e.clientX - disX;
            let top = e.clientY - disY;

            // 边界处理
            if (-left > minDragDomLeft) {
              left = -minDragDomLeft;
            } else if (left > maxDragDomLeft) {
              left = maxDragDomLeft;
            }
            if (top < minDragDomTop) {
              top = minDragDomTop;
            } else if (top > maxDragDomTop) {
              top = maxDragDomTop;
            }

            // 移动当前元素
            dragDom.style.cssText += `;left:${left}px;top:${top}px;`;
          };

          document.onmouseup = function(e) {
            document.onmousemove = null;
            document.onmouseup = null;
          };

          return false
        }
      }
    },

    removeDrag() { // 销毁拖动效果
      console.error('清理坐标和事件----')
      const el = document.querySelector('.drag-list')

      if (el) {
        el.style = ''
        el.onmousedown = null
      }
    },

    getPrevIconName() {
      if (this.isFullModel) { // 全屏模式
        return 'iconjiantou_shangjiantou_24_bai'
      } else {
        return this.type === 'row' ? 'iconjiantou_zuojiantou_24_bai' : 'iconjiantou_shangjiantou_24_bai'
      }
    },
    getNextIconName() {
      if (this.isFullModel) { // 全屏模式
        return 'iconjiantou_xiajiantou_24_bai'
      } else {
        return this.type === 'row' ? 'iconjiantou_youjiantou_24_bai' : 'iconjiantou_xiajiantou_24_bai'
      }
    },

    isShowOneView() { // 是否显示中间一路画面
      if (!this.isFullModel) { // 非全屏模式，一定不显示
        return false
      } else { // 全屏模式下，只有controlType为2
        return this.controlType === 2
      }
    },

    isShowListBox() { // 是否显示listBox

      if (!this.isFullModel) { // 如果不是全屏模式，就一定显示
        return true
      } else { // 全屏模式下，只有controlType为3时显示
        return this.controlType === 3
      }
    },

    controlClickHandle(type) { // 点击三个控制按钮
      this.controlType = type
    }
  },

  destroyed() {
    this.removeDrag()
  }
};
</script>

<style lang="scss" scoped>
  @mixin ali_style(){
    display: flex;
    align-items: center;
  }
  @mixin dis_style(){
    @include ali_style;
    justify-content: center;
  }
  @mixin base_style($width,$height){
    width:$width;
    height:$height;
  }
.list {
  position: relative;
  background: #111111;

  &.col {
    @include base_style(230px,100%);
    flex-shrink: 0;

    .list-box {
      flex-shrink: 0;
      padding-bottom: 10px;
      box-sizing: border-box;
      .list-box-center {
        width: 100%;
      }
    }

    .user-item {
      @include base_style(auto,130px);
      border: 2px solid #000000;
      border-right: none;
      border-bottom: none;

      &:last-child {
        border-bottom: 2px solid #000000;
      }
    }

    .page-icon-box {
      height: 24px;
      line-height: 12px;

      &.prev-box {
        margin-bottom: 2px;
      }

      &.next-box {
        margin-top: 2px;
      }
      &:focus,&:hover{
        background: rgba(51, 51, 51, 0.45);
      }
    }
  }

  &.row {
    @include base_style(100%,118px);
    .list-box {
      .list-box-center {
        height: 100%;
        display: flex;
      }
    }

    .user-item {
    @include base_style(210px,100%);
      flex-shrink: 0;
      border: 2px solid #000000;
      border-top: none;
      border-right: none;

      &:last-child {
        border-right: 2px solid #000000;
      }
    }

    .page-icon-box {
      &.prev-box {
        margin-right: 19px;
      }

      &.next-box {
        margin-left: 19px;
      }
      &:focus,&:hover{
        background: rgba(51, 51, 51, 0.45);
      }
    }
  }

  &.isFullModel { // 全局模式下的布局
    position: absolute;
    top: 50px;
    right: 40px;
    @include base_style(240px,auto);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    overflow: hidden;
    z-index:10;
    background: transparent;
    .top-control-hide{
      opacity: 0;
      -webkit-transition-delay: 1s;
      -moz-transition-delay: 1s;
      transition-delay: 1s;
    }
    &:hover{
      .top-control-hide{
        opacity: 1;
        -webkit-transition-delay: 0s;
        -moz-transition-delay: 0s;
        transition-delay: 0s;
      }
    } 
    .page-icon-box {
      &.prev-box,&.next-box {
        // margin: 0;
        margin-right: 0px;
        // margin-bottom: 19px;
      }
      &:focus,&:hover{
        background: rgba(51, 51, 51, 0.45);
      }
    }

    &.col, &.row {
      .list-box-center {
        width: 100%; 
      }

      .user-item {
       @include base_style(100%,137px);
        // height: 130px;
        border: none;
        border-bottom: 2px solid #000000;

        &:last-child {
          border-bottom: none;
        }
      }
    }
    &.row {
      .list-box-center {
        flex-wrap: wrap;
        width: 100%; 
      }
    }
  }

  .list-box {
    @include dis_style;
    flex-wrap: wrap;
    height: 100%;
  }

  .page-icon-box {
    cursor: pointer;
    text-align: center;
    width: 100%;
    @include dis_style;
  }

  .top-control {
    height: 24px;
    padding: 0 8px;
    @include ali_style;
    background-color: #fff;
    .item {
      @include base_style(16px,16px);
      // background-color: #1ab370;
      cursor: pointer;
      margin-right: 10px;
      img{
        display: block;
        width: 100%;
      }
    }
  }
}
</style>
<!--
* 医百会议-主界面-顶部左边-安全图标功能
* version: 1.0
* author:  SunAo
* time: 2021-01-26
-->
<template>
  <div>
    <!-- <my-icon class="iconanquan" :iconName="'iconanquan_24'" @click.stop="togglePanel" ></my-icon> -->
    <img class="iconanquan" src="@/assets/images/iconanquan_24.png" @click.stop="togglePanel" alt="">
    <div class="iconanquanPanel" v-show="panel" @click.stop="">{{ $t('meeting.encrypted') }}</div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        timer: null,
        panel: false,
      };
    },
    mounted() {
      this.$parent.$el.addEventListener("click", () => {
        this.panel = false
      })
    },
    methods: {
      togglePanel() {
        // 防止弹窗堆叠
        !this.panel && this.$parent.$el.click()
        this.panel = !this.panel
      }
    },
  };
</script>

<style lang="scss" scoped>
  .iconanquan {
    // margin-left: 8px;
    // margin-top: 18px;
    // margin-bottom: 18px;
    // float: left;
    color: #ff8200;
    cursor: pointer;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    margin-top: 4px;


    &:hover {
      background: rgba(102, 102, 102, 0.3);
    }

    &:focus {
      background: rgba(153, 153, 153, 0.3);
    }
  }

  .iconanquanPanel {
    height: 40px;
    background: #ffffff;
    box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.3),
      0px 10px 20px 0px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    position: absolute;
    left: 176px;
    top: 58px;
    font-size: 14px;
    color: #333333;
    line-height: 40px;
    text-align: center;
    padding-left: 24px;
    padding-right: 24px;
  }
</style>
import eventBus from '@/utils/bus'

export default {
  data() {
    return {
      changePageLock: false,

      oldPageList: [] // 备份数据
    }
  },
  computed: {
    userList() {
      // 成员列表
      return this.$store.getters["member/getRealUserList"]
    },

    pageIndex() {
      return this.$store.state.member.pageIndex
    },
    pageList() {
      return this.$store.getters['member/pageList']
    },

    speakUser() { // 发言的用户

      console.error('computed speaker---')
      console.log(this.$store.getters["member/getSpeakUser"])

      return this.$store.getters["member/getSpeakUser"]
    }
  },
  methods: {
    stopUserListHandle(cb) {
      console.log(this.pageList)

      const whiteUserIdList = [this.$configs.peerId]

      const coreOneRef = this.$parent.$refs.layoutCoreOne
      if (coreOneRef) {
        console.log(coreOneRef)
        whiteUserIdList.push(coreOneRef.userMain.userId)
      }

      /**
       * 1. 排除自己
       * 2. 大画面
       */
      const stopUserList = this.pageList.filter(item => {
        return !item.isTopShare && whiteUserIdList.indexOf(item.userId) === -1
      })

      console.error('-----要关闭视频的名单------')
      console.log(stopUserList)

      stopUserList.forEach(async item => {
        try {
          await this.$meetingControl.stopRemoteVideo(item.userId)
        } catch (error) {
          console.log(error)
        }
      })

      cb && cb()
    },

    async nextHandle() { // 下一页
      console.error('下一页------')
      this.isRefresh = true
      if (this.changePageLock) {
        console.error('锁定期间，不生效')
        return
      }

      console.error('page锁定-----')
      this.changePageLock = true
      
      this.stopUserListHandle(() => {
        // 切换到下一页
        window._loadTopShare = true
        this.$store.commit('member/updatePageIndex', this.pageIndex + 1)
      })
    },

    async prevHandle() { // 上一页
      console.error('上一页------')
      this.isRefresh = true
      if (this.changePageLock) {
        console.error('锁定期间，不生效')
        return
      }

      console.error('page锁定-----')
      this.changePageLock = true

      this.stopUserListHandle(() => {
        // 切换到上一页
        window._loadTopShare = true
        this.$store.commit('member/updatePageIndex', this.pageIndex - 1)
      })
    }
  },
  watch: {
    pageList: {
      handler(newPageList) {
        // console.log('watch-----pageList---------')
        // console.error('新值---')
        // console.log(newPageList)
        // console.error('老值----')
        // console.log(this.oldPageList)

        if (newPageList.length === 0 && this.pageIndex > 1) { // 当前页已经没有用户了，这时候跳转到上一页
          console.error('准备回调上一页---------')
          window._loadTopShare = true
          this.$store.commit('member/updatePageIndex', this.pageIndex - 1)
        }

        // console.error('即将执行oldlist的清理工作-------')
        // 视频源清理操作
        this.oldPageList.forEach((oldUser, oldIndex) => {
          if (oldUser.isTopShare) { // 当前oldUser为顶部分享画面，特殊处理
            console.error('命中清理topshare-----')
            const newTopShare = newPageList.find(i => i.isTopShare && (i.userId === oldUser.userId))

            if (!newTopShare) {
              const videoEl = document.getElementById(`top-share-${oldUser.userId}`)

              console.error('执行单个top-share的清理工作---')
              console.error(videoEl)

              videoEl && (videoEl.srcObject = null)
            } else {
              console.error('不执行topShare的清理工作---')
            }
          } else { // 其他情况，正常处理
            // console.error('不是topshare，正常处理-----')
            const newIndex = newPageList.findIndex(i => !i.isTopShare && (i.userId === oldUser.userId))
            const newUser = newPageList[newIndex]

            let isOldRun = false

            if (oldUser.isUseShiPin) { // 老的开始视频
              if (!newUser) { // 在新列表中，已经找不到原来的老用户了
                isOldRun = true
                console.error('在新列表中，已经找不到原来的老用户了');
              } else { // 新老都存在，这时候判断索引
                if (oldIndex !== newIndex) { // 新老交换了位置
                  isOldRun = true
                  console.error('新老交换了位置');
                } else if (oldUser.isUseShiPin && !newUser.isUseShiPin) { // 新老位置相同
                  console.error('新老位置相同，并且新的已经关闭了视频');
                  isOldRun = true
                }
              }
            }

            if (isOldRun) {
              const videoEl = document.getElementById(`video-${oldUser.userId}`)

              console.error('执行单个video清理工作---')
              console.error(videoEl)

              videoEl && (videoEl.srcObject = null)

              console.error('单个video清理完毕----');
            } else {
              // console.error('oldlist没有需要清理的内容------')
            }
          }
        })
        // console.error('oldlist清理工作执行完成-------')

        this.$nextTick(() => {
          newPageList.forEach(async (newUser, newIndex) => {
            const oldIndex = this.oldPageList.findIndex(o => !o.isTopShare && (o.userId === newUser.userId))
            const oldUser = this.oldPageList[oldIndex]
        
            let isRun = false
            if (newUser.isTopShare) { // 当前newUser为顶部分享画面，特殊处理
              console.error('命中isTopShare------------')
              if (window._loadTopShare) {
                isRun = true
                window._loadTopShare = false
              }
            } else if (!oldUser) { // 再老的副本中找不到，说明当前用户为新增的。
              isRun = true
            } else { // 新老都存在，正常的逻辑判断
              if (oldIndex !== newIndex) { // 新老交换了位置
                isRun = true
              } else if (newUser.isUseShiPin && !oldUser.isUseShiPin) { // 新的打开了视频，旧的没有打开
                isRun = true
              }
            }


            // isRun逻辑
            if (isRun) { // 执行相关代码
              // console.log('执行list isRun -----------' + newUser.userId);

              const { isTopShare, userId, isUseShiPin } = newUser

              if (isTopShare) { // 顶部的分享画面

                console.error('要开启顶部的分享画面-----');
                const shareVideEl = document.getElementById(`top-share-${userId}`)

                // console.log(shareVideEl)

                if (userId !== this.$configs.peerId) { // 开启其他用户的共享画面
                  this.$meetingControl.startRemoteShare(userId, shareVideEl)
                }

              } else { // 正常处理
                if (isUseShiPin) { // 要开启视频
                  const videoEl = document.getElementById(`video-${userId}`)

                  if (userId === this.$configs.peerId) {
                    this.$meetingControl.startLocalPreview(videoEl)
                  } else {
                    // 这里变更成同步调用
                    console.error('list将要开启远程视频-------')
                    console.log(userId)
                    console.log(videoEl)

                    this.$meetingControl.startRemoteVideo(userId, videoEl)
                  }
                }
              }
            } else {
              // console.log('不执行list isRun ----------' + newUser.userId)
            }
          })
        
          // console.log('使用完了，将当前newPageList的副本存储一份')

          this.oldPageList = JSON.parse(JSON.stringify(newPageList))
          
          // 解锁
          // console.error('page解锁----')
          this.changePageLock = false
        })
      },
      deep: true,
      immediate: true
    },

    speakUser: {
      handler(newVal) {
        console.error('watch speakUser------------')
        console.log(newVal)

        this.userList.forEach(item => {
          this.$store.commit('member/updateUser', {
            userId: item.userId,
            isSpeaking: false
          })
        })

        if (newVal) {
          this.$store.commit('member/updateUser', {
            userId: newVal.userId,
            isSpeaking: true
          })
        }
      },
      immediate: true
    }
  },

  created() {
    eventBus.$on('resetOldPageList', () => {
      console.error('收到resetOld通知---------');
      this.oldPageList = []
    })
  }
}
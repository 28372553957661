<template>
  <div class="gallery">
    <!-- 上一页 -->
    <div
      class='prev'
      v-if="isPrev"
      @click="prevHandle"
    >
      <my-icon class="gongge9PrevIcon" :iconName="'iconjiantou_zuojiantou_24_bai'"></my-icon>
    </div>

    <!-- 视频 -->
    <div 
      class="video-list" 
      id="girdVideoList"
      :style="{
        width: warpElWidth,
        height: warpElHeight,
      }"
      :class="[pageIndex === 1 ? 'onePage' : 'outOnePage',gridType === 9 ? 'minsize-9' : (gridType === 25 ? 'minsize-25':'')]"
    >
      <div
        class="video-item"
        :key="user.userId" 
        v-for="(user) in pageList"
        :style="{
          width: videoElWidth,
          height: videoElHeight
        }"
      >
        <div 
          class="videoWrap"
          :class="{speaking: user.isSpeaking}"
        >
          <video
            autoplay
            playsinline
            :data-video="`video-${user.isUseShiPin}`"
            :id="`video-${user.userId}`"
            class="media-reset-flag"
            v-if="user.isUseShiPin"
          ></video>
          <span v-if="user.isUseShiPin" class="loading-box" data-isCurrentShare="false"></span>
          <!-- 底部条 -->
          <div class="bottomBar">
            <!-- 主持人图标 -->
         <img class="compere" src="@/assets/images/iconzhuchiren.png" v-show="user.roleCode === ROLE_CODE.HOST" alt="">

            <!-- <my-icon title="主持人" class="compere" :fontSize="20" :iconName="'iconzhuchiren'" v-show="user.roleCode === ROLE_CODE.HOST"></my-icon> -->
            <!-- 联席主持人图标 -->
          <img class="compere2" src="@/assets/images/iconlianxizhuchiren.png" v-show="user.roleCode === ROLE_CODE.CO_HOST" alt="">

            <!-- <my-icon title="联席主持人" class="compere2" :fontSize="20" :iconName="'iconlianxizhuchiren'" v-show="user.roleCode === ROLE_CODE.CO_HOST"></my-icon> -->
            <!-- 话筒音波状态 -->
            <!-- <my-icon
                class="userAudioIcon"
                :fontSize="16"
                :iconName="user.isUseHuaTongError ? 'iconshipinlan_shengyin_yichang_16_bai' : user.isUseHuaTong ? 'iconshipinlan_shengyin_16_bai' : 'iconshipinlan_shengyin_guanbi_16_bai'"
            ></my-icon> -->
      <img class="userAudioIcon" :src="user.isUseHuaTongError ? require('@/assets/images/iconshipinlan_shengyin_yichang_16_bai.png') : user.isUseHuaTong ? require('@/assets/images/iconshipinlan_shengyin_16_bai.png') : require('@/assets/images/iconshipinlan_shengyin_guanbi_16_bai.png')">


            <!-- 用户名 -->
            <div :title="user.userName" class="userName">{{ strMiddleEllipsis(user.userName, 10, 3, 2) }}</div>
          </div>

          <!-- 关闭视频遮挡面板 -->
          <div
            class="parseVideoPanel"
            v-show="!user.isUseShiPin"
          >
            <div :title="user.userName" class="parseName">{{ strMiddleEllipsis(user.userName, 10, 3, 2) }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 下一页 -->
    <div
      class="next"
      v-if="isNext"
      @click="nextHandle"
    >
      <my-icon class="gongge9NextIcon" :iconName="'iconjiantou_youjiantou_24_bai'"></my-icon>
    </div>
  </div>
</template>

<script>
import throttle from 'lodash.throttle'
import { ROLE_CODE } from "@/constant";
import layoutMixins from "./layout.mixins"
import { strMiddleEllipsis } from "@/utils/str"
import { galleryConfig, galleryMCUConfig } from "@/config/grid";

export default {
  mixins: [layoutMixins],
  created() {
    this.$store.commit("member/updatePageIndex", 1);
    this.$store.commit("member/updatePageSize", this.gridType);
  },
  data() {
    return {
      ROLE_CODE,
      videoElWidth: '',
      videoElHeight: '',
      warpElWidth: '',
      warpElHeight:''
    }
  },
  props: {
    gridType: {
      type: Number,
      default: 9
    }
  },

  watch: {
    pageList: {
      handler() {
        this.setVideoHandle()
      },
      immediate: true
    }
  },

  computed: {
    userList() {
      return this.$store.getters["member/getRealUserList"]
    },
    pageIndex() {
      return this.$store.state.member.pageIndex
    },
    selfInfo() {
      return this.$store.getters["member/getUser"](this.$configs.peerId);
    },
    pageList() {
      return this.$store.getters['member/pageList']
    },

    isPrev() {
      return this.$store.getters['member/isPrev']
    },
    isNext() {
      return this.$store.getters['member/isNext']
    },

  },

  methods: {
    strMiddleEllipsis,
    setVideoHandle() {
      const userLength = this.userList.length

      if (userLength > 0) {
        const isOutOnePage = (userLength / this.gridType) > 1

        let xLen = 1
        let yLen = 1

        if (!isOutOnePage) { // 未超过1页
          let targetConfig = galleryConfig[userLength]
          if (this.gridType === 25) {
            targetConfig = galleryMCUConfig[userLength]
          }

          xLen = targetConfig.xLen
          yLen = targetConfig.yLen
        } else { // 超过1页
          if (this.gridType === 9) {
            xLen = yLen = 3
          } else if (this.gridType === 25) {
            xLen = yLen = 5
          }
        }

        const { width, height, warpWidth, warpHeight } = this.resolveVideoWidhtAndHeight(document.body, xLen, yLen)
        this.videoElWidth = width + 'px'
        this.videoElHeight = height + 'px'
        this.warpElWidth = warpWidth + 'px'
        this.warpElHeight = warpHeight + 'px'
        
      }
    },

    resolveVideoWidhtAndHeight(el, xlen, yLen) {
      let _offsetWidth = el.offsetWidth < 1240 ? 1240 : el.offsetWidth
      let _offsetHeight = el.offsetHeight < 700 ? 700 : el.offsetHeight
      
      const baseWidth = parseInt((_offsetWidth - 1) / xlen)
      const baseHeight = parseInt((_offsetHeight - 1) / yLen)

      // 以宽度为基准，计算高度
      const resolveHeight = 9 * baseWidth / 16
      if (resolveHeight <= baseHeight) {
        return {
          width: baseWidth,
          height: resolveHeight,
          warpWidth: baseWidth * xlen,
          warpHeight: resolveHeight * yLen,
        }
      } else { // 以高度为基准，计算宽度
        const resolveWidth = 16 * baseHeight / 9
        return {
          width: resolveWidth,
          height: baseHeight,
          warpWidth: resolveWidth * xlen,
          warpHeight: baseHeight * yLen,
        }
      }
    }
  },

  mounted() {
    window.onresize = throttle(() => {
      this.setVideoHandle()
    }, 15, {
      trailing: false
    })
  }
};
</script>

<style lang="scss" scoped>
@mixin base_style($width){
  width: $width;
  height: $width;
}
@mixin dis_style($dis,$jus,$align){
  display: $dis;
  justify-content: $jus;
  align-items: $align;
}
.gallery{
  height: 100%;
  .prev {
    position: absolute;
    left: 10px;
    top: 50%;
    z-index: 1;
    cursor: pointer;
    width: 36px;
    height: 60px;
    background: rgba(51, 51, 51, 0.3);
    border-radius: 0px 100px 100px 0px;
    .gongge9PrevIcon {
      margin: 18px 11px 18px 1px;
    }
  }
  .next {
    position: absolute;
    right: 10px;
    top: 50%;
    cursor: pointer;
    width: 36px;
    height: 60px;
    background: rgba(51, 51, 51, 0.3);
    border-radius: 100px 0px 0px 100px;
    .gongge9NextIcon {
      margin: 18px 1px 18px 11px;
    }
  }

  .over {
    background: rgba(102, 102, 102, 0.6);
  }

  .down {
    background: rgba(102, 102, 102, 0.8);
  }
}

.video-list {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &.onePage{
    align-content: center;
    align-items: center;
    justify-content: center;
  }

  &.outOnePage {
    align-content: flex-start;
  }

  &.minsize-9 {
    .video-item {
      min-width: 362px;
      min-height: 204px;
    }
  }

  &.minsize-25 {
    .video-item {
      min-width: 210px;
      min-height: 118px;
    }
  }
  .video-item {
    box-sizing: border-box;
    border-right: 2px solid #000;
    border-bottom: 2px solid #000;
    .videoWrap {
      position: relative;
      text-align: center;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      // border: 2px solid #000000;
      overflow: hidden;
      &.speaking {
        border: 2px solid #1AB370;
      }
      video{
        width: 100%;
        height: 100%;
        // object-fit: cover;
      }
      .loading-box {
        position: absolute;
        display: none;
        top: 50%;
        left: 50%;
        margin-top: -30px;
        margin-left: -30px;
        z-index: 10;
        width: 60px;
        height: 60px;
        background: url(~@/assets/images/loading.png) no-repeat;
        background-size: 100% 100%;
        animation: rotate 1s linear infinite;
      }
      .loading-box-show{
        display: block;
      }
    }
    
    .loadWrap {
      position: absolute;
      width: 40px;
      height: 25px;
      left: calc(50% - 20px);
      top: calc(50% - 12.5px);
    }

    .bottomBar {
      display: flex;
      padding-right: 4px;
      align-items: center;
      height: 20px;
      background: rgba(0, 0, 0, 0.5);
      box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.3);
      border-radius: 0px 4px 0px 0px;
      position: absolute;
      // margin-left:2px;
      bottom: 0;
      left: 0;
      z-index: 1;
      .compere,.compere2 {
        // float: left;
        margin-right: 4px;
        width:20px;
        height: 20px;
      }
      .userAudioIcon {
        // margin: 2px 0 2px 4px;
        // float: left;
        margin-right: 2px;
        width:16px;
        height: 16px;
      }
      .userName {
        // height: 12px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 16px;
        text-shadow: 0px 20px 50px rgba(0, 0, 0, 0.3);
        // float: left;
        // margin: 2px 6px 2px 2px;
      }
    }

    .parseVideoPanel {
      position: absolute;
      top: 0;
      //left: 0;
      color: #ffffff;
      background: #222222;
      width: 100%;
      height: 100%;
    }

    .speakingPanel {
      position: absolute;
      top: 0;
      left: 0;
      color: #ffffff;
      width: 100%;
      height: 64%;
      border: 3px solid #1ab370;
      box-sizing: border-box;
    }

    .parseName {
      width: 100%;
      height: 60px;
      line-height: 60px;
      font-size: 30px;
      text-align: center;
      position: absolute;
      top: calc(50% - 30px);
    }
  }
}
</style>

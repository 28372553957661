<template>
  <div class="top-share-box" v-if="isExitUserShare">
    <div class="text">
      <div>{{$t('meeting.lookWhoShare')}}</div>
      <!-- <div :title="shareUserName">{{ strMiddleEllipsis(shareUserName, 10, 3, 2) }}</div> -->
      <div :title="shareUserName">{{ shareUserName }}</div>

      <div>{{$t('meeting.shares')}}</div>
    </div>

    <el-dropdown v-if="!isTopShare" trigger="click" @command="changeShareScaleHandle">
      <span class="el-dropdown-link">
        <div class="icon el-icon-arrow-down"></div>
      </span>

      <el-dropdown-menu class="userDropDown" slot="dropdown">
        <div class="title">{{$t('meeting.lookBIg')}}</div>
        <el-dropdown-item v-for="item in list" :key="item.value" :class="{
          active: shareScale === item.value
        }" :command="item.value">
          <div class="selected"></div>
          {{ item.label }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { strMiddleEllipsis } from "@/utils/str"

export default {
  data() {
    return {
      list: [{
        label: this.$t('meeting.windowOk'),
        value: 1
      }, {
        label: '50%',
        value: 2
      }, {
        label: this.$t('meeting.bfb'),
        value: 3
      }, {
        label: '150%',
        value: 4
      }, {
        label: '200%',
        value: 5
      }, {
        label: '300%',
        value: 6
      }],

      shareUserName: ''
    }
  },

  computed: {
    shareScale() {
      return this.$store.state.meet.shareScale
    },

    userList() {
      return this.$store.getters["member/getRealUserList"]
    },

    isTopShare() {
      return this.$store.state.member.userList.find(i => i.isTopShare)
    },

    isExitUserShare() { // 存在用户共享，并且共享的用户不是本人。控制显示隐藏
      const targetShareUser = this.userList.find(i => {
        return i.isShare && (i.userId !== this.$configs.peerId)
      })

      this.shareUserName = targetShareUser ? targetShareUser.userName : ''

      return !!targetShareUser
    }
  },

  watch: {
    isExitUserShare: {
      handler(newVal) {
        if (newVal) { // 存在
          const tempShareScale = this.shareScale

          // 先重置为1
          this.$store.commit("meet/updateGlobalMeetState", {
            shareScale: 1
          })

          // 再设置为记忆值
          this.$nextTick(() => {
            this.$store.commit("meet/updateGlobalMeetState", {
              shareScale: tempShareScale
            })
          })
        } else { // 不存在
          this.$store.commit("meet/updateGlobalMeetState", {
            shareScale: ''
          })
        }
      },
      immediate: true
    }
  },

  methods: {
    strMiddleEllipsis,

    changeShareScaleHandle(e) {
      this.$store.commit("meet/updateGlobalMeetState", {
        shareScale: e
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @mixin font_style($s,$w,$c,$p){
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    padding: $p;
  }

.userDropDown {
  .title {
    @include font_style(12px,400,#999999,0 40px);
    margin-top: 17px;
    line-height: 17px;
  }

  li {
    @include font_style(13px,400,#333333,0 40px);
    position: relative;
    &.active {
      .selected {
        display: block;
      }
    }

    .selected {
      display: none;
      width: 16px;
      height: 16px;
      background: url(~@/assets/images/primary-select.png) no-repeat;
      background-size: 100% 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 16px;
    }
  }
}

.top-share-box {
  @include font_style(14px,500,#fff,9px 16px);
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  background-color: #1AB370;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  align-items: center;

  .text {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #FFFFFF;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
  }

  .icon {
    margin-left: 47px;
    cursor: pointer;
    color: #fff;
  }
}
</style>
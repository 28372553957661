<!--
* 医百会议-主界面-顶部左边-信息图标功能
* version: 1.0
* author:  SunAo
* time: 2021-01-26
-->
<template>
  <div>
    <img class="iconhuiyixinxi" src="@/assets/images/meetInfo.png" @click.stop="togglePanel" alt="">
    <div class="iconhuiyixinxiPanel" v-show="panel">
      <div class="xinxiTitle">{{meetName}}</div>
      <table>
        <!-- 会议密码 -->
        <tr v-if='$store.state.meet.passwordEnable && $store.state.meet.password'>
          <td>
            <div class="xinxiZhuChiRenName">{{ $t('login.meetPsd') }}</div>
          </td>
          <td>
            <div :title="$store.state.meet.ownerName" class="xinxiZhuChiRenValue">{{ $store.state.meet.password }}</div>
          </td>
        </tr>

        <!-- 链接 -->
        <tr>
          <td>
            <div class="xinxiLinkName">{{ $t('meeting.invite') }}:</div>
          </td>
          <td>
            <div class="xinxiZhuChiRenValue" v-extend-max-length="[24, '...', mopanLinks]"></div>
            <div class="xinxiLinkCopy" v-clipboard:copy="mopanLinkMsg" v-clipboard:success="onCopy"
              v-clipboard:error="onError">{{ $t('meeting.copy') }}</div>
          </td>
        </tr>
        <!-- 参会者ID -->
        <tr>
          <td>
            <div class="xinxiUserName">{{ $t('meeting.attendee') }}:</div>
          </td>
          <td>
            <div class="xinxiZhuChiRenValue id_style">{{userID}}</div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
  import {
    getMopanLinks
  } from "@/utils/auth"

  import {
    getDate
  } from "@/utils/tool"
  export default {
    data() {
      const userID = this.$route.params.userID
      const conferenceNo = this.$route.query.conferenceNo
      const mopanLinks = getMopanLinks()
      return {
        userID,
        conferenceNo,
        timer: null,
        panel: false,
        mopanLinks,
        mopanLinkMsg:mopanLinks
      };
    },
    computed: {
      meetName() {
        return this.$store.state.meet.meetName
      }
    },
    watch: {
      mopanInfo(newVal) {
        if(newVal){
          let startTime = new Date(newVal.planStartTime)
          let endTime = new Date(newVal.planEndTime)
          let ymd = getDate(startTime)
          let _endYmd = getDate(endTime)
          let timeinfo = `${ymd} ${startTime.toTimeString().substr(0, 5)}~${getDate(endTime)!=getDate(startTime) ? _endYmd + ' ' : ''}${ endTime.toTimeString().substr(0,5)}`
          this.mopanLinkMsg = `${newVal.username}邀请您参加会议\n会议主题：${newVal.topic}\n会议时间：${timeinfo}(GMT+08：00)中国标准时间-北京\n会议链接：建议使用Chrome浏览器打开链接\n${this.mopanLinks}`
        }
        // console.error(2222223551,newVal)
      },
    },
    mounted() {
      this.$parent.$el.addEventListener("click", () => {
        this.panel = false
      })
    },
    props: {
      mopanInfo: {
        default: {}
      }
    },
    methods: {
      onCopy(e) {
        const {
          text
        } = e

        let copyText = ''
        if (text.indexOf('https') > -1) { // 邀请信息
          copyText = this.$t('seeting.inviteInfo')
        } else {
          copyText = this.$t('meeting.meetID')
        }

        this.$parent.showToast(`${copyText}` + this.$t('seeting.succCopy'))
      },
      // 复制失败时的回调函数
      onError(e) {},
      togglePanel() {
        // 防止弹窗堆叠
        !this.panel && this.$parent.$el.click()
        this.panel = !this.panel
      }
    },
  };
</script>

<style lang="scss" scoped>
  @mixin public_font {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 20px;
  }

  .iconhuiyixinxi {
    cursor: pointer;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    margin-top: 4px;

    &:hover {
      background: rgba(153, 153, 153, 0.3);
    }
  }

  .iconhuiyixinxiPanel {
    //width: 360px;
    min-height: 130px;
    background: #ffffff;
    border-radius: 8px;
    position: absolute;
    left: 144px;
    top: 58px;
    padding: 24px;
    box-sizing: border-box;

    table {
      width: 100%;

      td:first-child {
        padding-right: 12px;
      }
    }

    .xinxiTitle {
      max-width: 310px;
      min-height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
    }
    .xinxiZhuChiRenName {
      @include public_font;
      color: #999999;
      margin-top: 8px;
    }

    .xinxiZhuChiRenValue {
      @include public_font;
      max-width: 210px;
      color: #333333;
      float: left;
      margin: 8px 0 0;
    }

    .id_style {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap
    }

    .xinxiLinkName {
      @include public_font;
      color: #999999;
      margin: 8px 0 0;
    }

    .xinxiLinkCopy {
      @include public_font;
      width: 28px;
      color: #1AB370;
      float: left;
      margin: 8px 0 0 17px;
      cursor: pointer;
    }

    .xinxiUserName {
      @include public_font;
      color: #999999;
      margin: 6px 0 0;
      float: left;
    }
  }
</style>
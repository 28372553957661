<template>
  <div class="member-container">
    <div class="member-body">
      <div class="member-search">
        <el-input
          class="search-input"
          :placeholder="searchChoice? $t('meeting.searchMicrophonePerson') : $t('meeting.searchPerson')"
          clearable
          v-model="keywords">
        </el-input>
        <div class="search-choice" :class="{
          'search-choice-off':searchChoice
        }">
            <div class="member-search-btn">
              <el-dropdown trigger="click" >
                <span class="el-dropdown-link">
                  <img src="../../assets/images/menu/choice.svg" alt class="button-choice button-choice-off" />
                  <img src="../../assets/images/menu/choice_checked.svg" alt class="button-choice  button-choice-on" />
                  <el-button class="more-btn" :class="searchChoice?'btn-color-green':''" size="small">{{$t('meeting.filter')}}</el-button>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item class="more-item">
                    <div @click="changeSearchChoice(false)">
                      {{$t('meeting.acquiescence')}} 
                      <img src="../../assets/images/menu/yes.svg" alt v-if="!searchChoice" class="button-choice button-yes" />
                    </div>
                  </el-dropdown-item>

                  <el-dropdown-item class="more-item">
                    <div @click="changeSearchChoice(true)">
                       {{$t('meeting.microphoneOn')}}
                      <img src="../../assets/images/menu/yes.svg" alt v-if="searchChoice"  class="button-choice button-yes" />
                    </div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
        </div>
      </div>

      <ul v-if="filterList.length > 0" class="member-list" @scroll="hiddenCurrentMenu">
        <li
          class="member-item"
          :class="{
            'orgUserHover': orgUserHover
          }"
          v-for="(item) in this.filterList"
          :key="item.userId"
          @mouseenter="mouseenter(item)"
          @mouseleave="mouseleave"
        >
          <!-- 左侧 -->
          <div class="member-name" v-if="!searchChoice?true:item.isUseHuaTong?true:false">
            <!-- 头像 -->
            <div class="member-avatar">
              <img class="host_img" src="@/assets/images/iconzhuchiren.png" v-show="item.roleCode == 20" alt="">
              <img class="host_img" src="@/assets/images/iconlianxizhuchiren.png" v-show="item.roleCode == 10" alt="">
              <img class="img-common" v-if="item.avatarUrl" :src="item.avatarUrl" />
              <div
                v-else
                class="member-avatar-text"
              >
                {{ getUserNameFirst(item.userName) }}
              </div>
            </div>
            <div class="member-title">
              <!-- 名字 -->
              <div :title="item.userName" class="member-title-name" v-html='hightLight(strMiddleEllipsis(item.userName, 20, 6, 4) )'></div>
              <!-- 职位 -->
              <div class="sub-title">{{ getRoleText(item) }}</div>
            </div>
          </div>

          <!-- 右侧 -->
          <div class="member-button" v-if="!searchChoice?true:item.isUseHuaTong?true:false">
            <!-- 图标 -->
            <div class="member-status-icon">

              <my-icon
                class="icon-svg"
                v-if="isCloudRecor(item.roleCode)"
                :iconName="isCloudRecor(item.roleCode)"
              ></my-icon>

              <div v-if="item.isRaiseHand" class="icon hand"></div>
              <div v-if="item.isShare" class="icon share"></div>
              <div v-if="item.isRecord && !item.recordPaused" class="icon record"></div>

              <div v-if="item.isUseHuaTongError" class="icon audio-error"></div>
              <div v-if="!item.isUseHuaTongError && item.isUseHuaTong" class="icon audio-open"></div>
              <div v-if="!item.isUseHuaTongError && !item.isUseHuaTong" class="icon audio-close"></div>

              <div v-if="item.isUseShiPin" class="icon video-open"></div>
              <div v-if="!item.isUseShiPin" class="icon video-close"></div>
            
            </div>

            <div class="member-status-btn">
              <el-button @click="setUnMuteHandle(item.userId)" size="small" v-if="isShowUnMute(item)" type="primary">{{$t('meeting.audioUnMute')}}</el-button>
              <el-button @click="setMuteHandle(item.userId)" size="small" v-if="isShowMute(item)" type="primary">{{$t('meeting.audioMute')}}</el-button>
              <el-button @click="changeNameClickHandle(item)" size="small" v-if="isShowChangeName(item)" type="primary" >{{$t('meeting.changeName')}}</el-button>

              <el-dropdown trigger="click" v-if="isShowMore(item)">
                <span class="el-dropdown-link">
                  <el-button class="more-btn" size="small">{{$t('login.more')}}</el-button>
                </span>

                <el-dropdown-menu slot="dropdown" class="member-more-menu">
                  <el-dropdown-item class="more-item" v-if="isShowMoreChangeName(item)">
                    <div @click="changeNameClickHandle(item)">{{$t('meeting.changeName')}}</div>
                  </el-dropdown-item>

                  <el-dropdown-item class="more-item" v-if="isShowSetHost(item)">
                    <div @click="setHostClickHandle(item)">{{$t('meeting.setMain')}}</div>
                  </el-dropdown-item>


                  <el-dropdown-item class="more-item" v-if="isShowSetCoHost(item)">
                    <div @click="debounceHandle(setCoHostHandle.bind(this, item))">{{$t('meeting.goHost')}}</div>
                  </el-dropdown-item>

                  <el-dropdown-item class="more-item" v-if="isShowNotSetCoHost(item)">
                    <div @click="debounceHandle(setUserHandle.bind(this, item))">{{$t('meeting.removeHost')}}</div>
                  </el-dropdown-item>

                  <el-dropdown-item class="more-item" v-if="isShowStopVideo(item)">
                    <div @click="debounceHandle(stopVideHandle.bind(this, item))">{{$t('meeting.stopVideo')}}</div>
                  </el-dropdown-item>

                  <el-dropdown-item class="more-item" v-if="isShowStopShare(item)">
                    <div @click="stopShareClickHandle(item)">{{$t('meeting.stopShare')}}</div>
                  </el-dropdown-item>

                  <el-dropdown-item class="more-item" v-if="isShowStopHand(item)">
                    <div @click="debounceHandle(singDownHandHandle.bind(this, item))">{{$t('meeting.putHands')}}</div>
                  </el-dropdown-item>

                  <el-dropdown-item class="more-item" v-if="isShowStopMeet(item)">
                    <div style="color: #FF4D4F" @click="removeMemberClickHandle(item)">{{$t('meeting.removeMeet')}}</div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </li>
      </ul>

      <div v-else class="empty-box">{{$t('meeting.noPerson')}}</div>
    </div>
·
    <div class="member-footer">
      <div class="member-silence">
        <el-button :type="isSetHighLight()" @click="setAllMuteClickHandle" v-if="isShowAllToggleMute()" size="small">{{$t('meeting.allMute')}}</el-button>
        <el-button @click="debounceHandle(setAllUnMuteHandle)" v-if="isShowAllToggleMute()" size="small">{{$t('meeting.silenceall')}}</el-button>
        <el-button @click="debounceHandle(downAllHandHandle)" v-if="isShowStopAllHand()" size="small">{{$t('meeting.allStopHands')}}</el-button>
        <el-button @click="debounceHandle(revokeHostHandle)" v-if="isShowRevokeHost()" size="small">{{$t('meeting.getMain')}}</el-button>
        <el-button @click="raiseHandHandle" v-if="isShowBottomHand()" size="small">{{$t('meeting.handsUp')}}</el-button>
        <el-button @click="downHandHandle" v-if="isShowBottomDownHand()" size="small">{{$t('meeting.putHands')}}</el-button>
        <el-button @click="setMuteHandle($configs.peerId)" v-if="isShowBottomSelfMute()" size="small">{{$t('meeting.audioMute')}}</el-button>
        <el-button @click="setUnMuteHandle($configs.peerId)" v-if="isShowBottomSelfUnMute()" size="small">{{$t('meeting.audioUnMute')}}</el-button>
        <el-button @click="setBecomeHostHandle" v-if="isShowBecomeHost()" size="small">{{$t('meeting.becomeHost')}}</el-button>
      </div>

      <!-- 更多按钮 -->
      <div
        class="member-more"
        v-if="!isOrdinaryUser"
      >
        <el-button
          size="small"
          @click="handleMoreControl"
        >{{$t('login.more')}}</el-button>

        <ul
          class="more-control"
          :class="$i18n.locale === 'en-US' ? 'enWidth' : ''"
          v-show="moreControl"
          v-extend-panel-manager="closeMoreControl"
        >
          <el-checkbox
            @change="lockMeetChange"
            :value="!!lockedState"
            class="chooseCheckBox"
          >{{$t('meeting.lockMeet')}}</el-checkbox>
          <el-checkbox
            @change="muteJoinMeetingChange"
            :value="!!muteJoinMeeting"
            class="chooseCheckBox"
          >{{$t('meeting.noVoiceMeet')}}</el-checkbox>
          <el-checkbox
            @change="allowSelfUnmuteChange"
            :value="!!allowSelfUnmute"
            class="chooseCheckBox"
          >{{$t('login.relieve')}}</el-checkbox>
          <el-checkbox
            @change="playTipsChange"
            :value="!!playTips"
            class="chooseCheckBox"
          >{{$t('meeting.tipsVoice')}}</el-checkbox>
        </ul>
      </div>
    </div>

    <!-- 修改名字弹窗 -->
    <el-dialog
      :title="$t('meeting.changeName')"
      class="commonElDialog"
      :modal="false"
      width="380px"
      :top="logTop"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="changeNameDialog"
    >
      <el-input
        v-model="currentChangedName"
        maxlength="25"
        clearable>
      </el-input>

      <span slot="footer" class="dialog-footer">
        <el-button @click="changeNameDialog = false">{{$t('login.cancel')}}</el-button>
        <el-button type="primary" @click="debounceHandle(changeNameHandle)">{{$t('login.sure')}}</el-button>
      </span>
    </el-dialog>

    <!-- 是否将其设置为主持人 -->
    <el-dialog
      :title="$t('meeting.setMain')"
      class="commonElDialog"
      :modal="false"
      :show-close='false'
      :top="logTop"
      :visible.sync="setHostDialog"
      width="380px"
    >
      <span>{{$t('meeting.confirmWill')}}{{ strMiddleEllipsis(currentSelectItem.userName, 10, 3, 2) }}{{$t('meeting.setMain')}}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setHostDialog = false">{{$t('login.cancel')}}</el-button>
        <el-button type="primary" @click="debounceHandle(setHostHandle)">{{$t('login.sure')}}</el-button>
      </span>
    </el-dialog>

    <!-- 是否将其移除会议 -->
    <el-dialog
      :title="$t('meeting.outMeet')"
      class="commonElDialog"
      :modal="false"
      :show-close='false'
      :top="logTop"
      :visible.sync="removeUserDialog"
      width="380px"
    >
      <span>{{$t('meeting.confirmWill')}}{{ strMiddleEllipsis(currentSelectItem.userName, 10, 3, 2) }}{{$t('meeting.moveMeet')}}？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="removeUserDialog = false">{{$t('login.cancel')}}</el-button>
        <el-button type="primary" @click="debounceHandle(removeMemberHandle)">{{$t('login.sure')}}</el-button>
      </span>
    </el-dialog>

    <!-- 全体静音 -->
    <el-dialog
      :title="$t('login.tips')"
      class="commonElDialog"
      :modal="false"
      :visible.sync="allMuteDialog"
      width="380px"
      :show-close='false'
      :top="logTop"
    >
      <div style="font-size: 16px; marginBottom: 15px;">{{$t('meeting.newVoice')}}</div>

      <el-checkbox
        @change="allowSelfUnmuteChange"
        :value="!!allowSelfUnmute"
        class="chooseCheckBox"
      >
        {{$t('login.relieve')}}
      </el-checkbox>

      <span slot="footer" class="dialog-footer">
        <el-button @click="allMuteDialog = false">{{$t('login.cancel')}}</el-button>
        <el-button type="primary" @click="debounceHandle(setAllMuteHandle)">{{$t('login.sure')}}</el-button>
      </span>
    </el-dialog>
    
    <!-- 成为主持人 -->
    <el-dialog
      :title="$t('meeting.becomeHost')"
      class="commonElDialog"
      :modal="false"
      :visible.sync="becomeHostDialog"
      width="380px"
      :show-close='false'
      :top="logTop"
    >
      <el-input v-model="hostPassword" clearable :placeholder="$t('login.onlyNo')"></el-input>

      <span slot="footer" class="dialog-footer">
        <el-button @click="becomeHostDialog = false">{{$t('login.cancel')}}</el-button>
        <el-button type="primary" @click="debounceHandle(becomeHostSubmitHandle)">{{$t('login.sure')}}</el-button>
      </span>
    </el-dialog>

    <!-- 停止该成员共享 -->
    <el-dialog
      :title="$t('meeting.endShare')"
      class="commonElDialog"
      :modal="false"
      :visible.sync="stopShareDialog"
      width="380px"
      :show-close='false'
      :top="logTop"
    >
      <div>{{$t('meeting.surShare')}}</div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="stopShareDialog = false">{{$t('login.cancel')}}</el-button>
        <el-button type="primary" @click="debounceHandle(stopShareHandle)">{{$t('login.sure')}}</el-button>
      </span>
    </el-dialog>

    <!-- 通用提示弹窗 -->
    <el-dialog
      :title="$t('login.tips')"
      class="commonElDialog"
      :modal="false"
      :visible.sync="commonTipDialog"
      width="380px"
      :show-close='false'
      :top="logTop"
    >
      <span>{{ commonTipText }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="commonTipDialog = false">{{$t('login.know')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { WEIGHT_CONFIG, MAX_CONF_TIME, ROLE_CODE, CONTROL_COMMAND, THROTLE_TIME } from "@/constant/index";
import { strMiddleEllipsis } from "@/utils/str"
import { fetchMeetControl,wordsFilter } from '@/api/meet'
import debounce from 'lodash.debounce'
import { loganLog } from "@/utils/log"

export default {
  data() {
    return {
      logTop:'200px',

      currentSelectItem: {},

      // 停止共享
      stopShareDialog: false,

      // 设置主持人
      setHostDialog: false,

      // 成为主持人
      becomeHostDialog: false,
      hostPassword: '', // 成为主持人密码

      // 改名
      changeNameDialog: false,
      currentChangedName: '',

      // 移除会议
      removeUserDialog: false,

      // 全体静音
      allMuteDialog: false,

      // 通用弹窗
      commonTipDialog: false,
      commonTipText: '',

      // 筛选按钮
      searchChoice:false,

      keywords: '', // 关键字
      orgUserHover: false,

      modeIcon: "",
      myText: { value: "" },
      moreIndex: "",
      moreControl: false,

      roleEnum: [
        { roleCode: 0, name: this.$t('meeting.normalUser')  },
        { roleCode: 10, name: this.$t('meeting.nohost') },
        { roleCode: 20, name: this.$t('meeting.host') },
      ],
    };
  }, 

  computed: {
    userList() {
      // 成员列表
      return this.$store.getters["member/getRealUserList"]
    },

    filterList() {
      const filterUserList = this.userList.filter(i => {
        //筛选开启了话筒
        if(this.searchChoice && !i.isUseHuaTong){
          return 
        }else{
          return i.userName.indexOf(this.keywords) > -1
        }
      }).map(item => {
        let weight = 0
        const nowTime = new Date().getTime()
        let duration = nowTime - item.timeMs

        duration = duration > 0 ? duration : 0

        if (item.userId === this.selfInfo.userId) { // 是自己
          weight += WEIGHT_CONFIG.SELF
        } else if (item.roleCode === ROLE_CODE.HOST) { // 是主持人
          weight += WEIGHT_CONFIG.HOST
        } else if (item.roleCode === ROLE_CODE.CO_HOST) { // 是联席主持人
          weight += WEIGHT_CONFIG.CO_HOST
        } else {
          weight += WEIGHT_CONFIG.USER
        }

        weight = (weight * MAX_CONF_TIME) + duration

        return {
          ...item,
          userWeight: weight
        }
      }).sort((a, b) => {
        const v1 = a.userWeight
        const v2 = b.userWeight

        return v2 - v1
      })
      return filterUserList
    },

    selfInfo() {
      return this.$store.getters["member/getUser"](this.$configs.peerId);
    },

    ownerId() { // 会议所有者id
      return this.$store.state.meet.ownerId
    },

    isHost() {
      return this.selfInfo.roleCode === ROLE_CODE.HOST;
    },

    isCoHost() {
      return this.selfInfo.roleCode === ROLE_CODE.CO_HOST;
    },

    isOrdinaryUser() {
      return this.selfInfo.roleCode === ROLE_CODE.USER;
    },

    lockedState() {
      // 会议锁定
      return this.$store.state.meet.lockedState;
    },
    muteJoinMeeting() {
      return this.$store.state.meet.muteJoinMeeting;
    },
    allowSelfUnmute() {
      return this.$store.state.meet.allowSelfUnmute;
    },
    allMuteState() {
      return this.$store.state.meet.allMuteState;
    },
    playTips() {
      return this.$store.state.meet.playTips;
    },

    ownerPasswordEnable() { // 是否有主持人密码，0否，1是
      return this.$store.state.meet.ownerPasswordEnable
    }
    
  },

  mounted() {
    const self = this
    window.addEventListener('resize', function () {
      self.hiddenCurrentMenu()
    })
  },

  destroyed() {
    window.removeEventListener('resize', this.hiddenCurrentMenu)
  },

  methods: {
    hightLight(item){
      let reg = new RegExp(this.keywords,'g');
      let resDtring = `<span style='color:#1AB370;'>${this.keywords}</span>`
      return item.replace(reg,resDtring)
    },
   
    strMiddleEllipsis,

    getEllipsisStr(str) {
      return this.strMiddleEllipsis(str, 10, 3, 2)
    },

    //限制按钮点击频率
    debounceHandle: debounce(fn => fn && fn(), 500, { leading: true, trailing: false }),

    mouseenter(item) {
      if (this.isOrdinaryUser && !this.isCurrentUser(item)) { // 普通用户，并且不是我自己
        this.orgUserHover = true
      }
    },
    mouseleave() {
      this.orgUserHover = false
    },


    async lockMeetChange(e) {
      // 锁定会议室
      // let lockedState;
      let command;

      if (e) {
        // 锁定
        // lockedState = 1;
        command = CONTROL_COMMAND.LOCK_CONFERENCE;
      } else {
        // 解锁
        // lockedState = 0;
        command = CONTROL_COMMAND.UNLOCK_CONFERENCE;
      }

      await fetchMeetControl({
        command
      }, this.$configs)
    },
    async muteJoinMeetingChange(e) {
      // let muteJoinMeeting;
      let command;

      if (e) {
        // muteJoinMeeting = 1;
        command = CONTROL_COMMAND.OPEN_MUTE_JOIN_MEETING;
      } else {
        // muteJoinMeeting = 0;
        command = CONTROL_COMMAND.CLOSE_MUTE_JOIN_MEETING;
      }

      await fetchMeetControl({
        command
      }, this.$configs)

      // this.$store.commit("meet/updateGlobalMeetState", {
      //   muteJoinMeeting,
      // });
    },
    async allowSelfUnmuteChange(e) {
      // let allowSelfUnmute;
      let command;

      if (e) {
        // allowSelfUnmute = 1;
        command = CONTROL_COMMAND.ALLOW_SELF_UNMUTE;
      } else {
        // allowSelfUnmute = 0;
        command = CONTROL_COMMAND.FORBID_SELF_UNMUTE;
      }

      await fetchMeetControl({
        command
      }, this.$configs)

      // this.$store.commit("meet/updateGlobalMeetState", {
      //   allowSelfUnmute,
      // });
    },
    async playTipsChange(e) {
      // let playTips;
      let command;

      if (e) {
        // playTips = 1;
        command = CONTROL_COMMAND.OPEN_PLAY_TIPS;
      } else {
        // playTips = 0;
        command = CONTROL_COMMAND.CLOSE_PLAY_TIPS;
      }

      await fetchMeetControl({
        command
      }, this.$configs)
    },

    /**
     * 点击成为主持人
     */
    setBecomeHostHandle() {
      this.hostPassword = ''
      this.becomeHostDialog = true
    },

    /**
     * 成为主持人提交
     */
    async becomeHostSubmitHandle() {
      try {
        await fetchMeetControl({
          command: CONTROL_COMMAND.APPLY_HOST,
          metaData: {
            ownerPassword: this.hostPassword
          }
        }, this.$configs)
        

        this.becomeHostDialog = false
      } catch (error) {
        console.log(error);
      }
    },

    /**
     * 设置主持人点击事件
     */
    setHostClickHandle(item) {
      this.currentSelectItem = item

      this.setHostDialog = true
    },

    /**
     * 设置主持人
     */
    async setHostHandle() {
      try {
          this.setHostDialog = false

          await fetchMeetControl({
            command: CONTROL_COMMAND.TRANSFER_HOST,
            toPeerId: this.currentSelectItem.userId,
          }, this.$configs)

        } catch (error) {
          console.log(error);
        }
    },

    /**
     * 收回主持人
     */
    async revokeHostHandle() {
      try {
        await fetchMeetControl({
          command: CONTROL_COMMAND.RECOVER_HOST_PERMISSION,
          // toPeerId: this.$configs.peerId,
        }, this.$configs)

      } catch (error) {
        console.log(error);
      }
    },

    /**
     * 撤销联席主持人
     */
    async setUserHandle(item) {
      try {
        await fetchMeetControl({
          command: CONTROL_COMMAND.GRANT,
          metaData: {
            roleCode: ROLE_CODE.USER,
          },
          toPeerId: item.userId,
        }, this.$configs);

        this.$totast(this.$t('meeting.Withdrawn') + `${this.getEllipsisStr(item.userName)}`+this.$t('meeting.moderator'))
      } catch (error) {
        this.$totast(this.$t('meeting.hostFail'))
      }
    },

    /**
     * 设为联席主持人，变为普通用户
     */
    async setCoHostHandle(item) {
      try {
        await fetchMeetControl({
          command: CONTROL_COMMAND.GRANT,
          metaData: {
            roleCode: ROLE_CODE.CO_HOST,
          },
          toPeerId: item.userId,
        }, this.$configs);

        this.$parent.$parent.$parent.showToast(`${this.getEllipsisStr(item.userName)}`+ this.$t('meeting.beHost'))
      } catch (error) {
        this.$parent.$parent.$parent.showToast(this.$t('meeting.goFail'))
      }
    },


    /**
     * 移除会议点击事件
     */
    removeMemberClickHandle(item) {
      this.currentSelectItem = item

      this.removeUserDialog = true
    },

    /**
     * 移除会议
     */
    async removeMemberHandle() {
      try {
        await fetchMeetControl({
          command: CONTROL_COMMAND.REMOVE_USER,
          toPeerId: this.currentSelectItem.userId
        }, this.$configs)

        this.$totast({
          message: this.$t('meeting.removeSuccess')
        })

        this.removeUserDialog = false
      } catch (error) {
        this.removeUserDialog = false
      }
    },

    /**
     * 点击改名按钮
     */
    changeNameClickHandle(item) {
      this.currentSelectItem = item
      this.currentChangedName = item.userName

      this.changeNameDialog = true
    },

    changeSearchChoice(searchChoice){
      this.searchChoice = searchChoice
    },
    //父元素弹窗关闭
    dialogCloseClick(){
      this.searchChoice = false
      window.removeEventListener('resize', this.hiddenCurrentMenu)
    },
    /**
     * 改名
     */
    async changeNameHandle() {
      if (!this.currentChangedName) {
         return
      }

      try {
        await wordsFilter({ 
          bizType:'meeting',
          text:this.currentChangedName
        })
      } catch (error) {
        let _msg = [102,103,199001].includes(error.code) ? '名字内容存在敏感词，请检查后重试' : '名字内容' + error.msg
        this.$totast(_msg)
        loganLog(`改名错误--error:${JSON.stringify(error)}，test:${this.chatContent}`)
        return
      }
      
      if (this.isCurrentUser(this.currentSelectItem)) {
        console.log("操作自己");

        // im的改名信令
        this.$i100MeetSDK.i100IM.updateNickAndAvatar({
          nickname: this.currentChangedName
        });


        // rtc信令改名
        this.$i100MeetSDK.i100MeetingControl.changeLocalDisplayName(this.currentChangedName)

        this.changeNameDialog = false
      } else {
        // 给其他人改名
        console.log("操作他人");

        try {
          await fetchMeetControl({
            command: CONTROL_COMMAND.CHANGE_NAME,
            metaData: {
              userName: this.currentChangedName
            },
            toPeerId: this.currentSelectItem.userId,
          }, this.$configs);

          this.changeNameDialog = false

          // TODO: 调用成功后的提示
        } catch (error) {
          console.log(error);
        }
      }
    },

    /**
     * 举手
     */
    async raiseHandHandle() {
      try {
        const selfId = this.$configs.peerId;

        await fetchMeetControl({
          command: CONTROL_COMMAND.RAISE_HAND,
          toPeerId: selfId,
        }, this.$configs);


        // TODO: 调用成功后的提示
      } catch (error) {
        console.log(error);
      }
    },

    /**
     * 主持人将某个成员手放下
     */
    async singDownHandHandle(item) {
      try {
        await fetchMeetControl({
          command: CONTROL_COMMAND.SINGLE_HAND_DOWN,
          toPeerId: item.userId,
        }, this.$configs);

        // TODO: 调用成功后的提示
      } catch (error) {
        console.log(error);
      }
    },

    /**
     * 成员主动将手放下
     */
    async downHandHandle() {
      try {
        const selfId = this.$configs.peerId;

        await fetchMeetControl({
          command: CONTROL_COMMAND.SINGLE_HAND_DOWN,
          toPeerId: selfId,
        }, this.$configs);

        // TODO: 调用成功后的提示
      } catch (error) {
        console.log(error); // TODO:
      }
    },

    /**
     * 全体手放下 TODO:
     */
    async downAllHandHandle() {
      try {
        await fetchMeetControl({
          command: CONTROL_COMMAND.ALL_HAND_DOWN,
        }, this.$configs);

        // TODO: 调用成功后的提示
      } catch (error) {
        console.log(error); // TODO:
      }
    },

    /**
     * 停止成员分享点击事件
     */
    async stopShareClickHandle(item) {
      this.currentSelectItem = item
      this.stopShareDialog = true
    },

    async stopShareHandle() {
      try {
        this.stopShareDialog = false

        await fetchMeetControl({
          command: CONTROL_COMMAND.STOP_SHARE,
          toPeerId: this.currentSelectItem.userId
        }, this.$configs)
      } catch (error) {
        console.log(error)
      }
    },

    /**
     * 停止视频
     */
    async stopVideHandle(item) {
      try {
        await fetchMeetControl({
          command: CONTROL_COMMAND.SHIELD_VIDEOS,
          toPeerId: item.userId
        }, this.$configs)

        // TODO: 调用成功后的提示
      } catch (error) {
        console.log(error)
      }
    },

    /**
     * 静音
     */
    async setMuteHandle(userId) {
      if (userId === this.selfInfo.userId) { // 自己执行静音操作

        console.log('操作自己')

        try {
          await this.$meetingControl.muteLocalAudio(true)

          // 更改自身状态
          this.$store.commit('member/updateUser', {
            userId,
            isUseHuaTong: false
          })
        } catch (error) {
          this.$sentry.captureException({
            msg: '成员列表静音操作',
            userId: this.$configs.peerId,
            userName: this.selfInfo.userName,
            error
          })
        }
      } else { // 控制他人静音
        console.log('操作他人静音')

        try {
          await fetchMeetControl({
            command: CONTROL_COMMAND.SINGLE_MUTE,
            toPeerId: userId
          }, this.$configs)
        } catch (error) {
          console.log(error)
        }
      }
    },

    /**
     * 解除静音
     */
    async setUnMuteHandle(userId) {
      if (userId === this.selfInfo.userId) {

        console.log('操作自己解除静音')

        if (!this.selfInfo.isUseHuaTong && this.isOrdinaryUser && !this.allowSelfUnmute) { 
          this.commonTipText = this.$t('meeting.noVoiceFail')
          this.commonTipDialog = true
          return
        }

        try {
          await this.$meetingControl.muteLocalAudio(false)

          // 更改自身状态
          this.$store.commit('member/updateUser', {
            userId,
            isUseHuaTong: true
          })
        } catch (error) {
          this.$sentry.captureException({
            msg: '成员列表解除静音操作',
            userId: this.$configs.peerId,
            userName: this.selfInfo.userName,
            error
          })
        }
      } else { // 控制他人解除静音
        console.log('操作他人解除静音')

        try {
          await fetchMeetControl({
            command: CONTROL_COMMAND.SINGLE_UNMUTE,
            toPeerId: userId
          }, this.$configs)
        } catch (error) {
          console.log(error)
        }

      }
    },

    /**
     * 全体静音点击事件
     */
    async setAllMuteClickHandle() {
      console.log(1111)

      this.allMuteDialog = true
    },

    /**
     * 全体静音
     */
    async setAllMuteHandle() {
      try {
        let command = CONTROL_COMMAND.ALL_FORCE_MUTE
        if (this.allowSelfUnmute) {
          command = CONTROL_COMMAND.ALL_UNFORCE_MUTE
        }

        await fetchMeetControl({
          command
        }, this.$configs)

        this.allMuteDialog = false
      } catch (error) {
        console.log(error)
      }
    },
    
    /**
     * 解除全体静音
     */
    async setAllUnMuteHandle() {
      this.$parent.$parent.$parent.showToast(this.$t('meeting.pleaceVoice'))

      try {
        await fetchMeetControl({
          command: CONTROL_COMMAND.ALL_UNMUTE
        }, this.$configs)
      } catch (error) {
        console.log(error)
      }
    },
    


    /* ---------按钮部分的显示隐藏逻辑----------- */
    // 是否为当前用户
    isCurrentUser(item) {
      return item.userId === this.$configs.peerId;
    },


    /**
     * 设置全体静音的高亮状态
     * 0：全体解除静音  1：全体静音
     */
    isSetHighLight() {
      return (this.allMuteState && !this.isOrdinaryUser) ? 'primary' : ''
    },

    isShowAllToggleMute() { // 是否显示全体静音/取消全体静音
      return this.isHost || this.isCoHost
    },

    isShowStopAllHand() {
      // 是否显示全部停止举手
      let isExitUserHand = false;
      for (let i = 0; i < this.userList.length; i++) {
        const item = this.userList[i];

        if (item.isRaiseHand) {
          // 正在举手
          isExitUserHand = true;
          break;
        }
      }

      return (this.isHost || this.isCoHost) && isExitUserHand;
    },

    isShowRevokeHost() {
      if (this.selfInfo.userId) {
        const idArr = this.selfInfo.userId.split('_')
        return !this.isHost && (this.ownerId === idArr[1])
      } else {
        return false
      }
    },

    isShowBottomHand() { // 举手
      return this.isOrdinaryUser && !this.selfInfo.isRaiseHand
    },

    isShowBottomDownHand() { // 手放下
      return this.isOrdinaryUser && this.selfInfo.isRaiseHand
    },

    isShowBottomSelfMute() { // 静音
      return this.isOrdinaryUser && this.selfInfo.isUseHuaTong
    },
    
    isShowBottomSelfUnMute() { // 解除静音
      return this.isOrdinaryUser && !this.selfInfo.isUseHuaTong
    },


    isShowBecomeHost() { // 成为主持人，注意owner永远不会显示成为主持人

      let isExistHost = false

      for (let i = 0; i < this.userList.length; i++) {
        const item = this.userList[i]

        if (item.roleCode === ROLE_CODE.HOST) {
          isExistHost = true
          break
        }
      }

      return this.ownerPasswordEnable && (this.ownerId !== this.$configs.userId) && !isExistHost
    },


    // 成员列表右侧按钮显示逻辑，可能的情况：
    // 主持人 => 自己
    // 主持人 => 联席主持人
    // 主持人 => 普通用户

    // 联席主持人 => 主持人（不支持）
    // 联席主持人 => 自己
    // 联席主持人 => 其他联席主持人
    // 联席主持人 => 普通用户

    // 普通用户 => 主持人（不支持）
    // 普通用户 => 联席主持人（不支持）
    // 普通用户 => 自己
    // 普通用户 => 其他普通用户

    // 成员列表右侧按钮显示逻辑判断
    isShowMute(item) { // 是否显示静音按钮
      if (item.isUseHuaTong) { // 开启音频状态
        if (this.isOrdinaryUser) { // 普通用户
          return this.isCurrentUser(item) // 移动到自己，显示静音按钮
        } else if (this.isCoHost) { // 联席主持人
          return item.roleCode !== ROLE_CODE.HOST
        } else { // 主持人
          return true
        }
      } else { // 静音状态
        return false
      }
    },

    isShowUnMute(item) { // 是否显示解除静音
      if (item.isUseHuaTong) { // 开启音频状态
        return false
      } else { // 静音状态
        if (this.isOrdinaryUser) { // 普通用户
          return this.isCurrentUser(item) // 移动到自己，显示静音按钮
        } else if (this.isCoHost) { // 联席主持人
          return item.roleCode !== ROLE_CODE.HOST
        } else { // 主持人
          return true
        }
      }
    },
    

    isShowChangeName(item) { // 是否显示改名
      return this.isCurrentUser(item)
    },

    isShowChoiceBox(item) {
      // 是否显示更多按钮
      
        return true;
    },

    isCloudRecor(roleCode){
      if((this.isCoHost || this.isHost || this.$store.state.meet.cloudRecorState !== 20) && (roleCode === ROLE_CODE.HOST || roleCode === ROLE_CODE.CO_HOST)){
        let icon = false
        if(this.$store.state.meet.cloudRecorState > 0 && this.$store.state.meet.cloudRecorState < 40) icon = 'icona-icontubiaohuichangluzhi_24_hei'
        if(this.$store.state.meet.cloudRecorState === 20) icon = 'icona-icontubiaohuichangluzhi_24_hei3'
        return icon
      }else{
        return false
      }
    },

    isShowMore(item) {
      // 是否显示更多按钮
      if (this.isOrdinaryUser) {
        // 普通用户不显示
        return false;
      } else if (this.isCoHost) {
        // 联席主持人
        if (item.roleCode === ROLE_CODE.HOST) {
          return false;
        } else if (item.roleCode === ROLE_CODE.CO_HOST) {
          if(item.isShare || item.isUseShiPin){
            return !this.isCurrentUser(item);
          }else{
            return false;
          }
        } else {
          return !this.isCurrentUser(item);
        }
      } else {
        // 主持人
        return !this.isCurrentUser(item);
      }
    },

    isShowMoreChangeName(item) {
      // 是否显示下拉菜单中的改名
      if (this.isCoHost) {
        return item.roleCode !== ROLE_CODE.CO_HOST;
      } else {
        return true;
      }
    },
    
    isShowSetCoHost(item) { // 是否显示设置联席主持人按钮
      // return item.roleCode !== ROLE_CODE.CO_HOST

      if (this.isHost) {
        return item.roleCode === ROLE_CODE.USER;
      } else {
        return false;
      }
    },

    isShowNotSetCoHost(item) {
      // 是否显示撤销联席主持人
      if (this.isHost) {
        return item.roleCode === ROLE_CODE.CO_HOST;
      } else {
        return false;
      }
    },

    isShowSetHost() {
      // 是否显示设置为主持人按钮
      return this.isHost;
    },

    isShowStopVideo(item) {
      // 是否显示停止视频
      return item.isUseShiPin;
    },

    isShowStopShare(item) {
      // 是否显示停止分享
      return item.isShare;
    },

    isShowStopHand(item) {
      // 是否显示放下手
      if (this.isHost || this.isCoHost) {
        // 主持人
        return item.roleCode === ROLE_CODE.USER && item.isRaiseHand;
      } else {
        return false;
      }
    },

    isShowStopMeet(item) { // 是否显示移除会议
      if (this.isHost || this.isCoHost) { // 主持人或者是联席主持人
        return (item.roleCode === ROLE_CODE.USER)
      } else {
        return false;
      }
    },


    getUserNameFirst(str) {
      if (str) {
        return str.charAt();
      }
      return ''
    },

    /**
     * 显示职称信息
     */
    getRoleText(item) {

      const { roleCode } = item

      const nameConfig = {
      

        [ROLE_CODE.HOST]: this.$t('meeting.host'),
        [ROLE_CODE.CO_HOST]: this.$t('meeting.nohost')
      }

      if (roleCode === ROLE_CODE.USER) { // 普通用户
        return this.isCurrentUser(item) ? `( ${this.$t('meeting.me')} )` : ''
      } else { // 主持人，联系主持人

        const title = nameConfig[roleCode]

        return `(${title}${this.isCurrentUser(item) ? `,${this.$t('meeting.me')}` : ''})`
      }
    },

    
    handleMoreControl() {
      this.moreControl = true;
    },
    closeMoreControl() {
      this.moreControl = false;
    },

    /**
     * * 修复scroll 和 resize时菜单位置问题,强制隐藏当前展示的菜单
     */
    hiddenCurrentMenu() {
      const menuElements = document.querySelectorAll('body > .member-more-menu')
      if (menuElements.length > 0) {
        menuElements[menuElements.length - 1].style.display = 'none'
      }
    }
  },
};
</script>

<style lang="scss">
.member-container {
  width: 600px;
  // height: 580px;
  background: #ffffff;
  border-radius: 4px;
  position: relative;
  

  .member-header {
    padding: 0 16px;

    line-height: 48px;
    text-align: center;

    position: relative;
    margin-bottom: 8px;


    .member-title {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      line-height: 22px;
    }

    .member-close {
      position: absolute;
      top: 20px;
      right: 16px;
      cursor: pointer;
    }
  }
   /* 设置选中后的文字颜色 */
   .chooseCheckBox .el-checkbox__input.is-checked+.el-checkbox__label {
            color: #333333;
            font-weight:Regular;
          }

  .member-body {
    padding: 0 16px;
    

    .member-search {
      margin-bottom: 16px;
      height: 48px;
      display: flex;
      .search-input {
        margin-top: 8px;
        .el-input__inner {
          height: 32px;
        }
      }
      .search-choice{
        width: 92px;
        height: 48px;
        box-sizing: border-box;
        color: #666666;
        display: flex;
        .button-choice{
            width: 24px;
            height: 24px;
            margin: 12px 0 12px 16px;
        }
        .button-choice-on{
          display: none;
        }
        .button-choice-off{
          display: block;
        }
        span{
            font-size: 14px;
            font-weight: 400;
            line-height: 48px;
            text-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
        }
        .member-search-btn {
          display: block;
          position: relative;
          .el-dropdown-link{
            display: flex;
            cursor: pointer;
          }
          .el-dropdown {
            button{
              height: 48px ;
              border: 0;
              padding: 0 8px 6px;
              font-weight: 400;
              font-size: 14px;
              background-color: transparent;
              outline: none;
            }
            .btn-color-green{
              color:#1AB370;
            }
          }
          .more-list {
            position: absolute;
            right: 0px;
            width: 160px;
            height: 190px;
            background: #ffffff;
            border-radius: 4px;
            box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2),
              0px 8px 20px 0px rgba(0, 0, 0, 0.2);
              .el-dropdown-menu__item {
                font-size: 13px;
                font-weight: 400;
                padding: 9px 0 9px 16px;
                color: #333333;
                line-height: 18px;
                text-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
                display: flex;
                &:hover {
                  background: rgba(0, 0, 0, 0.05);
                }
            }
          }
          .button-item {
            margin-left: 10px;
          }
        }
          
      }
      .search-choice-off{
          color: #1AB370;
          .button-choice-on{
            display: block;
          }
          .button-choice-off{
            display: none;
          }
      }
    }

    .empty-box {
      text-align: center;
      color: #999999;
      font-size: 14px;
      padding-top: 120px;
      height: 338px;
      box-sizing: border-box;
    }

    .member-list {
      height: 338px;
      overflow-y: auto;

      .member-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: all 0.2s ease;
        cursor: pointer;

        &:hover {
          background: rgba(0, 0, 0, 0.05);
          .member-button {
            .member-status-icon {
              display: none;
            }

            .member-status-btn {
              display: block;
            }
          }
        }

        &.orgUserHover {
          .member-button {
            .member-status-icon {
              display: flex !important;
            }
          }
        }
      
        .member-name {
          display: flex;
          align-items: center;
          height: 48px;
          .member-avatar {
            position: relative;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #1ab370;
            /* overflow: hidden; */
            .host_img{
                position: absolute;
                width: 16px;
                height: 16px;
                top: 12px;
                left: 12px;
                border-radius: 50%;
            }
            .member-avatar-text {
              color: #ffffff;
              font-size: 12px;
            }
            .img-common{
              width:28px;
              height:100%;
              border-radius: 50%;
            }
          
          }
     
          .member-title {
            display: flex;
            align-items: center;
            font-size: 14px;
            margin-left: 10px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
            .member-title-name {
              color: #333333;
              font-size: 14px;
              margin-right: 6px;
            }
            .sub-title {
              font-size: 14px;
              font-weight: 400;
              color: #999999;
              line-height: 20px;
            }
          }
        }

        .member-button {
          display: flex;
          align-items: center;
          padding-right: 10px;

          .member-status-icon {
            display: flex;
            .icon-svg{
              margin-right: 23px;
            }
            .icon {
              width: 24px;
              height: 24px;
              margin-right: 23px;

              &:last-child {
                margin-right: 0;
              }
              &.audio-open {
                background: url(~@/assets/images/member/audio-open.png) no-repeat;
                background-size: 100% 100%;
              }
              &.audio-close {
                background: url(~@/assets/images/member/audio-close.png) no-repeat;
                background-size: 100% 100%;
              }
              &.audio-error {
                background: url(~@/assets/images/member/audio-error.png) no-repeat;
                background-size: 100% 100%;
              }
              &.video-open {
                background: url(~@/assets/images/member/video-open.png) no-repeat;
                background-size: 100% 100%;
              }
              &.video-close {
                background: url(~@/assets/images/member/video-close.png) no-repeat;
                background-size: 100% 100%;
              }

              &.record {
                background: url(~@/assets/images/member/record.png) no-repeat;
                background-size: 100% 100%;
              }

              &.record-pause {
                background: url(~@/assets/images/member/record-pause.png) no-repeat;
                background-size: 100% 100%;
              }

              &.record-resume {
                background: url(~@/assets/images/member/record-resume.png) no-repeat;
                background-size: 100% 100%;
              }

              &.share {
                background: url(~@/assets/images/member/share.png) no-repeat;
                background-size: 100% 100%;
              }

              &.hand {
                background: url(~@/assets/images/member/hand.png) no-repeat;
                background-size: 100% 100%;
              }
            }
          }

          .member-status-btn {
            display: none;
            position: relative;

            .more-btn {
              margin-left: 10px;
            }

            .more-list {
              position: absolute;
              right: 0px;
              width: 160px;
              height: 190px;
              background: #ffffff;
              border-radius: 4px;
              box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2),
                0px 8px 20px 0px rgba(0, 0, 0, 0.2);
              .more-item {
                font-size: 13px;
                font-weight: 400;
                padding: 9px 0 9px 16px;
                color: #333333;
                line-height: 18px;
                text-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);

                &:hover {
                  background: rgba(0, 0, 0, 0.05);
                }

                
              }
            }
            .button-item {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
  .member-footer {
    // width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 13px 24px 14px 24px;
    // height: 60px;
    // box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    // position: absolute;
    // bottom: 0;
    .member-more {
      .more-control {
        position: absolute;
        right: 10px;
        bottom: 60px;
        width: 200px;
        // height: 152px;
        background: #ffffff;
        border-radius: 4px;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2),
          0px 8px 20px 0px rgba(0, 0, 0, 0.2);
        padding: 4px 0;

        .el-checkbox {
          display: flex;
          align-items: center;
          height: 36px;
          margin-left: 16px;
          color: #333333;
          font-weight:Regular;
        }
       
        .control-item {
          overflow: hidden;
          padding: 5px 0 5px 16px;
          &:hover {
            background: rgba(0, 0, 0, 0.05);
          }
        }
      }
      .enWidth{
        width: 314px;
      }
    }
  }
}
</style>

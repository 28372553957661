<template>
  <div class="lecturerColumn">
    <topShareControl v-show="isShowMenuBar"/>

    <!-- 中间大画面区域 -->
    <div class="layoutCoreOne">
      <layoutCoreOne
        ref="layoutCoreOne"
        :isFullModel="isFullModel"
        type="col"
      ></layoutCoreOne>
    </div>
    
    <!-- 右侧列表视频区域 -->
    <layoutList
      :isFullModel="isFullModel"
      type="col"
    ></layoutList>
  </div>
</template>

<script>
import LayoutCoreOne from "./Meeting.Layout.CoreOne.vue"
import LayoutList from "./Meeting.Layout.List.vue"
import topShareControl from "./topShareControl.vue"

export default {
  props:['isShowMenuBar'],
  components: {
    layoutCoreOne: LayoutCoreOne,
    layoutList: LayoutList,

    topShareControl
  },
  data () {
    return {

    }
  },
  computed: {
    isFullModel() {
      return this.$store.state.meet.isFullModel
    }
  },

  created() {
    this.$store.commit("member/updatePageIndex", 1)
    this.$store.commit("member/updatePageSize", 4)
  }
};
</script>

<style lang="scss" scoped>
.lecturerColumn {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}
</style>
import Vue from 'vue'
import * as retry from 'retry'

import isobject from 'lodash.isobject'
import {
  QRTCVideoResolution,
  QRTCVideoResolutionMode,
  QRTCVideoFillMode,
  QRTCCodecType
} from "@/meet-sdk/module/rtc/lib/RoomParams";

import { getAvatar, getAcceptLanguage, getUuid, setMeetInfo } from "@/utils/auth"
import { strToBoolean } from "@/utils/str";
import eventBus from '@/utils/bus'
import { loganLog } from "@/utils/log"
// import { showErrorNotify } from "@/utils/notify"

import { signalUrl } from "@/config/index";
import { LAYOUT_CONFIG, MAX_SHARE_VIDEO_FPS, MAX_AUDIO_TRANSPORT } from "@/constant/index";
import { fetchReconnectionInfo } from '@/api/meet'
import { DIALOG_ERR_CODE, DIALOG_BACK_ERR_CODE, ERR_DATE_MAP,NEED_LOADING_CODE,DEVICE_ERR_CODE} from "@/constant/index"



function MeetingManager(vueInstance) {
  this.vue = vueInstance
  
  this.roomClient = this.vue.$i100MeetSDK
  this.i100MeetingControl = this.roomClient.i100MeetingControl
  this.i100Settings =this.roomClient.i100Settings
  // console.log(this.i100MeetingControl)
  this.localUser = null; // 本地用户

  // 开启本地音视频等待队列
  this._isLocalMediaPending = false;
  this._pendingLocalMedia = [];

  // 订阅远端视频队列配置
  this._pendingRemoteConfig = {};

  // 是否执行reconnect
  this.isLoadReConnect = false;

  this.initEvent()
}


// 初始化方法
MeetingManager.prototype.initEvent = function() {
  console.error('meetingManager initEvent')
  // 绑定sdk异常处理
  this.handErrorMessage();

  // 绑定本人进入房间后的回调
  this.onEnteredMeeting();

  // 远端有人进入房间后
  this.onRemoteUserEnterMeeting();

  // 服务端通知 - 远端有人推视频流
  this.onUserVideoAvailable();

  // 远端有人离开房间后
  this.onRemoteUserLeaveMeeting();

  // 服务端通知 - 远程有人推音频流
  this.onUserAudioAvailable();

  // 服务端通知-远程有人开始讲话
  this.userSpeaking();

  // 本地用户共享开始
  this.screenCaptureStarted();

  // 本地用户共享结束
  this.screenCaptureStoped();

  // 服务端通知 - 远程有人推共享流
  this.userShareAvailable();
}

// 进入房间
MeetingManager.prototype.enterRoom = function(options = {}) {
  return new Promise(async (resolve, reject) => {
    const { roomId, peerId, userName, avatarUrl } = this.vue.$configs
    const { conferenceToken } = options
    // 2. 进入房间
    try {
      // const localDeviceData = getLocalDeviceInfo();
      // const quality = localDeviceData.videoQuality;
      const quality = await this.i100Settings.getConfig('videoQuality')
      let videoCodeModule
      try {
        videoCodeModule = await this.i100Settings.getConfig('videoCodeModule')
      } catch (error) {
        videoCodeModule = ''
      }
      let videoResolution
      try {
        videoResolution = await this.i100Settings.getConfig('videoResolution')
      } catch (error) {
        videoResolution = ''
      }
      let _VideoResolution 

      if(quality === 1){
        if(videoResolution === 1080){
          _VideoResolution = QRTCVideoResolution.QRTCVideoResolution_1920_1080
        }else if(videoResolution === 720){
          _VideoResolution = QRTCVideoResolution.QRTCVideoResolution_1280_720
        }else{
          _VideoResolution = QRTCVideoResolution.QRTCVideoResolution_640_360
        }
      }else{
        _VideoResolution = QRTCVideoResolution.QRTCVideoResolution_640_360
      }
      //设置编码参数
      const encoderParams = {
        preferCodec: videoCodeModule === 1? QRTCCodecType.QRTCCodec_AV1 : QRTCCodecType.QRTCCodec_H264Base,
        videoResolution:_VideoResolution,
        resMode: QRTCVideoResolutionMode.QRTCVideoResolutionModeLandscape,
        videoFps: 10,
        videoBitrate: 400,
        minVideoBitrate: 200,
        maxVideoBitrate: 500,
      }

      this.i100MeetingControl.setVideoEncoderParam(encoderParams)

      this.i100MeetingControl.setRemoteViewFillMode(
        QRTCVideoFillMode.QRTCVideoFillMode_Fill
      )

      // 进房
      await this.i100MeetingControl.startMeeting({
        serverUrl: signalUrl,
        sdkAppId: 1,
        userSig: "",
        userId: peerId,
        userName: userName,
        roomId: roomId,
        maxRetryTime: 30 * 1000,
        avatarUrl: avatarUrl,

        xConferenceToken: conferenceToken, // 获取会议token
        acceptLanguage: getAcceptLanguage()
      })

      resolve()
    } catch (error) {
      // console.error(11111)
      // console.error(error)
      reject(error)
    }
  })
}

// 打开本地音频
MeetingManager.prototype.startLocalAudio = function(isUseHuaTong) {
  return this._startLocalMedia("audio", { isUseHuaTong });
};

// 打开本地视频
MeetingManager.prototype.startLocalPreview = function(videoEl) {
  return this._startLocalMedia("video", { videoEl });
};

MeetingManager.prototype._startLocalMedia = function(type, params) {
  return new Promise((resolve, reject) => {
    if (this._isLocalMediaPending) {
      console.error("要开启本地媒体，先缓存---" + type);

      this._pendingLocalMedia.push({
        type,
        f: this._handleStartlocalMedia,
        params,
        promiseResolve: resolve,
        promiseReject: reject,
      });
    } else {
      // 正常执行
      console.error("正常执行开启本地媒体----" + type);
      this._handleStartlocalMedia(type, params, resolve, reject);
    }
  });
};

MeetingManager.prototype._handleStartlocalMedia = async function(type, p, resolve, reject) {
  this._isLocalMediaPending = true;

  if (type === "audio") {
    console.error("将要开启本地音频----");
    console.log(p);

    try {
      await this.i100MeetingControl.startLocalAudio(!p.isUseHuaTong);

      // 麦克风
      this.vue.$deviceControl.setCurrentMicDevice()
      this.vue.$deviceControl.initMicDeviceVolume()

      console.error("本地音频开启成功----");

      this._isLocalMediaPending = false
      resolve()
    } catch (error) {
      // this._handlelocalMediaError('audio', error) //音视频错误1.6 SDK异常处理了
      this._isLocalMediaPending = false
      reject(error)
    }
  } else if (type === "video") {
    console.error("将要开启本地视频----");
    console.log(p);

    try {
      const quality = await this.i100Settings.getConfig('videoQuality')
      let videoCodeModule
      try {
        videoCodeModule = await this.i100Settings.getConfig('videoCodeModule')
      } catch (error) {
        videoCodeModule = ''
      }
      let videoResolution
      try {
        videoResolution = await this.i100Settings.getConfig('videoResolution')
      } catch (error) {
        videoResolution = ''
      }
      let _VideoResolution 

      if(quality === 1){
        if(videoResolution === 1080){
          _VideoResolution = QRTCVideoResolution.QRTCVideoResolution_1920_1080
        }else if(videoResolution === 720){
          _VideoResolution = QRTCVideoResolution.QRTCVideoResolution_1280_720
        }else{
          _VideoResolution = QRTCVideoResolution.QRTCVideoResolution_640_360
        }
      }else{
        _VideoResolution = QRTCVideoResolution.QRTCVideoResolution_640_360
      }
       const encoderParams = {
        preferCodec: videoCodeModule === 1? QRTCCodecType.QRTCCodec_AV1 : QRTCCodecType.QRTCCodec_H264Base,
        videoResolution:_VideoResolution,
        resMode: QRTCVideoResolutionMode.QRTCVideoResolutionModeLandscape,
        videoFps: 10,
        videoBitrate: 400,
        minVideoBitrate: 200,
        maxVideoBitrate: 500,
      }

      await this.i100MeetingControl.setVideoEncoderParam(encoderParams)

      await this.i100MeetingControl.startLocalVideo(p.videoEl);
      console.error("本地视频开启成功----");

      this._isLocalMediaPending = false
      resolve()
    } catch (error) {
      // this._handlelocalMediaError('video', error) //音视频错误1.6 SDK异常处理了
      this._isLocalMediaPending = false
      console.error("本地视频开启失败----");
      reject(error)
    }
  }

  if (this._pendingLocalMedia.length > 0) {
    const currentPend = this._pendingLocalMedia[0];

    // 推出数组中的第一个元素
    this._pendingLocalMedia.shift();

    const { type, f, params, promiseResolve, promiseReject } = currentPend;

    f.call(this, type, params, promiseResolve, promiseReject);
  }
};

/**
 * 开启本地音视频的错误处理
 */
MeetingManager.prototype._handlelocalMediaError = function(type, error) {
  /**
   * error.name:
   * 1. NotAllowedError 未开启摄像头/麦克风权限
   * 2. AbortError
   * 3. OverConstrainedError
   * 4. NotReadableError 摄像头/麦克风正在被占用
   */
  const selfId = this.vue.$configs.peerId

  const config = {
    audio: {
      notPermission: this.vue.$t('meeting.audioNotPermission'),
      occupy: this.vue.$t('meeting.audioOccupy'),
      sentryMsg: '开启本地音频失败'
    },
    video: {
      notPermission: this.vue.$t('meeting.videoNotPermission'),
      occupy: this.vue.$t('meeting.videoOccupy'),
      sentryMsg: '开启本地视频失败'
    }
  }
  const targetConfig = config[type]

  let errMsg = this.vue.$t('meeting.operationFailed')
  if (isobject(error) && error.name) {
    const name = error.name
    if (name === 'NotAllowedError') {
      errMsg = targetConfig.notPermission
    } else if (name === 'NotReadableError') {
      errMsg = targetConfig.occupy
    }
  }
  // this.vue.showToast && this.vue.showToast(errMsg)

  const targetUser = this.vue.$store.state.member.userList.find(i => i.userId === selfId)
  this.vue.$sentry.captureException({
    msg: targetConfig.sentryMsg,
    userId: selfId,
    userName: targetUser ? targetUser.userName : '',
    error
  })
}


// 关闭本地音频
MeetingManager.prototype.stopLocalAudio = function() {
  loganLog(`关闭本地音频 stopLocalAudio`)
  return this.i100MeetingControl.stopLocalAudio();
};

// 停止本地视频
MeetingManager.prototype.stopLocalPreview = function() {
  loganLog(`停止本地视频 stopLocalPreview`)
  return this.i100MeetingControl.stopLocalVideo();
};

// 暂停/恢复本地音频
MeetingManager.prototype.muteLocalAudio = function(pause) {
  loganLog(`暂停/恢复本地音频,pause:${pause}`)
  return this.i100MeetingControl.muteLocalAudio(pause);
};

// 打开或者关闭扬声器
MeetingManager.prototype.setAudioPlayoutVolume = function(val, elements) {
  // 0的话代表关闭扬声器
  loganLog(`打开或者关闭扬声器,val:${val}`)
  return this.i100MeetingControl.startLocalSpeaker(elements, val);
};


// 订阅远端用户的音频
MeetingManager.prototype.startRemoteAudio = function(userId, element) {
  return this.i100MeetingControl.startRemoteAudio(userId, element);
};

// 停止订阅远端用户的音频
MeetingManager.prototype.stopRemoteAudio = function(userId) {
  return this.i100MeetingControl.stopRemoteAudio(userId);
};

// 订阅远端用户视频
MeetingManager.prototype.startRemoteVideo = function(userId, element) {
  if (!this._pendingRemoteConfig[userId]) {
    this._pendingRemoteConfig[userId] = {
      isPending: false,
      pendingList: [],
    };
  }

  const pendItem = this._pendingRemoteConfig[userId];

  if (pendItem.isPending) {
    loganLog('订阅远端视频, 先缓存-----')
    pendItem.pendingList.push({
      f: this._startRemoteVideo,
      params: { userId, element },
    });
  } else {
    loganLog('正常订阅远端视频-----');
    this._startRemoteVideo({ userId, element });
  }
};

MeetingManager.prototype._startRemoteVideo = async function(p) {

  const pendItem = this._pendingRemoteConfig[p.userId];

  pendItem.isPending = true;

  try {
    await this.remoteVideoWithRetry(p)
    loganLog('remoteVideoWithRetry成功了----------')
  } catch (error) {
    loganLog('remoteVideoWithRetry失败了----------')
    loganLog(error)
  }

  pendItem.isPending = false;

  if (pendItem.pendingList.length > 0) {
    const currentPend = pendItem.pendingList[0];

    loganLog('推出----------')
    loganLog(currentPend)

    // 推出数组中的第一个元素
    pendItem.pendingList.shift();

    const { f, params } = currentPend;

    f.call(this, params);
  }
};

//清理大画面loading
MeetingManager.prototype.changeMainLoadingClass = function() {
  setTimeout(() => {
    let loadingMainEl = null
    try {
      loadingMainEl = document.getElementById(`main-video-loading-box`)
    } catch (error) {
      loadingMainEl = null
    }
    console.error("loadingMainEl",loadingMainEl)
    loadingMainEl && loadingMainEl.classList.remove('loading-box-show')
  }, 0);
}
MeetingManager.prototype.changeLoadingClass = function(userId,type,isShare) {
  setTimeout(() => {
    let loadingEl = null
    let loadingMainEl = null
    let loadingTab2El = null
    let loadingTopShareEl = null
    try {
      loadingEl = document.getElementById(`video-${userId}`).nextSibling
      if((isShare && loadingEl.getAttribute("data-iscurrentshare") === 'false') || (!isShare && loadingEl.getAttribute("data-iscurrentshare") === 'true')){
        loadingEl = null
      }
    } catch (error) {
      loadingEl = null
      // console.error(`元素失败获取loading: ${userId}`)
    }
    try {
      loadingMainEl = document.getElementById(`main-video-${userId}`).nextSibling
      if((isShare && loadingMainEl.getAttribute("data-iscurrentshare") === 'false') || (!isShare && loadingMainEl.getAttribute("data-iscurrentshare") === 'true')){
        loadingMainEl = null
      }
    } catch (error) {
      loadingMainEl = null
      // console.error(`元素失败获取loading: ${userId}`)
    }
    try {
      loadingTab2El = document.getElementById(`tab2-video-${userId}`).nextSibling
      if((isShare && loadingTab2El.getAttribute("data-iscurrentshare") === 'false') || (!isShare && loadingTab2El.getAttribute("data-iscurrentshare") === 'true')){
        loadingTab2El = null
      }
    } catch (error) {
      loadingTab2El = null
      // console.error(`元素失败获取loading: ${userId}`)
    }
    try {
      loadingTopShareEl = document.getElementById(`top-share-${userId}`).nextSibling
      if((isShare && loadingTopShareEl.getAttribute("data-iscurrentshare") === 'false') || (!isShare && loadingTopShareEl.getAttribute("data-iscurrentshare") === 'true')){
        loadingTopShareEl = null
      }
    } catch (error) {
      loadingTopShareEl = null
      // console.error(`元素失败获取loading: ${userId}`)
    }
    // console.error("type:",type)
    // console.error("loadingEl",loadingEl)
    // console.error("loadingMainEl",loadingMainEl)
    // console.error("loadingTab2El",loadingTab2El)
    // console.error("loadingTopShareEl",loadingTopShareEl)
  
    if(type === 'remove'){
      loadingEl && loadingEl.classList.remove('loading-box-show')
      loadingMainEl && loadingMainEl.classList.remove('loading-box-show')
      loadingTab2El && loadingTab2El.classList.remove('loading-box-show')
      loadingTopShareEl && loadingTopShareEl.classList.remove('loading-box-show')
      if(this[`timer${userId}${isShare}`]){
        clearTimeout(this[`timer${userId}${isShare}`])
      }
    }else{
      const targetUser = this.vue.$store.state.member.userList.find(i => !i.isTopShare && (i.userId === userId))
      if (targetUser && targetUser.isUseShiPin || isShare && targetUser && targetUser.isShare) {
        loadingEl && loadingEl.classList.add('loading-box-show')
        loadingMainEl && loadingMainEl.classList.add('loading-box-show')
        loadingTab2El && loadingTab2El.classList.add('loading-box-show')
        loadingTopShareEl && loadingTopShareEl.classList.add('loading-box-show')
      }
      if(!this[`timer${userId}${isShare}`]){
        this[`timer${userId}${isShare}`] =  setTimeout(() => {
          loganLog(`90后自动取消loading`)
          this.changeLoadingClass(userId,'remove',isShare)
        }, 90000);
      }
      // console.error("isUseShiPin:",targetUser.isUseShiPin)
    }
   }, 0);
 }

// MeetingManager.prototype.remoteVideoWithRetry = function(p) {
//   return new Promise((resolve, reject) => {
//     const operation = retry.operation({
//       retries: 3,
//       minTimeout: 0
//     });

//     operation.attempt(async (currentAttempt) => {
//       loganLog(`次数-----${currentAttempt}`)

//       /**
//        * 跳出情况：
//        * 1. 用户已经不在房间了
//        * 2. 用户已经关闭了视频
//        */
//       const operateUserId = this.vue.$configs.peerId
//       const targetUser = this.vue.$store.state.member.userList.find(i => !i.isTopShare && (i.userId === p.userId))
//       const targetUserName = targetUser ? targetUser.userName : ''

//       if (!targetUser || (targetUser && !targetUser.isUseShiPin)) {
//         loganLog(`用户已经不在房间内或者已经把视频关闭了`)
//         loganLog(targetUser)

//         operation.stop()
//         reject({
//           name: 'notExistError',
//           message: 'does not exist'
//         })
//         return
//       }

//       try {
//         await this.i100MeetingControl.startRemoteVideo(p.userId, p.element)
//         this.changeLoadingClass(p.userId,'remove',false)
//         loganLog(`订阅远端用户的视频成功------userId: ${p.userId}---userName: ${targetUserName}`)
//         operation.stop()
//         resolve()
//       } catch (error) {
//         this.changeLoadingClass(p.userId,'add',false)
//         loganLog(`订阅远端用户的视频失败------userId: ${p.userId}---userName: ${targetUserName}---error: ${JSON.stringify(error)}`)



//         // showErrorNotify(this.vue, {
//         //   type: 'video',
//         //   userId: p.userId,
//         //   userName: targetUserName,
//         //   errorMessage: '' // TODO:
//         // })

//         this.vue.$sentry.captureException({
//           msg: '订阅远端用户的视频失败',
//           userId: p.userId,
//           operateUserId,
//           userName: targetUserName,
//           error
//         })

//         if (!operation.retry(new Error())) { // 已达到最大返回数量
//           loganLog(`订阅视频超过最大重试次数-----${currentAttempt}`)

//           this.vue.$sentry.captureException({
//             msg: '订阅远端用户的视频超出最大重试次数',
//             userId: p.userId,
//             operateUserId,
//             userName: targetUserName
//           })

//           reject({
//             name: 'exceedMixRetryError',
//             message: 'Maximum number of retries exceeded'
//           })
//         }
//       }
//     })
//   })
// }




MeetingManager.prototype.remoteVideoWithRetry = function(p) {
  return new Promise((resolve, reject) => {
    const operation = retry.operation({
      retries: 3,
      minTimeout: 0
    });

    operation.attempt(async (currentAttempt) => {
      loganLog(`retry次数-----${currentAttempt}`)

      /**
       * 跳出情况：
       * 1. 用户已经不在房间了
       * 2. 用户已经关闭了视频
       */
      const operateUserId = this.vue.$configs.peerId
      const targetUser = this.vue.$store.state.member.userList.find(i => !i.isTopShare && (i.userId === p.userId))
      const targetUserName = targetUser ? targetUser.userName : ''

      if (!targetUser || (targetUser && !targetUser.isUseShiPin)) {
        loganLog(`用户已经不在房间内或者已经把视频关闭了`)
        loganLog(targetUser)

        operation.stop()
        reject({
          name: 'notExistError',
          message: 'does not exist'
        })
        return
      }

      try {
        await this.i100MeetingControl.startRemoteVideo(p.userId, p.element)
        this.changeLoadingClass(p.userId,'remove',false)
        //删除重试任务
        this.vue.$exceptionManager.deleteRetryJob(p.userId, 'video')

        loganLog(`订阅远端用户的视频成功------userId: ${p.userId}---userName: ${targetUserName}`)
        operation.stop()
        resolve()
      } catch (error) {
        this.changeLoadingClass(p.userId,'add',false)
        loganLog(`订阅远端用户的视频失败------userId: ${p.userId}---userName: ${targetUserName}---error: ${JSON.stringify(error)}`)


        // showErrorNotify(this.vue, {
        //   type: 'video',
        //   userId: p.userId,
        //   userName: targetUserName,
        //   errorMessage: '' // TODO:
        // })

        this.vue.$sentry.captureException({
          msg: '订阅远端用户的视频失败',
          userId: p.userId,
          operateUserId,
          userName: targetUserName,
          error
        })

        if (!operation.retry(new Error())) { // 已达到最大返回数量
          loganLog(`订阅视频超过最大重试次数-----${currentAttempt}`)
          //增加视频重试任务
          this.vue.$exceptionManager.addRetryJob(p.userId, p.element, 'video')

          this.vue.$sentry.captureException({
            msg: '订阅远端用户的视频超出最大重试次数',
            userId: p.userId,
            operateUserId,
            userName: targetUserName
          })

          reject({
            name: 'exceedMixRetryError',
            message: 'Maximum number of retries exceeded'
          })
        }
      }
    })
  })
}

// 订阅远端用户的分享
MeetingManager.prototype.startRemoteShare = function(userId, element) {
  const operation = retry.operation({
    retries: 3,
    minTimeout: 0
  })
  operation.attempt(async (currentAttempt) => {
    loganLog(`分享执行次数-----${currentAttempt}`)

    /**
     * 跳出情况：
     * 1. 用户已经不在房间了
     * 2. 用户已经关闭了分享
     */
    const operateUserId = this.vue.$configs.peerId
    const targetUser = this.vue.$store.state.member.userList.find(i => !i.isTopShare && (i.userId === userId))
    const targetUserName = targetUser ? targetUser.userName : ''

    if (!targetUser || (targetUser && !targetUser.isShare)) {
      loganLog(`用户已经不在房间内或者已经把分享关闭了`)
      loganLog(targetUser)

      operation.stop()
      return
    }

    loganLog(targetUser)

    try {
      await this.i100MeetingControl.startRemoteSharing(userId, element)
      this.changeLoadingClass(userId,'remove',true)
      loganLog(`订阅远端用户的分享成功------userId: ${userId}---userName: ${targetUserName}`)
      operation.stop()
    } catch (error) {
      loganLog(`订阅远端用户的分享失败------userId: ${userId}---userName: ${targetUserName}---error: ${JSON.stringify(error)}`)
      this.changeLoadingClass(userId,'add',true)
      loganLog(`分享失败上报, 并且启动重试-----${currentAttempt}`)

      // showErrorNotify(this.vue, {
      //   type: 'share',
      //   userId,
      //   userName: targetUserName,
      //   errorMessage: '' // TODO:
      // })

      this.vue.$sentry.captureException({
        msg: '订阅远端用户的分享失败',
        userId,
        operateUserId,
        userName: targetUserName,
        error
      })

      if (!operation.retry(new Error())) { // 已达到最大返回数量
        loganLog(`订阅分享超过最大重试次数-----${currentAttempt}`)
        //增加共享重试任务
        this.vue.$exceptionManager.addRetryJob(userId, element, 'share')

        this.vue.$sentry.captureException({
          msg: '订阅远端用户的分享超出最大重试次数',
          userId,
          operateUserId,
          userName: targetUserName
        })
      }
    }
  })
}



// 停止订阅远端用户的视频
MeetingManager.prototype.stopRemoteVideo = function(userId) {
  loganLog(`停止订阅远端用户的视频 stopRemoteVideo,userId:${userId}`)
  return this.i100MeetingControl.stopRemoteVideo(userId);
};

// 暂停远程用户视频
MeetingManager.prototype.muteRemoteVideo = function(userInfo) {
  loganLog(`暂停远程用户视频 muteRemoteVideo,userId:${userId}`)
  return this.i100MeetingControl.muteRemoteVideo(userInfo.userId, true);
};

// 停止订阅远端用户的所有视频
MeetingManager.prototype.muteRemoteVideoAll = function() {
  loganLog(`停止订阅远端用户的所有视频 muteRemoteVideoAll`)
  return this.i100MeetingControl.muteAllRemoteViews(true);
};

// 结束会议
MeetingManager.prototype.logoutRoom = function(isLeave) {
  loganLog(`结束会议 logoutRoom isLeave:${isLeave}`)
  if(!isLeave){
    return this.i100MeetingControl.endMeeting();
  }else{
    return this.i100MeetingControl.leaveMeeting();
  }
};

// 通过程序停止共享
MeetingManager.prototype.stopShare = function() {
  loganLog(`通过程序停止共享 stopShare`)
  return this.i100MeetingControl.stopScreenSharing();
};

// 打开共享
MeetingManager.prototype.openShare = async function() {
  loganLog(`打开共享 openShare`)

  let videoFps = MAX_SHARE_VIDEO_FPS;
  const shareLimitValue = await this.i100Settings.getConfig('shareLimitValue')
  const shareLimit = await this.i100Settings.getConfig('shareLimit')
  if (shareLimit) {
    videoFps = shareLimitValue
  }

  return this.i100MeetingControl.startScreenSharing(null, {
    preferCodec: QRTCCodecType.QRTCCodec_H264Base,
    videoResolution: QRTCVideoResolution.QRTCVideoResolution_2560_1440, // 分辨率枚举
    resMode: QRTCVideoResolutionMode.TRTCVideoResolutionModeLandscape, // 画模式, Landscape: 长大于高, Portrait: 高大于长
    videoFps: videoFps, // 帧率 每秒刷多少帧
    videoBitrate: 500, // 每秒传多少字节
    minVideoBitrate: 300, // 每秒传送最少字节
    maxVideoBitrate: 2500, // 每秒传送最大字节
  })
}


MeetingManager.prototype._selfLocalUserHandle = function() {
  let that = this;

  // 更新localUser
  const attendMap = that.vue.$configs.attendMap;
  const attendUserInfo = attendMap[that.localUser.userId];
  if (attendUserInfo) {
    // 合并
    Object.assign(that.localUser, attendUserInfo);
    // 合并完成后，清理当前的数据
    delete attendMap[that.localUser.userId];
  }

  console.error("--------插入localUser--------");
  that.vue.$store.commit("member/addUser", that.localUser);

  this.i100MeetingControl.setAudioQualityLevel(2)

  // 根据设置打开音频
  const { isUseHuaTong } = that.localUser;
  that.startLocalAudio(isUseHuaTong);
}


// SDK异常通知处理
MeetingManager.prototype.handErrorMessage = function() {
  let that = this;
  let _eventCount = this.roomClient.listenerCount('errorMsg')
  if(_eventCount>0){ //如果注册过了就返回 避免多次注册   重复的问题以后再查一下
    return
  }
  that.roomClient.on('errorMsg', async ({ code, errorData }) => {
    // console.error(3333332)
    code != '80001005' && loganLog(`SDK异常通知，code:${code} errorData:${JSON.stringify(errorData)}`);

    // console.error(ERR_DATE_MAP[code])
       //如果是设备无法打开上次记录，再次点击弹窗提示
       if(DEVICE_ERR_CODE.includes(code)){
        let key = (code === 80000304 || code === 80000334)? "micNoGrant" : "cameraNoGrant"
        that.vue.$store.commit("meet/updateGlobalMeetState", {
          [key]:ERR_DATE_MAP[code]
        })
      }
  
      //如果摄像头设备异常更新本人isUseShiPin
      if(code === 80000301 || code === 80000303 || code === 80000332 || code === 80000310){
        const userId = this.vue.$configs.peerId
        that.vue.$store.commit("member/updateUser", {
          userId,
          isUseShiPin: false,
        })
      }
  
      //本地麦克风热插拔 目前还是在上层
      // if(code === 80000307){}

    //连续几秒（5/10/15）未收到订阅的指定流的音频包
    if(code === 80000528){
      if(errorData.userId){
        this.vue.$store.commit("member/updateUser",  {
          userId:errorData.userId,
          isUseHuaTongError: true
        })
      }
    }
    //订阅的指令流的音频包重新收到
    if(code === 80000538){
      if(errorData.userId){
        this.vue.$store.commit("member/updateUser",  {
          userId:errorData.userId,
          isUseHuaTongError: false
        })
      }
    }

    //应用层相应视频显示loading状态
    if(NEED_LOADING_CODE.includes(code)){
      if(errorData.userId && !that.localUser.sharePaused ){ //有user ID 且当前不是共享暂停状态
        this.changeLoadingClass(errorData.userId,'add',errorData.share || false)
      }
    }
    //应用层相应视频显示loading状态
    if(code === 80000518){
        if(errorData.userId){
          this.changeLoadingClass(errorData.userId,'remove',errorData.share)
        }
    }
    const meetDialogInfo = {
      tips: ERR_DATE_MAP[code]? ERR_DATE_MAP[code].title : errorData.msg,
      showClose : true,
      isGoIndex : false,
      commonText : `错误码:${code}`,
      describe:ERR_DATE_MAP[code]? ERR_DATE_MAP[code].describe : "",
      isGoLogin: false,
      ensureText: '',
      conferenceNo: '',
      title: ''
    }

    if(DIALOG_ERR_CODE.includes(code)){
      that.vue.$store.commit("meet/updateGlobalMeetState", {
        meetDialogInfo:{
          ...meetDialogInfo,
          isGoIndex : false,
          showClose : true
        }
      })
    }
    if(DIALOG_BACK_ERR_CODE.includes(code)){
      that.vue.$store.commit("meet/updateGlobalMeetState", {
        meetDialogInfo:{
          ...meetDialogInfo,
          showClose : false,
          isGoIndex : true
        }
      })
    }
  });
};

// 1.本人进入房间后
MeetingManager.prototype.onEnteredMeeting = function() {
  let that = this;

  let _eventCount = this.roomClient.listenerCount('onEnteredMeeting')
  if(_eventCount>0){ //如果注册过了就返回 避免多次注册   
    return
  }
  that.roomClient.on("onEnteredMeeting", async ( selfTimeMs ) => {
    if (that.isLoadReConnect) {
      loganLog("断线重连，enterRoom成功, 需要调用reconnection");

      console.error('断线前的音视频等待队列情况---------');
      console.log(that._isLocalMediaPending)
      console.log(JSON.stringify(that._pendingLocalMedia))
      console.log(JSON.stringify(that._pendingRemoteConfig))
      
      //清空重试任务
      this.vue.$exceptionManager.deleteRetryJobAll()


      // 清空之前的音视频等待队列
      that._isLocalMediaPending = false
      that._pendingLocalMedia = []
      that._pendingRemoteConfig = {}

      // 清空attendMap
      that.vue.$configs.attendMap = {};

      // 断线重连前是否有人再共享
      const isExitUserShare = that.vue.$store.getters["member/getRealUserList"].find(i => i.isShare)
      if (isExitUserShare) {
        loganLog("断线前有人再共享");
        // window._isReconnectionShare = true
      } else {
        console.error('断线前没有人再共享')
      }

      // 清空member
      that.vue.$store.commit("member/disconnectedReset")
      eventBus.$emit('resetOldPageList')

      // 重置部分全局状态
      that.vue.$store.commit("meet/updateGlobalMeetState", {
        shareScale: "",
        isNetworkDisconnect: false,
      });


      // 本人状态相关
      if (that.localUser.isShare) {
        // 如果用户之前再共享，那需要给出提示
        that.vue.shareStopDialog = true;
      }
      that.localUser.isShare = false; // 是否共享
      that.localUser.sharePaused = false; // 是否共享暂停中
      that.localUser.isSpeaking = false;
      that.localUser.isDbClick = false;
      that.localUser.timeMs = selfTimeMs;
      that.localUser._old = null;

      const { userId, conferenceNo, roomId, peerId } = that.vue.$configs;

      loganLog('重新调用reconnection恢复状态')


      try {
        const resData = await fetchReconnectionInfo({
          userId: userId,
          deviceId: getUuid(),
          conferenceNo: conferenceNo,
          roomId: roomId
        })

        const { attendList, conference, roleCode } = resData;

        loganLog('断网重连之后调用reconnection成功了-------------')

        console.error(11122222,resData)
        

        setMeetInfo(
          resData["X-Conference-Token"],
          resData["X-Channel-Token"]
        )

        that.vue.$router.replace({
          query: {
            ...that.vue.$route.query,
            conferenceNo: conference.conferenceNo
          }
        })

        // 存储全局会议状态
        that.vue.$store.commit("meet/updateGlobalMeetState", {
          allowEarlyEntry: conference.allowEarlyEntry,
          muteJoinMeeting: conference.muteJoinMeeting,
          playTips: conference.playTips,
          allowSelfUnmute: conference.allowSelfUnmute,
          ownerPasswordEnable: conference.ownerPasswordEnable,
          passwordEnable: conference.passwordEnable,
          agendaPermission: conference.agendaPermission,
          allMuteState: conference.allMuteState,
          recordPermission: conference.recordPermission,
          sharePermission: conference.sharePermission,
          lockedState: conference.lockedState,

          ownerName: conference.ownerName,
          ownerId: conference.ownerId,
          links: conference.links,
          userRoleCode: Number(roleCode),
          meetName: conference.title,
          password:conference.password,
          cloudRecorState:conference.cloudRecordState
        });

        // 更新个人状态
        // 是否开启话筒, 这里不在区分是主持人还是普通用户，allMuteState
        if (!conference.allMuteState) {
          console.error("不去处理，还是保持原来的状态---");
          console.log(that.localUser.isUseHuaTong);
        } else {
          that.localUser.isUseHuaTong = false;
        }
        that.localUser.roleCode = Number(roleCode)

        
        // 这里不用存储attendList，直接更新对应的user属性即可
        if (Array.isArray(attendList) && attendList.length > 0) {
          attendList.forEach((user) => {

            // raiseHandStatus 举手状态 1:举手 0：手放下
            // recordStatus 录制状态 1：已录制  0：停止录制
            const { peerId, raiseHandStatus, recordStatus, roleCode } = user

            const stateInfo = {
              roleCode: Number(roleCode),
              isRaiseHand: !!raiseHandStatus,
              isRecord: !!recordStatus
            }

            that.vue.$store.commit("member/updateUser", {
              userId: peerId,
              ...stateInfo
            })
          })
        }

        that._selfLocalUserHandle()
      } catch (error) {
        loganLog("enterRoomAfter调用reconnection失败-----------");

        that.vue.$store.commit("meet/updateGlobalMeetState", {
          meetDialogInfo: {
            isGoIndex: true,
            showClose: false,
            commonText: that.vue.$t('meeting.enterError')
          }
        })
      }
    } else { // 不需要，为第一次进入房间
      that.isLoadReConnect = true;

      loganLog("第一次进入房间，不需要调用reconnection");

      // todo 断网图标手动置为false，都已经enter room成功了。 原因是 断网下离会底层没清理状态。还是会通知disconnected
      this.vue.$store.commit("meet/updateGlobalMeetState", {
        isNetworkDisconnect: false
      })


      const { peerId, userName } = that.vue.$configs;
      const { muteJoinMeeting, allMuteState, userRoleCode } = that.vue.$store.state.meet;

      const isUseHuaTong = that.vue.$loadLocal("isUseHuaTong");
      const isUseShiPin = that.vue.$loadLocal("isUseShiPin");
      const isUseYangShengQi = that.vue.$loadLocal("isUseYangShengQi");

      // 设置自己用户对象初始化信息
      that.localUser = {
        userId: peerId,
        userName: userName, // 用户名称
        avatarUrl: getAvatar(), // 头像信息

        isUseShiPin: strToBoolean(isUseShiPin, false), // 是否开启视频
        isUseYangShengQi: strToBoolean(isUseYangShengQi, true), // 是否开启扬声器
        isShare: false, // 是否共享
        sharePaused: false, // 是否共享暂停中
        isUseHuaTongError:false, //话筒放状态是否error
        isSpeaking: false,
        roleCode: userRoleCode,
        isRaiseHand: false,
        isRecord: false,
        recordPaused: false,
        isDbClick: false,
        timeMs: selfTimeMs,
        _old: null
      }

      // 是否开启话筒, 这里不在区分是主持人还是普通用户，统一取决于muteJoinMeeting和allMuteState
      if (!muteJoinMeeting && !allMuteState) {
        that.localUser.isUseHuaTong = strToBoolean(isUseHuaTong, true);
      } else {
        console.error("直接命中了静音-----");
        that.localUser.isUseHuaTong = false;
      }

      //神策login
      // this.vue.$sensors.login(peerId)

      that._selfLocalUserHandle()
    }
  });
};

// 2.服务端通知 - 远端有人进入房间后
MeetingManager.prototype.onRemoteUserEnterMeeting = function() {
  let _eventCount = this.roomClient.listenerCount('onRemoteUserEnterMeeting')
  if(_eventCount>0){ //如果注册过了就返回 避免多次注册   
    return
  }

  this.roomClient.on("onRemoteUserEnterMeeting", (userInfo) => {
    console.error('onRemoteUserEnterMeeting',userInfo)
    const user = {
      userId: userInfo.userId,
      userName: userInfo.userName,
      avatarUrl: userInfo.avatarUrl,
      isUseHuaTong: false, // 是否开启话筒
      isUseHuaTongError: false, // 话筒状态是否error
      isUseShiPin: false, // 是否开启视频
      isUseYangShengQi: false, // 是否开启扬声器
      isShare: false, // 是否共享中
      sharePaused: false, // 是否共享暂停中
      isSpeaking: false, // 是否说话中

      roleCode: 0,
      isRaiseHand: false,
      isRecord: false,
      recordPaused: false,
      isDbClick: false,
      timeMs: userInfo.timeMs, // 入会时间

      _old: null,
    }

    // 更新user数据
    const attendMap = this.vue.$configs.attendMap;
    const attendUserInfo = attendMap[user.userId];
    if (attendUserInfo) {
      // 合并
      Object.assign(user, attendUserInfo);

      // 合并完成后，清理当前的数据
      delete attendMap[user.userId];
    }

    // 新增用户

    console.error('adduser',user)
    this.vue.$store.commit("member/addUser", user);

    // 播放提示音
    try {
      const { playTips } = this.vue.$store.state.meet;

      if (playTips && userInfo.timeMs > this.localUser.timeMs) {
        console.log("播放提示音-----");
        const tipAudioEl = document.getElementById("userEnterTipAudio");
        tipAudioEl && tipAudioEl.play();
      }
    } catch (error) {
      console.log(error);
    }
  })
}

// 4.服务端通知 - 远端有人离开房间后
MeetingManager.prototype.onRemoteUserLeaveMeeting = function() {
  let _eventCount = this.roomClient.listenerCount('onRemoteUserLeaveMeeting')
  if(_eventCount>0){ //如果注册过了就返回 避免多次注册   
    return
  }
  this.roomClient.on("onRemoteUserLeaveMeeting", (userInfo) => {
    const { userId } = userInfo

    // 在移除之前，判断当前用户是否为大画面的锁定用户
    const isLockUser = this.vue.$store.getters["member/getLockUser"]
    if (isLockUser && (isLockUser.userId === userId)) {
      console.error("锁定用户离开房间了，这时候要移除topshare------");
      this.vue.$store.commit("member/removeShareView");
    }

    // 在userList移除离开房间的用户
    this.vue.$store.commit("member/removeUser", userInfo)

    // 如果是最后一个人切换9宫格 并且本人没开共享
    const { layoutType,isFullModel } = this.vue.$store.state.meet;
    if(!this.localUser.isShare && !isFullModel && this.vue.$store.state.member.userList.length <=1 && (layoutType === LAYOUT_CONFIG.COLUMN || layoutType === LAYOUT_CONFIG.ROW)){
      this.vue.$store.commit("meet/updateGlobalMeetState", {
        layoutType: LAYOUT_CONFIG.GALLERY9
      })
    }

    // 在speakList中移除离开房间的用户
    this.vue.$store.commit("member/removeSpeak", userInfo)

    // 判断lastSpeaker是否为当前离开房间的用户
    const lastSpeaker = this.vue.$store.state.member.lastSpeaker
    if (lastSpeaker && lastSpeaker.userId === userId) {
      this.vue.$store.commit("member/updateLastSpeaker", null)
    }

    // 在audioList中移除用户
    this.vue.$store.commit("member/removeAudio", userInfo)

    // 删除顶部共享画面
    const isTopShare = this.vue.$store.getters["member/getTopShare"]
    if (isTopShare && isTopShare.userId === userId) {
      console.error("存在锁定的共享画面，将要移除------");
      this.vue.$store.commit("member/removeShareView");
    }

    // reset媒体标签
    const audioEl = document.getElementById(`audio-${userId}`)
    const videoListEl = document.getElementById(`video-${userId}`)
    const videoMainEl = document.getElementById(`main-video-${userId}`)
    const videoTab2El = document.getElementById(`tab2-video-${userId}`)
    const topShareEl = document.getElementById(`top-share-${userId}`)

    audioEl && (audioEl.srcObject = null)
    videoListEl && (videoListEl.srcObject = null)
    videoMainEl && (videoMainEl.srcObject = null)
    videoTab2El && (videoTab2El.srcObject = null)
    topShareEl && (topShareEl.srcObject = null)
  });
};

// 5.服务端通知 - 远程有人推音频流
MeetingManager.prototype.onUserAudioAvailable = function() {
  let _eventCount = this.roomClient.listenerCount('onUserAudioAvailable')
  if(_eventCount>0){ //如果注册过了就返回 避免多次注册   
    return
  }
  this.roomClient.on("onUserAudioAvailable", async (audioInfo) => {
    console.error("-----onUserAudioAvailable----------");
    console.log(audioInfo)
    
    // available: true 开启音频, false: 暂停音频
    let { userId, available } = audioInfo;
    loganLog(`userAudioAvailable-----userId:${userId}---available:${available}`)
    let user = this.vue.$store.getters["member/getUser"](userId)

    if (user.userId) {
      this.vue.$store.commit("member/updateUser", {
        userId,
        isUseHuaTong: available,
        isUseHuaTongError:false //每次订阅需要重新判断话筒状态
      });

      if (available) {
        console.error('准备订阅音频，先判断是否超过阈值--------')

        const { audioList } = this.vue.$store.state.member

        if (audioList.length < MAX_AUDIO_TRANSPORT) { // 没有超过阈值，正常订阅
          console.error('没有超过阈值，先新增或者修改audio，再订阅音频流');

          const targetAudio = audioList.find(i => i.userId === userId)

          if (targetAudio) { // 已经存在audioList中，只更新状态即可
            this.vue.$store.commit("member/updateAudio", {
              userId,
              action: 'start'
            })
          } else { // 新增
            this.vue.$store.commit("member/addAudio", {
              userId,
              action: 'start'
            })
          }

          this.vue.$nextTick(async () => {
            // 设置扬声器
            this._handleAudioPlayout(userId)

            try {
              await this._handleRemoteAudio(userId)
            } catch (error) {
              console.log(error)
            }
          })
        } else { // 超过了阈值
          console.error('超过了阈值------')

          const targetAudio = audioList.find(i => i.userId === userId)

          if (targetAudio) { // 已经存在audioList中，只更新状态即可
            console.error('超过了阈值，但是已经存在audioList中了，这时候只更新状态即可')

            this.vue.$store.commit("member/updateAudio", {
              userId,
              action: 'start'
            })
          } else { // 不存在，先置换，后新增
            console.error('useAudioAva超过了阈值，也不存在audiolist中，这时候进行置换处理------')

            const targetRemoveAudio = this._getRemoveAudioItem(audioList)

            if (targetRemoveAudio) { // 停掉当前的音频通道

              this.stopRemoteAudio(targetRemoveAudio.userId)

              console.log('useAudioAva停止音频成功----');

              // 删除auidoList中的指定对象
              this.vue.$store.commit("member/removeAudio", {
                userId: targetRemoveAudio.userId
              })

              const audioEl = document.getElementById(`audio-${targetRemoveAudio.userId}`)
              audioEl && (audioEl.srcObject = null)
            }


            // 将audio对象新增到audioList中
            this.vue.$store.commit("member/addAudio", {
              userId,
              action: 'start'
            })

            // 拉取音频流
            this.vue.$nextTick(async () => {
              // 设置扬声器
              this._handleAudioPlayout(userId)

              try {
                await this._handleRemoteAudio(userId)

                console.log('useAudioAva订阅音频成功----');
              } catch (error) {
                console.log(error)
              }
            })
          }
        }
      } else {
        console.error('远端成员关闭音频，停止订阅，并移出数组')

        // 停掉当前的音频通道
        this.stopRemoteAudio(userId)

        // 删除重试任务
        this.vue.$exceptionManager.deleteRetryJob(userId, 'audio')


        // 删除auidoList中的指定对象
        this.vue.$store.commit("member/removeAudio", {
          userId
        })

        const audioEl = document.getElementById(`audio-${userId}`)
        audioEl && (audioEl.srcObject = null)
      }
    }
  })
}


MeetingManager.prototype._handleRemoteAudio = function(userId) {
  const audioEl = document.getElementById(`audio-${userId}`)

  return new Promise(async (resolve, reject) => {
    const operation = retry.operation({
      retries: 3,
      minTimeout: 0
    });

    operation.attempt(async (currentAttempt) => {
      loganLog(`音频次数-----${currentAttempt}`)

      /**
       * 跳出情况：
       * 1. 用户已经不在房间了
       * 2. 用户已经关闭了音频
       */
      const operateUserId = this.vue.$configs.peerId
      const targetUser = this.vue.$store.state.member.userList.find(i => !i.isTopShare && (i.userId === userId))
      const targetUserName = targetUser ? targetUser.userName : ''

      if (!targetUser || (targetUser && !targetUser.isUseHuaTong)) {
        loganLog(`用户已经不在房间内或者已经把音频关闭了`)
        loganLog(targetUser)

        operation.stop()
        reject({
          name: 'notExistError',
          message: 'does not exist'
        })
        return
      }

      try {
        await this.startRemoteAudio(userId, audioEl)
        loganLog(`订阅远端用户的音频成功------userId: ${userId}---userName: ${targetUserName}`)
        //删除重试任务
        this.vue.$exceptionManager.deleteRetryJob(userId, 'audio')

        // 更改成员话筒是否为error状态
        this.vue.$store.commit("member/updateUser",  {
          userId,
          isUseHuaTongError: false
        })
        operation.stop()
        resolve()
      } catch (error) {
        loganLog(`订阅远端用户的音频失败------userId: ${userId}---userName: ${targetUserName}---error: ${JSON.stringify(error)}`)
        // 更改成员话筒是否为error状态
         this.vue.$store.commit("member/updateUser",  {
          userId,
          isUseHuaTongError: true
        })
        // 上报
        this.vue.$sentry.captureException({
          msg: '订阅远端用户的音频失败',
          userId,
          operateUserId,
          userName: targetUserName,
          error
        })

        // 弹窗提示
        // showErrorNotify(this.vue, {
        //   type: 'audio',
        //   userId,
        //   userName: targetUserName,
        //   errorMessage: '' // TODO:
        // })

        loganLog(`订阅音频失败, 启动重试-----${currentAttempt}`)

        if (!operation.retry(new Error())) { // 已达到最大返回数量
          loganLog(`订阅音频超过最大重试次数-----${currentAttempt}`)

          //增加音频重试任务
          this.vue.$exceptionManager.addRetryJob(userId, audioEl, 'audio')

          this.vue.$sentry.captureException({
            msg: '订阅远端用户的音频超出最大重试次数',
            userId,
            operateUserId,
            userName: targetUserName
          })

          reject({
            name: 'exceedMixRetryError',
            message: 'Maximum number of retries exceeded'
          })
        }
      }
    })
  })
}


// 3.服务端通知 - 远端有人推视频流
MeetingManager.prototype.onUserVideoAvailable = function() {
  let _eventCount = this.roomClient.listenerCount('onUserVideoAvailable')
  if(_eventCount>0){ //如果注册过了就返回 避免多次注册   
    return
  }
  this.roomClient.on("onUserVideoAvailable", async (videoInfo) => {
    // console.log(videoInfo);

    let { userId, available } = videoInfo;
    loganLog(`onUserVideoAvailable-----userId:${userId}---available:${available}`)
    console.error('远端有人推视频流取消load')
    this.changeLoadingClass(userId,'remove',false)
    if(!available){
      // 删除重试任务
      this.vue.$exceptionManager.deleteRetryJob(userId, 'video')
    }

    let user = this.vue.$store.getters["member/getUser"](userId);
    if (user.userId) {
      this.vue.$store.commit("member/updateUser", {
        userId,
        isUseShiPin: available,
      })
    }
  });
};

// 6.服务端通知-远程有人开始讲话
MeetingManager.prototype.userSpeaking = function() {
  let _eventCount = this.roomClient.listenerCount('userSpeaking')
  if(_eventCount>0){ //如果注册过了就返回 避免多次注册   
    return
  }
  this.roomClient.on("userSpeaking", async (speakingInfo) => {
    const { userId, action } = speakingInfo;

    console.error('-----userSpeaking-----')
    console.log(speakingInfo)

    const user = this.vue.$store.getters["member/getUser"](userId)

    if (user.userId) {
      // 其他逻辑
      if (action === "unmute") {
        // 添加到用户发言列表
        // console.error("添加发言人------------")
        // console.error(user)

        this.vue.$store.commit("member/addSpeak", user)
        this.vue.$store.commit("member/updateLastSpeaker", user)
      }

      if (action === "stop") {
        // 移除
        // console.error("删除发言人------------")
        // console.error(user)

        this.vue.$store.commit("member/removeSpeak", {
          userId: userId
        })
      }

      if (userId !== this.vue.$configs.peerId) { // 排除自己
        // 置换策略
        this.vue.$store.commit("member/updateAudio", {
          userId,
          action
        })

        // console.error('userSpeaking--audioList--', JSON.stringify(this.vue.$store.state.member.audioList))

        if (action === 'start' || action === 'unmute') {
          const { audioList } = this.vue.$store.state.member
          const isInAudioList = audioList.find(i => i.userId === userId)

          if (isInAudioList) { // 当前的发言人已经在audioList中了，说明已经简历了音频通道，这时候不用处理。
            console.error('说话的人已经建立过音频通道了')

            // console.error('audioList---', JSON.stringify(audioList))
          } else { // 当前发言人没有在audioList中，这时候要进行插入或者置换
            console.error('当前发言人没有在audioList中----');

            if (audioList.length < MAX_AUDIO_TRANSPORT) { // 没有超过阈值，正常插入
              console.error('此时没有超过阈值，执行插入逻辑')
            } else { // 超过阈值，先置换，再插入
              console.error('超过阈值了，要先置换')

              const targetRemoveAudio = this._getRemoveAudioItem(audioList)
              console.error('当前的targetRemoveAudio--', targetRemoveAudio)

              if (targetRemoveAudio) {
                console.error('将要停止订阅音频流--', JSON.stringify(targetRemoveAudio))

                // 停掉当前的音频通道
                this.stopRemoteAudio(targetRemoveAudio.userId)

                console.error('speaker停止订阅成功--')

                // 删除auidoList中的指定对象
                this.vue.$store.commit("member/removeAudio", {
                  userId: targetRemoveAudio.userId
                })

                const audioEl = document.getElementById(`audio-${targetRemoveAudio.userId}`)
                audioEl && (audioEl.srcObject = null)
              }
            }

            // 将audio对象新增到audioList中
            this.vue.$store.commit("member/addAudio", {
              userId,
              action
            })

            // 拉取音频流
            this.vue.$nextTick(async () => {
              // 设置扬声器
              this._handleAudioPlayout(userId)

              try {
                await this._handleRemoteAudio(userId)
              } catch (error) {
                console.log(error)
              }
            })
          }
        } else {
          console.error('当前action不做处理----')
        }
      } else {
        console.error('语音激励命中了自己，不做任何处理---')
      }
    }
  })
}

MeetingManager.prototype._getRemoveAudioItem = function(audioList) {
  // const isNotAvailable = audioList.find(i => !i.available)
  // if (isNotAvailable) { // 存在available: false
  //   console.error('选到ava: false');
  //   return isNotAvailable
  // }

  const isAudioStop = audioList.find(i => i.action === 'stop')
  if (isAudioStop) { // 存在action: stop
    return isAudioStop
  }

  const isAudioMute = audioList.find(i => i.action === 'mute')
  if (isAudioMute) { // 存在action: mute
    return isAudioMute
  }

  const isAudioStart = audioList.find(i => i.action === 'start')
  if (isAudioStart) { // 存在action: start
    return isAudioStart
  }

  const isAudioUnmute = audioList.find(i => i.action === 'unmute')
  if (isAudioUnmute) { // 存在action: unmute
    return isAudioUnmute
  }

  return null
}


// 7.本地用户共享开始
MeetingManager.prototype.screenCaptureStarted = function() {
  let _eventCount = this.roomClient.listenerCount('onScreenCaptureStarted')
  if(_eventCount>0){ //如果注册过了就返回 避免多次注册   
    return
  }
  this.roomClient.on("onScreenCaptureStarted", async () => {
    console.log("------收到本地用户的共享开始回调-------");

    // 将所有用户的dobclcik重置为false
    const userList = this.vue.$store.getters["member/getRealUserList"];
    // 将所有用户的dbClick重置为false
    userList.forEach((i) => {
      this.vue.$store.commit("member/updateUser", {
        userId: i.userId,
        isDbClick: false,
      });
    });

    // 更新vuex中自己的共享状态为false
    this.vue.$store.commit("member/updateUser", {
      userId: this.vue.$configs.peerId,
      isShare: true,
    });

    const { layoutType } = this.vue.$store.state.meet;

    if (
      layoutType === LAYOUT_CONFIG.GALLERY9 ||
      layoutType === LAYOUT_CONFIG.GALLERY25
    ) {
      // 如果当前处在宫格模式，则要切换到演讲者模式
      console.log("九宫格或者25宫格111，自动转换到演讲这个");

      try {
        console.error("首先停止订阅所有远端视频");
        await this.muteRemoteVideoAll();
        this.changeLoadingClass(this.vue.$configs.peerId,'remove',false)

        // 自动转到成员列表格式
        this.vue.$store.commit("meet/updateGlobalMeetState", {
          layoutType: LAYOUT_CONFIG.ROW,
        });
      } catch (error) {
        this.changeLoadingClass(this.vue.$configs.peerId,'add',false)
        console.log(error);
      }
    }
  });
};

// 8.本地用户共享结束
MeetingManager.prototype.screenCaptureStoped = function() {
  // let that = this
  let _eventCount = this.roomClient.listenerCount('onScreenCapturePaused')
  if(_eventCount>0){ //如果注册过了就返回 避免多次注册   
    return
  }
  this.roomClient.on("onScreenCapturePaused", () => {
    console.log("------收到本地用户的共享结束回调-------");

    // 删除isTopShare
    this.vue.$store.commit("member/removeShareView");

    // 如果是只有一个人切换9宫格
    const { layoutType,isFullModel } = this.vue.$store.state.meet;
    if(!isFullModel && this.vue.$store.state.member.userList.length <=1 && (layoutType === LAYOUT_CONFIG.COLUMN || layoutType === LAYOUT_CONFIG.ROW)){
      this.vue.$store.commit("meet/updateGlobalMeetState", {
        layoutType: LAYOUT_CONFIG.GALLERY9
      })
    }

    // 更新vuex中自己的共享状态为false
    this.vue.$store.commit("member/updateUser", {
      userId: this.vue.$configs.peerId,
      isShare: false,
    });
  });
};
// 9.服务端通知 - 远程有人开始或者停止共享流
MeetingManager.prototype.userShareAvailable = function() {
  let _eventCount = this.roomClient.listenerCount('onUserShareAvailable')
  if(_eventCount>0){ //如果注册过了就返回 避免多次注册   
    return
  }
  this.roomClient.on("onUserShareAvailable", async (shareInfo) => {
    const { userId } = shareInfo;
    console.error("userShareAvailable-----");
    console.log(shareInfo);
    loganLog(`userShareAvailable-----userId:${userId}`)

    let user = this.vue.$store.getters["member/getUser"](userId);

    if (user.userId) {
      console.error('已经存在userlist---');
      this._userShareAvailableHandle(shareInfo)
    }
  });
};

MeetingManager.prototype._userShareAvailableHandle = async function(shareInfo) {
  let that = this;
  const { userId, available, paused ,status} = shareInfo; //status  1 ,  开启共享 ， 2 ： 关闭共享 ， 3 ： 共享暂停 ， 4 共享恢复

  if (available) {
    //之前是否也有分享流，此状态为非登录游客状态断网重连userID发生了变化，之前的账户需要90s自动退会
    const isPreUserShare = that.vue.$store.getters["member/getRealUserList"].find(i => i.isShare)

    // 更新之前的游客共享信息为false
    if(isPreUserShare && isPreUserShare.userId && isPreUserShare.userId !== userId){
      that.vue.$store.commit("member/updateUser", {
        userId:isPreUserShare.userId,
        isShare: false
      });
      loganLog(`isPreUserShare--user:${userId}`)
    }


    // 有人开始共享了，这时候要判断模式
    const { layoutType } = that.vue.$store.state.meet;
    const userList = that.vue.$store.getters["member/getRealUserList"];

    // 将所有用户的dbClick重置为false，这时候就没有锁定状态了。
    if(status === 1 || status === 2){ //只有是开启共享和结束共享的情况下才取消锁定
      userList.forEach((i) => {
        that.vue.$store.commit("member/updateUser", {
          userId: i.userId,
          isDbClick: false,
        });
      });
    }

    // 如果当前处在宫格模式，则要切换到演讲者模式
    if (
      layoutType === LAYOUT_CONFIG.GALLERY9 ||
      layoutType === LAYOUT_CONFIG.GALLERY25
    ) {
      console.log("九宫格或者25宫格111，自动转换到演讲这个");

      try {
        console.error("首先停止订阅所有远端视频111");
        await this.muteRemoteVideoAll();

        that.vue.$store.commit("meet/updateGlobalMeetState", {
          layoutType: LAYOUT_CONFIG.ROW,
        });
      } catch (error) {
        console.log(error);
      }
    }
  } else {
    // 删除重试任务
    this.vue.$exceptionManager.deleteRetryJob(userId, 'share')

    // 删除isTopShare
    that.vue.$store.commit("member/removeShareView");
  }
  
  // 更新vuex中isShare
  that.vue.$store.commit("member/updateUser", {
    userId,
    isShare: available,
    sharePaused: !!paused,
  });
  loganLog(`updataUserShareAvailable--uese:${userId}`)

  that.localUser.sharePaused = !!paused
  const isTopShare = that.vue.$store.state.member.userList.find((i) => i.isTopShare)
  if (isTopShare) {
    // 如果存在topShare，那更新topShare的sharePaused状态
    that.vue.$store.commit("member/updateTopShare", {
      sharePaused: !!paused,
    });
  }
  //如果是在收到几秒未收到流后的状态下共享流暂停状态手动清除loading动画的兜底处理
  if(paused){
    this.changeLoadingClass(userId,'remove',true)
  }
}

/**
 * 扬声器设置
 */
MeetingManager.prototype._handleAudioPlayout = function(userId) {
  const elements = document.querySelectorAll(`#audio-${userId}`)

  // 设置要使用的扬声器
  this.vue.$deviceControl.setCurrentSpeakerDevice(null, elements, true)

  // 初始化扬声器音量
  if (this.localUser.isUseYangShengQi) {
    this.vue.$deviceControl.initPlayoutVolume(elements)
  } else {
    this.setAudioPlayoutVolume(0, elements)
  }
}

export default MeetingManager

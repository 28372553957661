<template>
  <div class="user-item" :class="{
      speaking: !user.isTopShare && user.isSpeaking
    }" @dblclick="dobuleClick(user)">

    <video
      autoplay
      playsinline
      class="media-reset-flag"
      :id="setVideoId(user)" 
      v-if="isShowVideo(user)" 
      :data-video="`video-${user.isUseShiPin}`"
      :data-share="`share-${user.isShare}`"
    ></video>
    <span v-if="isShowVideo(user)" class="loading-box" :data-isCurrentShare="`${isCurrentShare(user)}`"></span>
    
    <div :title="user.userName" v-if="isShowNoVideo(user)" class="user-no-video">
      {{ strMiddleEllipsis(user.userName, 7, 3, 2) }}
    </div>

    <div class="selfShare" v-if="isShowSelfShare(user)">
      {{$t('meeting.youAreShare')}}
    </div>

    <!-- 共享暂停面板 -->
    <div class="selfShare" v-if="isShowSharePausedView(user)">
      <div class="text"> {{$t('meeting.shareTop')}}</div>
    </div>

    <!-- v-if="!user.isTopShare" -->
    <div v-if="isShowBottomInfo(user)" class="user-state-box">
      <!-- 主持人图标 -->
      <!-- <my-icon title="主持人" v-if="user.roleCode === ROLE_CODE.HOST" class="compere" :fontSize="20"
        iconName="iconzhuchiren"></my-icon> -->
        <img class="compere" src="@/assets/images/iconzhuchiren.png" v-if="user.roleCode === ROLE_CODE.HOST" alt="">

      <!-- 联席主持人图标 -->
      <img class="compere2" src="@/assets/images/iconlianxizhuchiren.png" v-if="user.roleCode === ROLE_CODE.CO_HOST" alt="">

      <!-- <my-icon title="联席主持人" v-if="user.roleCode === ROLE_CODE.CO_HOST" class="compere2" :fontSize="20"
        iconName="iconlianxizhuchiren"></my-icon> -->

      <!-- 话筒音波状态 -->
      <!-- <my-icon class="userAudioIcon" :fontSize="16"
          :iconName="user.isUseHuaTongError ? 'iconshipinlan_shengyin_yichang_16_bai' : user.isUseHuaTong ? 'iconshipinlan_shengyin_16_bai' : 'iconshipinlan_shengyin_guanbi_16_bai'">
      </my-icon> -->
      <img class="userAudioIcon" :src="user.isUseHuaTongError ? require('@/assets/images/iconshipinlan_shengyin_yichang_16_bai.png') : user.isUseHuaTong ? require('@/assets/images/iconshipinlan_shengyin_16_bai.png') : require('@/assets/images/iconshipinlan_shengyin_guanbi_16_bai.png')">

      <!-- 用户名 -->
      <div :title="user.userName" class="userName">{{ strMiddleEllipsis(user.userName, 10, 3, 2) }}</div>
    </div>
  </div>
</template>

<script>
  import {
    strMiddleEllipsis
  } from "@/utils/str"
  import {
    ROLE_CODE
  } from '@/constant/index'

  export default {
    props: {
      user: {
        type: Object,
        default: () => {}
      },
      idPix: {
        type: String,
        default: 'video'
      },
      tab2CurrentShare:{
        type: Boolean,
        default: false
      }
    },


    data() {
      return {
        ROLE_CODE,
      }
    },

    computed: {
      userList() {
        return this.$store.getters["member/getRealUserList"]
      },

      isExitUserLock() { // 存在锁定用户
        return this.userList.find(i => i.isDbClick)
      }
    },


    methods: {
      strMiddleEllipsis,

      dobuleClick(user) {
        this.$emit('dobClick', user)
      },

      isShowBottomInfo(user) {
        if (this.idPix === 'tab2-video') {
          if (user.isShare) { // 在分享
            return this.isExitUserLock ? false : true
          } else { // 没有分享
            return true
          }
        } else {
          return !user.isTopShare
        }
      },
      isCurrentShare(user) {//如果是分享流给loading元素加data属性
        if(user.isTopShare || this.tab2CurrentShare){
          return true
        }else{
          return false
        }
      },
      isShowVideo(user) {
        
        if (user.isTopShare) {
          return user.userId !== this.$configs.peerId
        }
        //todo 要区分tab2的流
        // return user.isUseShiPin
        return user.isUseShiPin || user.isShare 
      },

      isShowSelfShare(user) { // 显示自己正在共享的死画面
        // return user.isTopShare && user.userId === this.$configs.peerId

        if (user.isTopShare) { // 顶部锁定画面
          return user.userId === this.$configs.peerId
        } else {
          if (this.idPix === 'tab2-video') { // tab2，有锁定人像，有共享，并且为自己
            return this.isExitUserLock && user.isShare && user.userId === this.$configs.peerId
          }

          return false
        }
      },

      isShowSharePausedView(user) { // 显示他人的共享暂停画面
        return (user.isTopShare || this.idPix === 'tab2-video' && this.isExitUserLock)
          && user.userId !== this.$configs.peerId
          && user.sharePaused
      },

      isShowNoVideo(user) {
        // return !user.isTopShare && !user.isUseShiPin // TODO: 可能有问题

        if (this.idPix === 'tab2-video') {
          if (!user.isTopShare && !user.isUseShiPin) {
            if (user.isShare) { // 在分享
              // return user.userId === this.$configs.peerId
              return this.isExitUserLock ? false : true
            } else { // 没有分享
              return true
            }
          }
        } else {
          return !user.isTopShare && !user.isUseShiPin
        }
      },

      setVideoId(user) { // 设置video的id值
        if (user.isTopShare) {
          return `top-share-${user.userId}`
        }

        return `${this.idPix}-${user.userId}`
      }
    }
  };
</script>

<style lang="scss" scoped>
  @mixin dis_style($h,$b){
    position: absolute;
    left: 0;
    height: $h;
    display: flex;
    align-items: center;
    background:$b;
  }
  @mixin font_style($s,$w,$c){
    font-size: $s;
    font-weight: $w;
    color: $c;
  }
  .user-item {
    position: relative;
    cursor: pointer;
    color: #fff;
    box-sizing: border-box;
    border: 2px solid #222222;

    &.speaking {
      border: 2px solid #1AB370 !important;
    }

    video {
      width: 100%;
      height: 100%;
      display: block;
    }

    .selfShare {
      @include dis_style(100%,#222222);
      color: #fff;
      justify-content: center;
      width: 100%;
      top: 0;
      right: 0;
      font-size: 15px;
    }
    .loading-box {
      position: absolute;
      display: none;
      top: 50%;
      left: 50%;
      margin-top: -30px;
      margin-left: -30px;
      z-index: 10;
      width: 60px;
      height: 60px;
      background: url(~@/assets/images/loading.png) no-repeat;
      background-size: 100% 100%;
      animation: rotate 1s linear infinite;
    }
    .loading-box-show{
      display: block;
    }
    .user-no-video {
      @include dis_style(100%,#222222);
      @include font_style(28px,500,#999);
      top: 0;
      width: 100%;
      justify-content: center;
    }

    .user-state-box {
      @include dis_style(20px,rgba(0, 0, 0, 0.5));
      // width: auto;
      padding-right: 4px;
      bottom: 0;
      box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.3);
      border-radius: 0px 4px 0px 0px;
      .compere,.compere2 {
        // float: left;
        margin-right: 4px;
        width:20px;
        height: 20px;
      }
      .userAudioIcon {
        // margin: 2px 0 2px 4px;
        // float: left;
        margin-right: 2px;
        width:16px;
    height: 16px;
      }
      .userName {
        @include font_style(12px,400,#fff);
        // height: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        line-height: 16px;
        text-shadow: 0px 20px 50px rgba(0, 0, 0, 0.3);
        // float: left;
        // margin: 2px 6px 2px 2px;
      }
    }
  }
</style>
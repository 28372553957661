<template>
  <div class="shareBtnBox">
    <div :class="['btnLeft',isOrdinaryUser ? 'noshare' : '']" @click="clickLeft" id="meeting_share_btn_headless">
      <!-- <my-icon :iconName="'icongongxiang_24_bai'"></my-icon> -->
      <img src="@/assets/images/icongongxiang_24_bai.png" width="24px" height="24px"/>
      <div class="shareInfo">{{$t('meeting.share')}}</div>
    </div>

    <div v-if="isShowShareSet()" class='btnRight' @click="clickRight">
      <!-- <my-icon :target="'shareArrowIcon'" :fontSize="16" :iconName="'iconjiantou_xiajiantou_bai'"></my-icon> -->
      <img class="audioArrowIcon" src="@/assets/images/iconjiantou_xiajiantou_bai.png"/>
    </div>

    <div class="sharePanel" :class="$i18n.locale === 'en-US' ? 'shareWidth' : ''" v-show="isShowPanel"
      v-extend-panel-manager="onClose">
      <div v-if="!isOrdinaryUser">
        <div class="sharePanel-title">{{$t('meeting.sharingRights')}}</div>

        <div class="radio-box">
          <el-radio-group @change="shareSetChange" v-model="shareVal">
            <el-radio :label="1" class="radio-item">{{$t('meeting.onlyMain')}}</el-radio>
            <el-radio :label="0" class="radio-item">{{$t('meeting.allPeoples')}}</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div v-if="isShowStopShare()" class="stop-btn" @click="stopCurrentShareClick">{{$t('meeting.stopThisShare')}}
      </div>
    </div>

    <!-- 停止共享 -->
    <el-dialog :title="$t('meeting.endShare')" class="commonElDialog" :modal="false" width="380px"
      :visible.sync="stopShareDialog">
      <div v-if="!stopAndStartShare">{{$t('meeting.isStopShare')}}</div>
      <div v-else>{{$t('meeting.stopSharing')}}</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="stopShareBtnCance">{{$t('meeting.noStop')}}</el-button>
        <el-button type="primary" @click="stopShareHandle">{{$t('meeting.isStop')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { fetchMeetControl } from '@/api/meet'
  import { ROLE_CODE, CONTROL_COMMAND } from "@/constant/index"
  import { loganLog } from "@/utils/log"

  export default {
    data() {
      return {
        isShowPanel: false, // 是否显示面板
        shareVal: "",
        stopShareDialog: false,
        stopShareBtnCanceType:'click',//点击取消停止其他人的共享的按钮类型 click、checked
        stopAndStartShare: false
      };
    },
    computed: {
      userList() {
        return this.$store.getters["member/getRealUserList"]
      },

      selfInfo() {
        return this.$store.getters["member/getUser"](this.$configs.peerId)
      },

      isHost() {
        return this.selfInfo.roleCode === ROLE_CODE.HOST;
      },

      isCoHost() {
        return this.selfInfo.roleCode === ROLE_CODE.CO_HOST;
      },

      isOrdinaryUser() {
        return this.selfInfo.roleCode === ROLE_CODE.USER;

      },

      sharePermission() {
        return this.$store.state.meet.sharePermission;
      },

      isExitUserShare() { // 存在用户共享
        return this.userList.find(i => i.isShare)
      }
    },
    methods: {

      openShareErrHandle(error) {
        loganLog(`openShare错误 error:${JSON.stringify(error)}`)

        const meetDialogInfo = {
          commonText: '开启屏幕共享失败'
        }

        if (error.errorCode === 70003) { // 点击了分享取消按钮，不做处理
          return
        }

        if (error.errorCode === 70005) { // mac端未开启录制权限
          meetDialogInfo.tips = this.$t('meeting.allShare')
          meetDialogInfo.commonText = this.$t('meeting.openShareDeniedHelp')
        }

        this.$store.commit("meet/updateGlobalMeetState", {
          meetDialogInfo
        })
      },

      stopCurrentShareClick() { // 停止共享
        this.stopShareDialog = true
        this.stopShareBtnCanceType = 'click' //取消按钮类型为点击
      },
      stopShareBtnCance(){
        this.stopShareDialog = false
        //如果是下拉框选择的仅主持人共享又取消了需要把状态重置
        if(this.stopShareBtnCanceType === 'checked'){
          this.shareVal = 0;
        }
      },
      async stopShareHandle() {
        this.stopShareDialog = false
        if (this.stopAndStartShare) {
          this.stopAndStartShare = false;
        }
        if(this.isExitUserShare.userId === this.$configs.peerId){
          // 结束自己的共享不走会控
          this.$meetingControl.stopShare()
        }else{
          try {
            await fetchMeetControl({
              command: CONTROL_COMMAND.STOP_SHARE,
              toPeerId: this.isExitUserShare.userId
            }, this.$configs)

            if (this.stopAndStartShare) {
              this.stopAndStartShare = false;
            }
          } catch (error) {
            console.log(error)
          }
        }
      },

      isShowStopShare() { // 是否出现停止共享按钮：存在有人共享并且当前用户是主持人或者联席主持人
        // return this.isExitUserShare && !this.isOrdinaryUser
        if (this.isExitUserShare) { // 存在共享
          if (this.isHost) { // 当前用户为主持人，一定显示
            return true
          } else if (this.isCoHost) { // 当前用户为联席主持人，分情况，如果分享的人为主持人，则不显示。
            return this.isExitUserShare.roleCode !== ROLE_CODE.HOST
          } else { // 普通用户，看是否共享人为自己
            // return false
            return this.isExitUserShare.userId === this.$configs.peerId
          }
        } else {
          return false
        }
      },

      isShowShareSet() { // 是否显示分享按钮右侧设置
        // return !this.isOrdinaryUser

        if (!this.isOrdinaryUser) { // 主持人和联席
          return true
        } else { // 普通用户
          return this.isExitUserShare && this.isExitUserShare.userId === this.$configs.peerId
        }
      },
      
      async shareSetChange(e) {
        //主持人选择仅主持可共享，弹窗是否结束当前非主持人的共享
        if(this.isExitUserShare && e === 1 && this.isExitUserShare.roleCode !== ROLE_CODE.CO_HOST && this.isExitUserShare && this.isExitUserShare.roleCode !== ROLE_CODE.HOST){
          this.stopShareDialog = true
          this.stopShareBtnCanceType = 'checked' //取消按钮类型为下拉框选择
        }
        await fetchMeetControl({
          command: e ?
            CONTROL_COMMAND.SHARE_PERMISSIONS_HOST : CONTROL_COMMAND.SHARE_PERMISSIONS_ALL,
        }, this.$configs)
      },
      // 共享操作
      async clickLeft() {
        if(this.$store.state.meet.isNetworkDisconnect){
        this.$parent.showToast(this.$t('meeting.netWorkBad'))
        return
      }
        let that = this;

        if (this.isExitUserShare) { // 当前有人再共享 TODO: 先临时这样做
          //判断当前共享人是不是自己，是的话提示一下，不再做任何操作
          if (this.$configs.peerId === this.isExitUserShare.userId) {
            this.$parent.commonTipText = this.$t('meeting.youShare')
            this.$parent.commonTipDialog = true;
            return;
          }
          if (this.isHost) { //如果自己是主持人
            if (this.isExitUserShare.roleCode === ROLE_CODE.HOST) { //如果正在分享人是主持人
              this.$parent.commonTipText = this.$t('meeting.hostShare')
              this.$parent.commonTipDialog = true
            } else { //正在分享人是联席或普通
              this.stopShareDialog = true;
              this.stopAndStartShare = true;
            }
          } else if (this.isCoHost) { //如果自己是联席主持人
            if (this.isExitUserShare.roleCode === ROLE_CODE.HOST) { //如果正在分享人是主持人
              this.$parent.commonTipText = this.$t('meeting.hostShare')
              this.$parent.commonTipDialog = true
            } else { //正在分享人是联席或普通
              this.stopShareDialog = true;
              this.stopAndStartShare = true;
            }
          } else {
            this.$parent.commonTipText = this.$t('meeting.haveShare')
            this.$parent.commonTipDialog = true
          }
        } else { // 当前没有人再共享
          let isRun = false

          if (this.sharePermission) { // 需要共享权限的时候
            if (!this.isOrdinaryUser) { // 自己是主持人或者联席主持人
              console.error('需要共享权限，自己是主持人')
              isRun = true
            } else {
              this.$parent.commonTipText = this.$t('meeting.getShareIng')
              this.$parent.commonTipDialog = true
            }
          } else {
            console.error('不需要共享权限')
            isRun = true
          }
          if (isRun) {
            try {
              await that.$meetingControl.openShare()
            } catch (error) {
              this.openShareErrHandle(error)
            }
          }
        }
      },
      // 点击按钮
      clickRight() {
        this.isShowPanel = !this.isShowPanel;
      },
      // 鼠标点击关闭面板
      onClose(mouseup, mousedown) {
        if (
          mousedown.target.className === "btnRight over" ||
          mousedown.target.className.baseVal === "icon16 shareArrowIcon" ||
          mousedown.target.className === "shareArrowIcon"
        ) {
          return;
        }

        this.isShowPanel = false;
      }
    },
    watch: {
      sharePermission(newVal) {
        this.shareVal = newVal;
      },
      selfInfo:{
        handler(newVal) {
          //自己的共享关闭 如果没有主持人权限关闭isShowPanel  不然会有一个小白快
          if(newVal.isShare === false && this.isOrdinaryUser){
            this.isShowPanel = false
          }
        },
        deep: true,
        immediate: true
      }
    },
    created() {
      this.shareVal = this.sharePermission;
    }
  };
</script>

<style lang="scss" scoped>
  @mixin public_hover{
    display: flex;
    align-items: center;
    height: 48px;
    background: #1ab370;
    transition-property: background-color;
    transition-duration: 0.5s;
    transition-timing-function: ease;
    cursor: pointer;
    &:hover{
      background: rgba(0, 0, 0, 0.05) !important;
    }
    &:focus{
      background: rgba(0, 0, 0, 0.1) !important;
    }
  }

  .shareBtnBox {
    display: flex;
    justify-content: space-between;
    height: 48px;
    background: #1ab370;
    border-radius: 24px;
    position: relative;

    .stop-btn {
      color: #FF0000;
      font-size: 13px;
      // padding-top: 9px;
      // border-top: 1px solid rgba(2, 2, 2, 0.05);
      cursor: pointer;
      border-top: 1px solid rgba(2, 2, 2, 0.05);
      padding-top: 9px;
    }

    .btnLeft {
      justify-content: space-between;
      padding-left: 10px;
      border-radius:100px 0 0 100px;
      @include public_hover;
      .shareInfo {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 20px;
        margin: 14px 8px;
      }
    }
    .noshare {
      padding-right: 10px;
      border-radius: 100px;
    }

    .btnRight {
      padding-right: 10px;
      border-radius: 0px 100px 100px 0px;
      @include public_hover;
      .audioArrowIcon{
        width:16px;
        height: 16px;
      }
    }

    .sharePanel {
      display: flex;
      box-sizing: border-box;
      flex-direction: column;
      padding: 16px;
      // height: 117px;
      background: #ffffff;
      box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      position: absolute;
      bottom: 70px;
      left: -35px;

      .sharePanel-title {
        color: #666666;
        font-size: 12px;
        margin-bottom: 4px;
      }

      .radio-box {
        display: flex;
        flex-direction: column;

        .radio-item {
          display: flex;
          align-items: center;
          height: 36px;
        }
      }
    }
  }
</style>
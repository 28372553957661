<!--
* 医百会议-主界面-底部-退出按钮功能
* version: 1.0
* author:  SunAo
* time: 2021-02-03
-->
<template>
  <div class='logout'>
    <!-- 退出=会议按钮 -->
    <div @click="click" id="meeting_logout_btn_headless">
      <!-- <div class="info"  v-if="isHost">{{$t('meeting.endMeetings')}}</div> -->
      <div class="info">{{isHost ? $t('meeting.endMeetings') : $t('meeting.leaveMeetings')}}</div>
    </div>

    <div class="logoutPanel" v-show="isShowPanel" v-extend-panel-manager="onClose">
      <!-- 结束会议按钮 -->
      <div  v-if="isHost"  class='endMeetBtn'  @click="endMeeting" id="meeting_endmeet_btn_headless">
        <div class="desc">{{$t('meeting.endMeetings')}}</div>
      </div>
      <!-- 离开会议按钮 -->
      <div class='leaveMeetBtn' @click="logoutMeeting" id="meeting_leavemeet_btn_headless">
        <div class="desc">{{$t('meeting.leaveMeetings')}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ROLE_CODE } from "@/constant";

export default {
  computed: {
    selfInfo() {
      return this.$store.getters["member/getUser"](this.$configs.peerId);
    },

    isHost() {
      return this.selfInfo.roleCode === ROLE_CODE.HOST;
    },

    isNetworkDisconnect() {
      return this.$store.state.meet.isNetworkDisconnect
    }
  },
  data() {
    return {
      isShowPanel: false, // 是否显示面板
      isSenEndMeeting:false
    };
  },
  methods: {
    // 点击按钮
    click() {
      this.isShowPanel = !this.isShowPanel;
    },
    // 鼠标点击关闭面板
    onClose(mouseup, mousedown) {
      if (
        mousedown.target.className === "logout over" ||
        mousedown.target.className === "info"
      ) {
        return;
      }
      this.isShowPanel = false;
    },
    resetSenEndMeeting(){
      this.isSenEndMeeting = false
    },
    endMeeting() { // 主持人点击结束会议
      if(this.$store.state.meet.isNetworkDisconnect){
        this.$parent.showToast(this.$t('meeting.netWorkBad'))
        return
      }
      if (!this.isNetworkDisconnect) {
        if(!this.isSenEndMeeting){
          this.isSenEndMeeting = true
          this.$emit('hostEndMeeting')
        }
      } else {
        console.log('断网下禁止点击----')
      }
    },
    // 当前用户退出会议
    logoutMeeting() { // 主持人，普通用户都会有，离开会议
      // if (!this.isNetworkDisconnect) {
        this.$emit('logoutMeeting')
      // } else {
      //   console.log('断网下禁止点击----')
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
  @mixin public_hover{
    &:hover{
      background: #ff9f29;
      }
    &:focus{
      background: #d96900;
    }
  }
.logout {
  background: #ff8200;
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.3),
    0px 2px 10px 0px rgba(254, 123, 69, 0.1);
  border-radius: 22px;
  cursor: pointer;
  position: relative;
  user-select: none;
  .info {
  text-align: center;
  border-radius: 22px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  text-shadow: 0px 20px 50px rgba(0, 0, 0, 0.3),
    0px 2px 10px rgba(254, 123, 69, 0.1);
  padding: 12px 24px;
  @include public_hover;
}
.logoutPanel {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  position: absolute;
  right: 5px;
  bottom: 81px;
  padding: 24px 16px;
  font-size: 13px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  @mixin public-meet_btn(){
      width:160px;
      display: inline-block;
      text-align: center;
      height: 36px;
      border-radius: 18px;
      cursor: pointer;
      line-height: 36px;
  }
    @mixin public_desc(){
        display: inline-block;
        width:100%;
        height:100%;
        border-radius: 18px;
    }
    .endMeetBtn {
      @include public-meet_btn;
      background: #ff8200;
      margin: 0 auto 10px;
        .desc {
            @include public_desc;
            color: #ffffff;
            @include public_hover;
        }
   }
    .leaveMeetBtn {
      @include public-meet_btn;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.2);
      box-sizing: border-box;
      margin: 0 auto;
      .desc {
          @include public_desc;
          color: #333333;
          &:hover{
            background: rgba(0, 0, 0, 0.05);
          }
          &:focus{
            background: rgba(0, 0, 0, 0.1);
          }
      }
    }
}
}
</style>

<!--
* 医百会议-主界面-底部-录制按钮功能
* version: 1.0
* author:  SunAo
* time: 2021-02-03
-->
<template>
  <div class="recordBtn">
    <div :class="['btnLeft' ,isOrdinaryUser ? 'no_jiantou' : '']" @click="clickLeft" id="meeting_record_btn_headless">
      <my-icon
        :iconName="isHost && isUseRecord ? 'icona-icontubiaohuichangluzhi_24_hei1' : 'icona-icontubiaohuichangluzhi_24_hei2'"
      ></my-icon>
      <!-- <img :src="isUseRecord ? require('@/assets/images/iconshengyin_24_hei.png') : require('@/assets/images/iconluzhi_zanting_24_hei.png')" width="24px" height="24px"/> -->
      <div class="recordInfo">{{isHost && isUseRecord ? (!isRecorStating ? '开启中' : $t('meeting.stopRecording')) : $t('meeting.startRecording')}}</div>
    </div>
    <div class='btnRight' v-if="isShowRecordSet()" @click="clickRight">
      <!-- <my-icon :target="'recordArrowIcon'" :fontSize="16" :iconName="'iconjiantou_xiajiantou_hei'"></my-icon> -->
      <img class="audioArrowIcon" src="@/assets/images/iconjiantou_xiajiantou_hei.png"/>
    </div>

    <div class="recordBox" v-if="isUseRecord && (isHost || cloudRecorState != 20)">
      <div class="iconDian"></div>
      <div class="text">{{!isRecorStating  ? '录制开启中'  : cloudRecorState == 20 ? '录制暂停中' : '录制中'}}</div>
      <my-icon
        class="myIcon"
        v-if="isHost"
        @click="commonClick(cloudRecorState == 20 ? 30 : 20)"
        :iconName="cloudRecorState == 20 ?'iconluzhi_zanting_24_bai':'iconluzhi_luzhizhong_24_bai'"
      ></my-icon>
      <my-icon
        v-if="isHost"
        class="myIcon"
        @click="clickLeft"
        :iconName=" 'iconluzhi_guanbi_24_bai'"
      ></my-icon>
    </div>

    <el-dialog
      :title="commonTipTit"
      class="commonElDialog"
      :modal="false"
      :visible.sync="commonTipDialog"
      width="380px"
    >
      <span>{{ commonTipText }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="commonTipDialog = false">{{'取消'}}</el-button>
        <el-button type="primary" @click="commonClick(!isUseRecord ? 10 : 40)">{{!isUseRecord ? '启动' : '结束'}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { fetchMeetControl } from '@/api/meet'
import { ROLE_CODE, CONTROL_COMMAND } from '@/constant/index'
import {
  getMeetToken,
} from '@/utils/auth'
import {
  landingCloudRecordEnable
} from '@/api/meet'

import throttle from 'lodash.throttle'

import {
  THROTLE_TIME
} from "@/constant/index";

export default {
  data() {
    return {
      isShowPanel: false, // 是否显示面板
      recordVal: '',
      commonTipDialog:false,
      commonTipText:'',
      commonTipTit:''
    };
  },
  computed: {
    selfInfo() {
      return this.$store.getters["member/getUser"](this.$configs.peerId)
    },

    isHost() {
      return (this.selfInfo.roleCode === ROLE_CODE.HOST || this.selfInfo.roleCode === ROLE_CODE.CO_HOST);
    },

    isOrdinaryUser() {
      return this.selfInfo.roleCode === ROLE_CODE.USER;
    },
    isRecorStating(){
      return this.cloudRecorState === 11 || this.cloudRecorState === 31  || this.cloudRecorState === 20 || this.cloudRecorState === 40 || this.cloudRecorState === 0
    },
    cloudRecorState() {
      console.error('当前录制状态',this.$store.state.meet.cloudRecorState);
      return this.$store.state.meet.cloudRecorState
    },
    isUseRecord() {
      return this.cloudRecorState > 0 && this.cloudRecorState < 40
    },
  },
  methods: {
    isShowRecordSet() { // 是否显示录制按钮右侧设置
      return false //只有主持人有权限
      return !this.isOrdinaryUser
    },

    async recordSetChange(e) {
      await fetchMeetControl({
        command: e ? CONTROL_COMMAND.RECORD_PERMISSIONS_HOST : CONTROL_COMMAND.RECORD_PERMISSIONS_ALL
      }, this.$configs)
    },
    // 录制操作
    clickLeft() {
      if(this.$store.state.meet.isNetworkDisconnect){
        this.$parent.showToast(this.$t('meeting.netWorkBad'))
        return
      }
      if (this.isHost) { // 自己是主持人
        if(!this.isUseRecord){
          this.commonTipText = '是否确认开启云录制'
          this.commonTipTit = '启动云录制'
        }else{
          this.commonTipText = '是否确认结束云录制'
          this.commonTipTit = '结束云录制'
        }
        if(this.cloudRecorState === 0 || this.cloudRecorState === 20 || this.cloudRecorState === 11 || this.cloudRecorState === 31 || this.cloudRecorState === 40){
          this.commonTipDialog = true
        }

      } else {
        // this.$parent.commonTipText = '仅主持人或联席主持人可录制'
        // this.$parent.commonTipDialog = true
        this.$parent.showToast(this.$t('仅主持人可录制'))
      }
    },

    commonClick: throttle(async function (type) {
      if(!this.isUseRecord){
        try {
          if(type === 10){
            const routeQuery = this.$route.query
            let meetingId = routeQuery.conferenceId
            const resData = await landingCloudRecordEnable({
              token: getMeetToken(),
              meetingId:meetingId
            })  
            if (resData.cloudRecordEnable == false) {
              this.$parent.loganLogFn(`录制权限错误--resData:${JSON.stringify(resData)}`)
              this.$parent.showToast(resData.msg)
            } else {
              this.recordFn(type)
            }
          }else{
            this.recordFn(type)
          }
        } catch (error) {
          console.error(error)
        }
      }else{
        this.recordFn(type)
        // this.isUseRecord = false;
      }
      this.commonTipDialog = false
    }, THROTLE_TIME),

    async recordFn(type){
      //调用会控开始录制
        if(!this.isRecorStating){ // 如果是开启中点击无效
          this.$parent.loganLogFn(`录制还没准备好--cloudRecorState:${JSON.stringify(this.cloudRecorState)}`)
          return
        }
       

        this.$parent.loganLogFn(`调用会控开始录制--type:${JSON.stringify(type)}`)
        let command
        if(type == 10) command = CONTROL_COMMAND.START_CLOUDRECORD
        if(type == 20) command = CONTROL_COMMAND.PAUSE_CLOUDRECORD
        if(type == 30) command = CONTROL_COMMAND.RESUME_CLOUDRECORD
        if(type == 40) command = CONTROL_COMMAND.STOP_CLOUDRECORD
        try {
         await fetchMeetControl({
            command: command
          }, this.$configs);
        } catch (error) {
          this.$parent.loganLogFn(`录制会控错误-----type:${type}-----error:${JSON.stringify(error)}`)
        }
    },
    // 点击按钮
    clickRight() {
      this.isShowPanel = !this.isShowPanel;
    },
    // 鼠标点击关闭面板
    onClose() {
      this.isShowPanel = false;
    }
  },
  watch: {
    
  },
  created() {
    
  }
};
</script>

<style lang="scss" scoped>
  @mixin public_hover{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    background: #fff;
    transition-property: background-color;
    transition-duration: 0.5s;
    transition-timing-function: ease;
    cursor: pointer;
    &:hover{
      background: rgba(0, 0, 0, 0.05) !important;
    }
    &:focus{
      background: rgba(0, 0, 0, 0.1) !important;
    }
  }
.recordBtn {
  display: flex;
  justify-content: space-between;
  height: 48px;
  position: relative;
  border-radius: 100px 0px 0px 100px;
    .no_jiantou{
      padding-right: 10px;
    }
    .btnLeft {
      padding-left: 10px;
      border-radius: 100px 0px 0px 100px;
      @include public_hover;
      .recordInfo {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
        margin: 14px 4px 14px 8px;
      }
    }
    .btnRight {
      @include public_hover;
      .audioArrowIcon{
        width:16px;
        height: 16px;
      }
    }
    .recordPanel {
      display: flex;
      flex-direction: column;
      padding: 16px;
      box-sizing: border-box;
      width: 192px;
      min-height: 115px;
      background: #ffffff;
      box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      position: absolute;
      bottom: 70px;
      left: -35px;
      .recordPanel-title {
        color: #666666;
        font-size: 12px;
        margin-bottom: 4px;
      }
      .radio-box {
        display: flex;
        flex-direction: column;
        .radio-item {
          display: flex;
          align-items: center;
          height: 36px;
        }
      }
    }
    .recordWidth{
      width:246px;
    }
}
.recordBox{
  position: fixed;
  top: 20px;
  left: 128px;
  padding: 2px 8px 2px 4px ;
  height: 24px;
  background: rgba(51, 51, 51, 0.4);
  border-radius: 2px;
  display: flex;
  .iconDian{
    width: 8px;
    height: 8px;
    background: #FF4D4F;
    border-radius: 50%;
    margin: 8px ;
  }
  .text{
    height: 16px;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    padding-top: 4px;;
    line-height: 16px;
  }
  .myIcon{
    margin-left: 4px;
  }
}
</style>

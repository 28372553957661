<!--
* 医百会议-主界面-底部-视频按钮功能
* version: 1.0
* author:  SunAo
* time: 2021-02-02
-->
<template>
  <div class="videoBtn">
    <div class="btnLeft"  @click="clickLeft" id="meeting_video_btn_left_headless">
      <img :src="isUseShiPin ? require('@/assets/images/iconshipin_24_hei.png') : require('@/assets/images/iconshipin_guanbi_24_hei.png')" width="24px" height="24px"/>

      <div class="videoInfo">
        {{ isUseShiPin ? $t('meeting.stopVideo') : $t('meeting.openVideo') }}</div>
    </div>
    <div class="btnRight"  @click="clickRight" id="videoBtnRight">
      <!-- <my-icon class="videoArrowIcon" :target="'videoArrowIcon'" :fontSize="16"
        :iconName="'iconjiantou_xiajiantou_hei'"></my-icon> -->
      <img class="videoArrowIcon" src="@/assets/images/iconjiantou_xiajiantou_hei.png"/>

    </div>

    <div class="videoPanel" v-show="isShowPanel" v-extend-panel-manager="onClose">
      <div class="title">{{$t('meeting.chooseVideo')}}</div>
      <template v-for="(device,idx) in cameraDevicesList">
        <div class="rowItem hover_item" :key="idx" @click="selectedDevice(device)" :title="device.deviceName">
          <div :class="['name',{'selected':device.deviceId === currentSelectedDevice.deviceId}]">{{device.deviceName}}
          </div>
          <my-icon v-if="device.deviceId === currentSelectedDevice.deviceId" class="okIcon" :fontSize="16"
            :iconName="'iconduigou_16_lv'"></my-icon>
        </div>
        <my-video :id="device.deviceId+'_video'" :isShow="device.deviceId === currentSelectedDevice.deviceId"
           class="rowBar" v-if="device.deviceId === currentSelectedDevice.deviceId"
          :isCloseDevice="device.deviceId !== currentSelectedDevice.deviceId" :key="idx+'_video'">
        </my-video>
      </template>
      <div class="rowItem" @click="openSetting" id="meeting_video_win_setting_headless">
        <div class="name">{{$t('setUp.seeting')}}</div>
      </div>
      <div class="rowItem" @click="clickLeft" id="meeting_video_win_switch_headless">
        <div class="name red" v-if="isUseShiPin">{{$t('meeting.closeVideo')}}</div>
        <div class="name green" v-else>{{$t('meeting.onepCamera')}}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import throttle from 'lodash.throttle'
  import myVideo from "@/components/video/index";

  export default {
    components: {
      myVideo
    },
    computed: {
      ...mapGetters(["isShowSettingPanel", "settingPanelDefaultIndex"]),
      selfInfo() {
        return this.$store.getters["member/getUser"](this.$configs.peerId);
      },
      isUseShiPin() {
        return this.selfInfo.isUseShiPin
      },
      cameraDevicesList() {
        return this.$store.state.device.cameraDeviceList
      },
      currentSelectedDevice() {
        return this.$store.state.device.currentCameraDevice
      },
      cameraNoGrant() {
        return this.$store.state.meet.cameraNoGrant
      },
    },
    beforeDestroy() {
      if (this.$deviceControl) {
        if (this.isShowSettingPanel && this.settingPanelDefaultIndex === 2) {
          return
        }
        this.$deviceControl.stopLocalCamera()
      }
    },
    data() {
      return {
        isShowPanel: false, // 是否显示面板
        isLock: false
      }
    },
    watch: {
      isShowPanel(value) {
        this.$store.commit('isShowVideoPanel', value)
        this.$store.commit('isShowDeviceSmallWin', value)
        value && this.initVideoDevice()

        if (!value && this.$deviceControl) {
          if (this.isShowSettingPanel && this.settingPanelDefaultIndex === 2) {
            return
          }
          this.$deviceControl.stopLocalCamera(true)
        }
      }
    },

    methods: {
      toggleLocalVideoState() {
        this.$store.commit('member/updateUser', {
          userId: this.$configs.peerId,
          isUseShiPin: !this.isUseShiPin
        })

        this.isLock = false
        console.log('解锁，可以再次触发');
      },

      // 视频操作
      clickLeft: throttle(async function () {
        if(this.$store.state.meet.isNetworkDisconnect){
          this.$parent.showToast(this.$t('meeting.netWorkBad'))
          return
        }
        if (this.isLock) {
          console.error('视频未解锁，直接返回---')
          return
        }
        //无摄像头权限
        if(this.cameraNoGrant && this.cameraNoGrant.code){
          const meetDialogInfo = {
            tips: this.cameraNoGrant.title,
            showClose : true,
            isGoIndex : false,
            commonText : `错误码:${this.cameraNoGrant.code}`,
            describe:this.cameraNoGrant.describe,
            isGoLogin: false,
            ensureText: '',
            conferenceNo: '',
            title: ''
          }
          this.$store.commit("meet/updateGlobalMeetState", {
            meetDialogInfo:meetDialogInfo
          })
          this.isLock = false
          return
        }
        this.isLock = true
        if (this.isUseShiPin) { // 正在使用视频，也就是说接下来要关闭
          console.error('将要关闭本地视频--')

          try {
            await this.$meetingControl.stopLocalPreview()

            console.error('关闭本地视频成功-------')

            this.toggleLocalVideoState()
          } catch (error) {
            this.isLock = false

            this.$parent.showToast(this.$t('meeting.operationFailed'))

            this.$sentry.captureException({
              msg: '底部关闭视频失败',
              userId: this.$configs.peerId,
              userName: this.selfInfo.userName,
              error
            })
          }
        } else { // 只开启推流，这时候不处理画面
          console.error('将要开启本地视频--')
          try {
            await this.$meetingControl.startLocalPreview()

            console.error('开启本地视频成功----')

            this.toggleLocalVideoState()
          } catch (error) {
            this.isLock = false
          }
        }
      }, 1000, {
        trailing: false
      }),

      // 点击按钮
      async clickRight() {
        if (this.$store.state.isShowVideoPanel) {
          return
        }
        if(!this.isShowPanel && !this.$store.state.isShowDeviceSmallWin) {
          this.isShowPanel = true
        } else {
          this.isShowPanel = false
        } 
      },
      //初始化
      async initVideoDevice() {
        if (this.isShowPanel) {

          // 开启与关闭视频
          this.$nextTick(async () => {
            if (this.currentSelectedDevice) {
              await this.$deviceControl.startLocalCamera(240, 135, this.currentSelectedDevice.deviceId, this
                .currentSelectedDevice.deviceId + '_video')
            }
          })
        }
      },
      // 鼠标点击关闭面板
      onClose(e) {
        //点击开启视频右侧箭头按钮不触发
        const videoBtnRightEl = document.getElementById('videoBtnRight')
        if (e.target === videoBtnRightEl 
           || videoBtnRightEl.contains(e.target)) {
          return
        }
        
        if (this.isShowPanel) {
          this.isShowPanel = false
        }
      },

      //点击选中设备
      async selectedDevice(device) {
        if (device.deviceId && this.currentSelectedDevice.deviceId !== device.deviceId) {
          await this.$deviceControl.setCurrentCameraDevice(device.deviceId)
          await this.$deviceControl.startLocalCamera(240, 135, device.deviceId, device.deviceId + '_video')
          if (this.isShowSettingPanel && this.settingPanelDefaultIndex === 2) {
            await this.$deviceControl.startLocalCamera(416, 234, device.deviceId, 'settingVideo')
          }
        }
      },
      //打开设置窗组件
      openSetting() {
        this.isShowPanel = false
        this.$emit('openSetting')
      }
    },
  };
</script>

<style lang="scss" scoped>
  @mixin public_hover{
    display: flex;
    align-items: center;
    height: 48px;
    background: #fff;
    transition-property: background-color;
    transition-duration: 0.5s;
    transition-timing-function: ease;
    cursor: pointer;
    &:hover{
      background: rgba(0, 0, 0, 0.05) !important;
    }
    &:focus{
      background: rgba(0, 0, 0, 0.1) !important;
    }
  }
  .videoBtn {
    display: flex;
    justify-content: space-between;
    height: 48px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 24px;

    .btnLeft {
      justify-content: space-between;
      border-radius: 100px 0px 0px 100px;
      padding-left: 10px;
      @include public_hover;

      .videoInfo {
        display: inline-block;
        height: 20px;
        font-size: 14px;
        text-align: center;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
        margin: 14px 4px;
      }
    }

    .btnRight {
      padding-right: 10px;
      border-radius: 0px 100px 100px 0px;
      @include public_hover;
      .videoArrowIcon{
        width:16px;
        height: 16px;
      }
    }

    .videoPanel {
      width: 256px;
      background: #ffffff;
      box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      position: absolute;
      bottom: 82px;
      left: 236px;
      padding-bottom: 10px;

      .title {
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 17px;
        margin: 17px 0 4px 16px;
      }
      .hover_item:hover{
        background: rgba(0, 0, 0, 0.05);
      }

      .rowItem {
        width: 100%;
        height: 36px;
        background: #ffffff;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        padding-right: 16px;

        .name {
          height: 18px;
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 18px;
          margin: 9px 0 0 16px;
          cursor: pointer;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .red {
          color: #FF0000;
        }

        .green {
          color: #1AB370;
        }

        .selected {
          color: #1ab370;
        }

        .okIcon {
          margin-top: 9px;
          margin-left: 10px;
        }

        .rowBar {
          margin-left: 7px;
          margin-top: 4px;
        }
      }
    }
  }
</style>